
import loadReduxTypes from '../../helpers/loadReduxTypes'

const GET_EXPERTS = loadReduxTypes('GET_EXPERTS')
const EXPERTS_CLEAR = loadReduxTypes('EXPERTS_CLEAR')

const initialState = {
  data: null,
  total: null,
  isFetching: null,
  error: null
}

export default function expertsReducer (state = initialState, { type, payload }) {
  switch (type) {
  case GET_EXPERTS.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case GET_EXPERTS.SUCCESS: {
    return {
      ...state,
      data: payload.experts,
      total: payload.total,
      isFetching: false
    }
  }

  case GET_EXPERTS.FAILURE: {
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }
  }

  case EXPERTS_CLEAR.SUCCESS: {
    return {
      ...state,
      data: null
    }
  }

  default: return state
  }
}
