import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import BaseLayout from '../../layouts/BaseLayout'
import { Button } from '../../components/Button'

import { SERVICE_MESSAGES } from './NotFound.constants'

const NotFound = () => {
  const history = useHistory()
  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get('message')
    /* WIP: Show code messages in the DOM */
    if (code && SERVICE_MESSAGES.some(messages => messages[code])) {
      toast.info(SERVICE_MESSAGES[0][code])
    }
  }, [ history ])

  return (
    <BaseLayout>
      <div className="not-found">
        <div className="not-found__content">
          <div className="not-found__cover" />
          <div className="not-found__description" />
          <div className="not-found__button">
            <Button
              label="На главную"
              color="blue"
              link="/"
              width={165}
            />
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default NotFound
