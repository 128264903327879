import PropTypes from 'prop-types'
import React from 'react'

import { getCoverImage } from '../../pages/utils/getObjectFields'
import noUserPicture from '../../ge/no-userpic.svg'

const ProjectInfoAvatar = ({ projectMember }) => {
  const projectMemberAvatar = <img alt="User avatar" src={projectMember.avatar ? `${getCoverImage(projectMember, 'avatar', true)}` : noUserPicture} />

  return (projectMember.profile_link ? (
    <a href={projectMember.profile_link} rel="noopener noreferrer" target="_blank">
      {projectMemberAvatar}
    </a>
  ) : projectMemberAvatar)
}

export default ProjectInfoAvatar

ProjectInfoAvatar.propTypes = {
  projectMember: PropTypes.shape({
    profile_link: PropTypes.string,
    avatar: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.number,
        uploaded_at: PropTypes.string,
        filename: PropTypes.string
      })
    ])
  })
}
