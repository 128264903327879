import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BaseLayout from '../../layouts/BaseLayout'
import { Button } from '../../components/Button'
import Loader from '../../components/Loader'
import PartnerPerson from '../../components/PartnerPerson'
import MetaTags from '../../components/MetaTags'
import { formatName } from '../../helpers/formatters'
import { useStateClearance } from '../../hooks/useStateClearance.hook'
import { META_TAGS_LIST } from '../../constants/Meta.constants'

import {
  PARTNERS_PER_PAGE
} from './Partners.constants'
import { clearPartnersState, getPartners } from './Partners.actions'

const PartnersPage = () => {
  const dispatch = useDispatch()
  const { data, isFetching } = useSelector(state => state.partners)

  const [ partnersCount, setPartnersCount ] = useState(PARTNERS_PER_PAGE)

  useEffect(() => {
    dispatch(getPartners(PARTNERS_PER_PAGE))
  }, [ dispatch ])

  useEffect(() => {
    partnersCount !== PARTNERS_PER_PAGE && dispatch(getPartners(partnersCount))
  }, [ dispatch, partnersCount ])

  const totalPartnersCount = (data && data.data.total) || 0

  useStateClearance(clearPartnersState)

  return (
    <BaseLayout>
      <MetaTags
        title={META_TAGS_LIST.PARTNER_TITLE}
        description={META_TAGS_LIST.PARTNER_DESCRIPTION}
      />
      <div className="container-app">
        <div className="container-fluid">
          <h1>Партнеры проектов</h1>

          <div className="row partners-card__schools-container">
            {data && data.data.items.map((person, id) => (
              <div className="col col-xl-3 flex align-items-start margin-bottom-45" key={id}>
                <PartnerPerson
                  key={id}
                  portrait={person}
                  name={formatName(person.first_name, person.last_name)}
                  position={person.position}
                  company={person.company}
                />
              </div>
            ))}

          </div>
          {(isFetching &&
            <div className="partners-card__loader">
              <Loader />
            </div>) ||
          <div className="row justify-content-center margin-top-15">
            <div className="col-xl-auto col">
              {partnersCount < totalPartnersCount &&
                <Button
                  type="submit"
                  onClick={() => {
                    setPartnersCount(partnersCount + PARTNERS_PER_PAGE)
                  }}
                  label={isFetching ? 'Загрузка' : 'Показать еще'}
                  large
                  nobg
                  disabled={isFetching}
                  width="100%"
                  className="btn__show-more"
                />
              }
            </div>
          </div>
          }
        </div>
      </div>
    </BaseLayout>
  )
}

export default PartnersPage
