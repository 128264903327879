import loadReduxTypes from '../../helpers/loadReduxTypes'

const initialState = {
  data: null,
  error: null,
  isFetching: false
}

const PARTNERS = loadReduxTypes('PARTNERS')
const PARTNERS_CLEAR = 'PARTNERS_CLEAR'

export default function partnersReducer (state = initialState, { type, payload }) {
  switch (type) {
  case PARTNERS.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }
  case PARTNERS.SUCCESS: {
    return {
      ...state,
      data: payload.partners,
      isFetching: false
    }
  }
  case PARTNERS.FAILURE: {
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }
  }

  case PARTNERS_CLEAR: {
    return initialState
  }

  default:
    return state
  }
}
