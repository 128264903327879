import loadReduxTypes from '../../helpers/loadReduxTypes'

export const PLATFORM_PAGE_MEMBERS = loadReduxTypes('PLATFORM_PAGE_MEMBERS')
export const PLATFORM_PAGE_NEWS = loadReduxTypes('PLATFORM_PAGE_NEWS')
export const PLATFORM_PAGE_VOLUNTEERS = loadReduxTypes('PLATFORM_PAGE_VOLUNTEERS')

const initialState = {
  platformMembers: null,
  platformVolunteers: null,
  platformNews: null,
  isFetching: null,
  error: null
}

export default function platformPageReducer (state = initialState, { type, payload }) {
  switch (type) {
  case PLATFORM_PAGE_NEWS.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case PLATFORM_PAGE_NEWS.SUCCESS: {
    return {
      ...state,
      platformNews: payload,
      isFetching: false
    }
  }

  case PLATFORM_PAGE_NEWS.FAILURE: {
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }
  }

  case PLATFORM_PAGE_MEMBERS.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case PLATFORM_PAGE_MEMBERS.SUCCESS: {
    return {
      ...state,
      platformMembers: payload,
      isFetching: false
    }
  }

  case PLATFORM_PAGE_MEMBERS.FAILURE: {
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }
  }

  case PLATFORM_PAGE_VOLUNTEERS.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case PLATFORM_PAGE_VOLUNTEERS.SUCCESS: {
    return {
      ...state,
      platformVolunteers: payload,
      isFetching: false
    }
  }

  case PLATFORM_PAGE_VOLUNTEERS.FAILURE: {
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }
  }
  default: return state
  }
}
