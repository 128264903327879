import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BaseLayout from '../../layouts/BaseLayout'
import Loader from '../../components/Loader'
import { API_IMG } from '../../constants/Api.constants'
import noUserPicture from '../../ge/no-userpic.svg'
import { Button } from '../../components/Button'
import MetaTags from '../../components/MetaTags'
import { useStateClearance } from '../../hooks/useStateClearance.hook'
import { META_TAGS_LIST } from '../../constants/Meta.constants'

import { EXPERTS_PER_PAGE } from './ExpertAdvice.constants'
import { getExpertsAction, clearState } from './ExpertAdvice.actions'

const ExpertAdvice = () => {
  const { experts: { data, isFetching, error, total } } = useSelector(state => state)
  const dispatch = useDispatch()
  const [ expertsCount, setExpertsCount ] = useState(EXPERTS_PER_PAGE)

  useEffect(() => {
    dispatch(getExpertsAction(EXPERTS_PER_PAGE))
  }, [ dispatch ])

  useEffect(() => {
    expertsCount !== EXPERTS_PER_PAGE && dispatch(getExpertsAction(expertsCount))
  }, [ dispatch, expertsCount ])

  useStateClearance(clearState)

  const metaTags = (
    <MetaTags
      title={META_TAGS_LIST.EXPERTS_TITLE}
      description={META_TAGS_LIST.EXPERTS_DESCRIPTION}
    />
  )

  if (isFetching && (expertsCount <= EXPERTS_PER_PAGE)) {
    return (
      <BaseLayout>
        {metaTags}
        <div className="experts-container container-fluid container-app">
          <h1>Экспертный совет</h1>
          <div className="news-article__loader">
            {isFetching && <Loader />}
          </div>
        </div>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout>
      {metaTags}
      <div className="experts-container container-fluid container-app">
        <h1>Экспертный совет</h1>
        {data && data.length === 0 && !isFetching &&
          <p className="experts-container__position">
            В настоящий момент экспертов нет.
          </p>
        }

        {error &&
          <p className="experts-container__position">
            Произошла ошибка. Повторите попытку позже.
          </p>
        }

        {data && data.map((expert, index) => (
          <div key={index} className="experts-container__expert-block">
            <div className="experts-container__details">
              <img
                alt="User avatar"
                src={expert.photo ? `${API_IMG}/${expert.photo}` : noUserPicture} />
              <div dangerouslySetInnerHTML={{ __html: expert.photo_description }} className="experts-container__image-description" />

            </div>
            <div className="experts-container__content">
              <h3>{expert.name}</h3>
              <div
                className="experts-container__position"
                dangerouslySetInnerHTML={{ __html: expert.summary }}
              />
              <div
                className="experts-container__text"
                dangerouslySetInnerHTML={{ __html: expert.text }}
              />
            </div>
          </div>
        ))}

        <div className="row justify-content-center col-xl-auto margin-top-15 align-self-center">
          {data && expertsCount < total && <Button
            onClick={() => setExpertsCount(expertsCount + 4)}
            label={isFetching ? 'Загрузка...' : 'Показать еще'}
            disabled={isFetching}
            width="100%"
            className="btn__show-more"
            nobg
          />}
        </div>

      </div>
    </BaseLayout>
  )
}

export default ExpertAdvice
