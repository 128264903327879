import * as Yup from 'yup'

import { FORM_FIELD_ERROR } from '../../constants/Errors.constans'

export const MAX_SOCIAL_LINKS_FIELDS = 3

export const MAX_MEDIA_FIELDS = 9
const YOUTUBE_MATCH = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

export const getMediaFields = (currentValue) => {
  const fields = {}
  for (let i = 0; i <= currentValue; i++) {
    fields[`video_link-${i}`] = Yup.string().matches(YOUTUBE_MATCH, FORM_FIELD_ERROR.VIDEO_LINK_FORMAT)
    fields[`video_description-${i}`] = Yup.string()
      .when(`video_link-${i}`, (link, schema, anotherField) => {
        if (anotherField.parent[`video_link-${i}`]) {
          return Yup.string().required(FORM_FIELD_ERROR.REQUIRED_FIELD)
        }
      })
  }
  return fields
}

export const PROJECT_DIRECTION = {
  WHICH_PROBLEM_SOLVE: 'project_goal_problems',
  DESCRIPTION_SITUATION: 'project_goal_current_state_of_affairs',
  DESCRIPTION_RESULT: 'project_goal_desired_result',
  WHAT_WAS_DONE: 'project_goal_already_done',
  WHAT_NEED_TODO: 'project_goal_required_steps',
  NECESSARY_SOURCES: 'project_goal_required_resources'
}

export const PROJECT_DIRECTION_PLACEHOLDERS = {
  [PROJECT_DIRECTION.WHICH_PROBLEM_SOLVE]: 'Какие проблемы решает*',
  [PROJECT_DIRECTION.DESCRIPTION_SITUATION]: 'Описание ситуации на сегодняшний день*',
  [PROJECT_DIRECTION.DESCRIPTION_RESULT]: 'Описание желаемого результата*',
  [PROJECT_DIRECTION.WHAT_WAS_DONE]: 'Что было сделано*',
  [PROJECT_DIRECTION.WHAT_NEED_TODO]: 'Что необходимо сделать, чтобы ситуация изменилась*',
  [PROJECT_DIRECTION.NECESSARY_SOURCES]: 'Какие ресурсы необходимы для каких этапов, в какой срок*'
}

export const INITIATOR_PROJECT = {
  WEBSITE: 'initiator_web_site',
  FIRST_SOCIAL_LINK: 'first_social_link',
  SECOND_SOCIAL_LINK: 'second_social_link',
  THIRD_SOCIAL_LINK: 'third_social_link'
}

export const PROJECT_FIELDS = {
  FCS: 'member_private_name',
  PHONE: 'member_private_phone',
  EMAIL: 'member_private_email',
  PROJECT_NAME: 'name',
  INITIATOR_NAME: 'initiator_name',
  INITIATOR_DESCRIPTION: 'initiator_description',
  INITIATOR_SOCIAL_LINKS: 'initiator_social_links',
  INITIATOR_WEBSITE: 'initiator_web_site',
  LEGAL_ADDRESS: 'initiator_legal_address',
  ...INITIATOR_PROJECT,
  ACTUAL_ADDRESS: 'initiator_physical_address',
  ORGANIZATION_LOGO: 'organization_logo',
  PROJECT_LOGO: 'project_logo',
  PROJECT_IDEA: 'short_description_idea',
  PROJECT_UNIQUE: 'short_description_unique',
  ...PROJECT_DIRECTION,
  PROJECT_FEATURES: 'short_description_features',
  TARGET_AUDIENCE: 'target_audience',
  TARGET_AUDIENCE_BENEFITS: 'target_audience_benefits',
  QUOTE: 'member_citation',
  INITIATOR_FCS: 'member_name',
  POSITION: 'member_position',
  AGENT_PHOTO: 'member_photo',
  FUND_SOURCES: 'fund_sources',
  PERSONAL_FUND_SOURCES: 'personal_fund_sources',
  BONUSES: 'bonuses',
  PROJECT_PHOTOS: 'photos',
  PROJECT_VIDEO_MESSAGE: 'video_message',
  PROJECT_ESTIMATE: 'estimate_document'
}

export const CREATE_PROJECT_FIELDS = {
  [PROJECT_FIELDS.FCS]: '',
  [PROJECT_FIELDS.PHONE]: '',
  [PROJECT_FIELDS.EMAIL]: '',
  [PROJECT_FIELDS.PROJECT_NAME]: '',
  [PROJECT_FIELDS.INITIATOR_NAME]: '',
  [PROJECT_FIELDS.INITIATOR_DESCRIPTION]: '',
  [PROJECT_FIELDS.ORGANIZATION_LOGO]: '',
  [PROJECT_FIELDS.LEGAL_ADDRESS]: '',
  [PROJECT_FIELDS.ACTUAL_ADDRESS]: '',
  [PROJECT_FIELDS.WEBSITE]: '',
  [PROJECT_FIELDS.FIRST_SOCIAL_LINK]: '',
  [PROJECT_FIELDS.SECOND_SOCIAL_LINK]: '',
  [PROJECT_FIELDS.THIRD_SOCIAL_LINK]: '',
  [PROJECT_FIELDS.PROJECT_LOGO]: '',
  [PROJECT_FIELDS.PROJECT_IDEA]: '',
  [PROJECT_FIELDS.PROJECT_UNIQUE]: '',
  [PROJECT_FIELDS.PROJECT_FEATURES]: '',
  [PROJECT_FIELDS.WHICH_PROBLEM_SOLVE]: '',
  [PROJECT_FIELDS.DESCRIPTION_SITUATION]: '',
  [PROJECT_FIELDS.DESCRIPTION_RESULT]: '',
  [PROJECT_FIELDS.WHAT_WAS_DONE]: '',
  [PROJECT_FIELDS.WHAT_NEED_TODO]: '',
  [PROJECT_FIELDS.NECESSARY_SOURCES]: '',
  [PROJECT_FIELDS.TARGET_AUDIENCE]: '',
  [PROJECT_FIELDS.TARGET_AUDIENCE_BENEFITS]: '',
  [PROJECT_FIELDS.QUOTE]: '',
  [PROJECT_FIELDS.INITIATOR_FCS]: '',
  [PROJECT_FIELDS.POSITION]: '',
  [PROJECT_FIELDS.AGENT_PHOTO]: '',
  [PROJECT_FIELDS.FUND_SOURCES]: '',
  [PROJECT_FIELDS.PERSONAL_FUND_SOURCES]: '',
  [PROJECT_FIELDS.BONUSES]: '',
  [PROJECT_FIELDS.PROJECT_PHOTOS]: '',
  [PROJECT_FIELDS.PROJECT_VIDEO_MESSAGE]: '',
  [PROJECT_FIELDS.PROJECT_ESTIMATE]: '',
  [PROJECT_FIELDS.INITIATOR_SOCIAL_LINKS]: [ '' ]
}
const phoneRegExp = /^\+?[78][-\\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/

export const FIELDS_NAMES = [
  'member_private_name',
  'member_private_phone',
  'member_private_email',
  'name',
  'initiator_name',
  'initiator_description',
  'initiator_legal_address',
  'initiator_physical_address',
  'initiator_web_site',
  'first_social_link',
  'second_social_link',
  'third_social_link',
  'organization_logo',
  'short_description_idea',
  'short_description_unique',
  'short_description_features',
  'project_goal_problems',
  'project_goal_current_state_of_affairs',
  'project_goal_desired_result',
  'project_goal_already_done',
  'project_goal_required_steps',
  'project_goal_required_resources',
  'target_audience',
  'target_audience_benefits',
  'member_citation',
  'member_name',
  'member_position',
  'member_photo',
  'fund_sources',
  'personal_fund_sources',
  'bonuses',
  'photos',
  'video_message',
  'estimate_document'
]

export const VALIDATION_SCHEMA = Yup.object()
  .shape({
    [PROJECT_FIELDS.FCS]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.PHONE]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
      .matches(phoneRegExp, FORM_FIELD_ERROR.PHONE_INCORRECT),
    [PROJECT_FIELDS.EMAIL]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
      .email(FORM_FIELD_ERROR.EMAIL_INCORRECT),
    [PROJECT_FIELDS.PROJECT_NAME]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
      .max(128, FORM_FIELD_ERROR.TOO_LARGE),
    [PROJECT_FIELDS.INITIATOR_NAME]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
      .max(128, FORM_FIELD_ERROR.TOO_LARGE),
    [PROJECT_FIELDS.INITIATOR_DESCRIPTION]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.ORGANIZATION_LOGO]: Yup
      .array()
      .required(FORM_FIELD_ERROR.REQUIRED_LOGO),
    [PROJECT_FIELDS.PROJECT_LOGO]: Yup
      .array()
      .required(FORM_FIELD_ERROR.REQUIRED_LOGO),
    [PROJECT_FIELDS.AGENT_PHOTO]: Yup
      .array()
      .required(FORM_FIELD_ERROR.REQUIRED_LOGO),
    [PROJECT_FIELDS.PROJECT_PHOTOS]: Yup
      .array()
      .min(4, 'Добавьте не менее 4-х изображений')
      .required(FORM_FIELD_ERROR.REQUIRED_LOGO),
    [PROJECT_FIELDS.PROJECT_ESTIMATE]: Yup
      .array()
      .required(FORM_FIELD_ERROR.REQUIRED_LOGO),
    [PROJECT_FIELDS.LEGAL_ADDRESS]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.ACTUAL_ADDRESS]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.PROJECT_IDEA]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.PROJECT_UNIQUE]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.PROJECT_FEATURES]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.WHICH_PROBLEM_SOLVE]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.DESCRIPTION_SITUATION]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.DESCRIPTION_RESULT]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.WHAT_WAS_DONE]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.WHAT_NEED_TODO]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.NECESSARY_SOURCES]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.TARGET_AUDIENCE]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.TARGET_AUDIENCE_BENEFITS]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.QUOTE]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
      .max(144, FORM_FIELD_ERROR.TOO_LARGE),
    [PROJECT_FIELDS.INITIATOR_FCS]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.POSITION]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.FUND_SOURCES]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.PERSONAL_FUND_SOURCES]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.BONUSES]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.PROJECT_VIDEO_MESSAGE]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    [PROJECT_FIELDS.INITIATOR_WEBSITE]: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
  })

export const CRATE_PROJECT_VALIDATION = Yup.object()
  .shape({
    title: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    info: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    description: Yup
      .string()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    logo: Yup
      .array()
      .required(FORM_FIELD_ERROR.REQUIRED_LOGO),
    age_group: Yup
      .array()
      .required(FORM_FIELD_ERROR.REQUIRED_AT_LEAST_ONE_OPTION),
    participants_count: Yup
      .number()
      .min(1)
      .max(10000, 'Допустимое значение – до 10000')
      .typeError(FORM_FIELD_ERROR.TYPE_NUMBER)
      .positive(FORM_FIELD_ERROR.MUST_BE_POSITIVE)
      .integer(FORM_FIELD_ERROR.MUST_BE_INTEGER)
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    goal: Yup
      .number()
      .typeError(FORM_FIELD_ERROR.TYPE_NUMBER)
      .positive(FORM_FIELD_ERROR.MUST_BE_POSITIVE)
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    category: Yup
      .mixed()
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    'video_message': Yup.string().matches(YOUTUBE_MATCH, FORM_FIELD_ERROR.VIDEO_LINK_FORMAT),
    'video_link-0': Yup.string().matches(YOUTUBE_MATCH, FORM_FIELD_ERROR.VIDEO_LINK_FORMAT),
    'video_description-0': Yup.string().when('video_link-0', {
      is: link => link,
      then: Yup.string().required(FORM_FIELD_ERROR.REQUIRED_FIELD)
    })
  }, [ 'category', 'request_category' ])

export const PROJECT_AGE_GROUPS = [
  { value: 1, label: 'Дошкольная' },
  { value: 2, label: 'Младшая' },
  { value: 3, label: 'Средняя' },
  { value: 4, label: 'Старшая' },
  { value: 5, label: 'Дополнительное образование' }
]

export const ERROR_MESSAGES = {
  LOGO: 'Возникла серверная ошибка'
}

export const DOCUMENTS = {
  ORGANIZATION_LOGO: {
    uri: 'Требования_к_логотипу_организаций.pdf',
    title: 'Требования к логотипу организации'
  },
  PROJECT_LOGO: {
    uri: 'Требования_к_логотипу_проекта.pdf',
    title: 'Требования к логотипу проекта'
  },
  AGENT_PHOTO: {
    uri: 'Требования_к_фотографии_инициатора_проекта.pdf',
    title: 'Требования к фотографии инициатора проекта'
  },
  PROJECT_PHOTOS: {
    uri: 'Требования_к_фотоматериалам_проекта.pdf',
    title: 'Требования к фотоматериалам проекта'
  }
}
