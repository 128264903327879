import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import { API_IMG } from '../../constants/Api.constants'
import noUserPicture from '../../ge/no-userpic.svg'
import CardSlider from '../CardSlider'

import { getResponsiveConfig } from './constants'

const ProjectMaterials = ({ videos, files, handleMediaItemClick }) => {
  const materialsCount = ((
    Array.isArray(videos) && videos.length) || 0) + (((Array.isArray(files)) && files.length) || 0)
  const slidesToShow = materialsCount < 4 ? materialsCount : 4
  const sliderRef = useRef(null)

  return (
    <React.Fragment>
      <div className="materials project__text-container" ref={sliderRef}>
        {(materialsCount === 0) &&
          <React.Fragment>
            <div>Материалы у проекта отсутствуют.</div>
          </React.Fragment>
        }
        {(materialsCount !== 0) && <CardSlider
          slidesToShow={slidesToShow}
          slidesToScroll={4}
          responsive={getResponsiveConfig(slidesToShow)}>
          {files && files.map((material, index) => (
            <div className="active-projects__slide" key={`file-image_${index}`}>
              <div
                key={`image_${index}`}
                className="materials__image"
                onClick={() => handleMediaItemClick('image', material)}>
                <img
                  alt="The material of project."
                  className="materials__image-wrapper"
                  src={material && material.filename ? `${API_IMG}/${material.filename}` : noUserPicture}
                />
              </div>
            </div>
          ))}

          {videos.map((video, index) => (
            <div className="materials__material" key={`file-video_${index}`}>
              <img
                alt={'The Youtube preview.'}
                key={`material_${index}`}
                className="materials__image"
                src={video.preview}
                onClick={() => handleMediaItemClick('video', video)}
              />
            </div>
          ))}
        </CardSlider>
        }
        {/* WIP: <div className="project__material-button"><a href="/">Все материалы</a></div> */}
      </div>
    </React.Fragment>
  )
}

export default ProjectMaterials

ProjectMaterials.propTypes = {
  files: PropTypes.array,
  handleMediaItemClick: PropTypes.func,
  videos: PropTypes.array
}
