import React from 'react'
import { Formik } from 'formik/dist/index'
import { useSelector, useDispatch } from 'react-redux'

import BaseLayout from '../../layouts/BaseLayout'
import { FiledropField, TextField } from '../../components/Input'
import { Button } from '../../components/Button'
import Loader from '../../components/Loader'
import { API_IMG } from '../../constants/Api.constants'

import {
  saveProfileAction,
  uploadAvatarAction,
  removeAvatarAction
} from './Profile.actions'
import {
  PROFILE_FORM_FIELDS,
  getInitialValues
} from './Profile.constants'
import { getProfileValidation } from './utils/validation'

const Profile = () => {
  const { avatar, isFetching } = useSelector(state => state.profile)
  const { user } = useSelector(state => state.auth)

  const dispatch = useDispatch()

  const onSubmit = (values) => {
    dispatch(saveProfileAction({ ...values, user, avatar: avatar && avatar.file_id }))
  }

  if (!user) {
    return (
      <BaseLayout>
        <div className="container-app">
          <div className="container-fluid profile-form">
            <div className="news-article__loader">
              <Loader />
            </div>
          </div>
        </div>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout>
      <div className="container-app">
        <div className="container-fluid profile-form">
          <h1>Ваш профиль</h1>
          <Formik
            initialValues={getInitialValues(user)}
            enableReinitialize
            validationSchema={getProfileValidation()}
            onSubmit={values => onSubmit(values)}
          >
            {(f) => (
              <form className="text-center margin-top-60 profile-container"
                onSubmit={f.handleSubmit}>

                <div className="profile__avatar-container">
                  {!avatar && !isFetching && <FiledropField
                    name={PROFILE_FORM_FIELDS.AVATAR}
                    label="Загрузите фото"
                    isMultiple={false}
                    isShowHint={true}
                    value={f.values.avatar}
                    modifier
                    fileListDisplay={false}
                    error={f.errors.avatar && f.touched.avatar && f.errors.avatar}
                    onChange={(name, img) => {
                      img.length !== 0 && dispatch(uploadAvatarAction(img))
                      f.setFieldValue(name, avatar)
                    }}
                    onBlur={f.handleBlur}
                    cnInput="profile__avatar"
                  />}

                  {isFetching &&
                  <div className="profile__avatar-loader">
                    <Loader />
                  </div>}

                  {!!avatar && !isFetching && <React.Fragment>
                    <img className="profile__avatar-img"
                      src={`${API_IMG}/${avatar.filename}`} alt="Avatar" />
                    <div className="profile__avatar-delete"
                      onClick={() => {
                        f.setFieldValue(PROFILE_FORM_FIELDS.AVATAR, [])
                        dispatch(removeAvatarAction())
                      }}>
                      <svg className="icon--close profile__avatar-delete-svg" />
                    </div>
                  </React.Fragment>}

                </div>

                <div className="profile__information-container">
                  <h3 className="profile__information-title">Персональная
                    информация</h3>
                  <TextField
                    required
                    medium
                    tabIndex="1"
                    type="mail"
                    name={PROFILE_FORM_FIELDS.EMAIL}
                    label="Email*"
                    value={f.values.email}
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    className="authorization-form__input"
                    error={f.errors.email && f.touched.email && f.errors.email}
                  />
                  <TextField
                    medium
                    tabIndex="2"
                    name={PROFILE_FORM_FIELDS.FIRST_NAME}
                    label="Имя*"
                    value={f.values.first_name}
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    className="authorization-form__input"
                    error={f.errors.first_name && f.touched.first_name && f.errors.first_name}
                  />
                  <TextField
                    medium
                    tabIndex="3"
                    name={PROFILE_FORM_FIELDS.MIDDLE_NAME}
                    label="Отчество"
                    value={f.values.middle_name}
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    className="authorization-form__input"
                  />
                  <TextField
                    medium
                    tabIndex="4"
                    name={PROFILE_FORM_FIELDS.LAST_NAME}
                    label="Фамилия*"
                    value={f.values.last_name}
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    className="authorization-form__input"
                    error={f.errors.last_name && f.touched.last_name && f.errors.last_name}
                  />
                  <TextField
                    medium
                    tabIndex="5"
                    name={PROFILE_FORM_FIELDS.COMPANY}
                    label="Организация"
                    value={f.values.company}
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    className="authorization-form__input"
                  />
                  <TextField
                    medium
                    tabIndex="6"
                    name={PROFILE_FORM_FIELDS.POSITION}
                    label="Должность"
                    value={f.values.position}
                    onChange={f.handleChange}
                    onBlur={f.handleBlur}
                    className="authorization-form__input"
                  />

                  <div
                    className="create-form__required-description profile__information-prompt">
                    <span>* Обязательно для заполнения</span>
                  </div>

                  <div className="profile__bottom-container">
                    <Button
                      type="submit"
                      className="margin-top-45 profile__information-button"
                      medium
                      label="Сохранить"
                      color={!f.isValid || isFetching ? 'gray' : 'blue'}
                      disabled={!f.isValid || isFetching}
                      width="210px"
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </BaseLayout>
  )
}

export default Profile
