export const DOCUMENTS = [
  {
    id: 1,
    img: '/images/docs/ils_свидетельство-1.png',
    alt: 'АНО Платформа ОП (ИНН+ОГРН)',
    title: 'АНО Платформа ОП (ИНН+ОГРН)',
    download: 'АНО_Платформа_ОП_(ИНН+ОГРН).pdf'
  },
  {
    id: 2,
    img: '/images/docs/ils_свидетельство-2.png',
    alt: 'АНО Платформа ОП (МИНЮСТ)',
    title: 'АНО Платформа ОП (МИНЮСТ)',
    download: 'АНО_Платформа_ОП_(МИНЮСТ).pdf'
  },
  {
    id: 3,
    img: '/images/docs/ils_устав.png',
    alt: 'АНО Платформа ОП (УСТАВ)',
    title: 'АНО Платформа ОП (УСТАВ)',
    download: 'АНО_Платформа_ОП_(УСТАВ).pdf'
  },
  {
    id: 4,
    img: '/images/docs/Выписка_из_ЕГРЮЛ.png',
    alt: 'Выписка из ЕГРЮЛ',
    title: 'Выписка из ЕГРЮЛ',
    download: 'Выписка_из_ЕГРЮЛ.pdf'
  },
  {
    id: 5,
    img: '/images/docs/Годовой_отчет.png',
    alt: 'Годовой отчет за 2019',
    title: 'Годовой отчет за 2019',
    download: 'Годовой_отчет_2019.pdf'
  },
  {
    id: 6,
    img: '/images/docs/Реквизиты.png',
    alt: 'АНО Платформа ОП (РЕКВИЗИТЫ)',
    title: 'АНО Платформа ОП (РЕКВИЗИТЫ)',
    download: 'Реквизиты_компании.pdf'
  },
  {
    id: 7,
    img: '/images/docs/Годовой_отчет.png',
    alt: 'Годовой отчет за 2020',
    title: 'Годовой отчет за 2020',
    download: 'Отчет_2020.pdf'
  },
  {
    id: 8,
    img: '/images/docs/форма_ОН0001.png',
    alt: 'АНО МИНЮСТ форма 1 2021 год',
    title: 'АНО МИНЮСТ форма 1 2021 год',
    download: 'АНО_МИНЮСТ_2021_ФОРМА_ОН0001.pdf'
  },
  {
    id: 9,
    img: '/images/docs/форма_ОН0002.png',
    alt: 'АНО МИНЮСТ форма 2 2021 год',
    title: 'АНО МИНЮСТ форма 2 2021 год',
    download: 'АНО_МИНЮСТ_2021_ФОРМА_ОН0002.pdf'
  }
]
