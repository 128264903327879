
import agent from '../../config/methods'

import { GET_PROJECTS_FAIL, GET_PROJECTS_REQUEST, GET_PROJECTS_SUCCESS, PROJECTS_CLEAR_STATE } from './SearchProject.constants'

export function getProjectList (query, projectsCount, sortParams) {
  const filters = []

  // TODO: rename like to eq for new back-end version.
  const searchParams = {
    per_page: projectsCount,
    sort_by: 'created_at',
    'q': [ 'status__like__published', ...filters ],
    ...sortParams
  }

  if (query !== '') {
    searchParams.search_query = query
  }

  return async function action (dispatch) {
    dispatch({ type: GET_PROJECTS_REQUEST })

    try {
      const response = await agent.GET('/project', searchParams)
      dispatch({
        type: GET_PROJECTS_SUCCESS,
        payload: {
          projects: response.data
        }
      })
    } catch (error) {
      dispatch({
        type: GET_PROJECTS_FAIL,
        payload: {
          error
        }
      })
    }
  }
}

export function clearState () {
  return function action (dispatch) {
    dispatch({ type: PROJECTS_CLEAR_STATE })
  }
}
