export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://test.iloveschool.help:8443'

/* Use for features without port name (Sharing) */
const URL_DETAILS = new URL(BASE_URL)
export const HOSTNAME_URL = `${URL_DETAILS.protocol}//${URL_DETAILS.hostname}`

export const API_ORIGIN = `${BASE_URL}/api/v1`
export const API_IMG = `${BASE_URL.replace(':8443', '')}/media`
export const YOUTUBE_IMG_URL = 'https://img.youtube.com/vi/'

export const FACEBOOK_PIXEL_ID = 3240638822644183

// dev: pk_010e833ca96a3c4ee7ce1d692c840, prod: pk_21a28bc49da182dd43f38e7162b05
export const PUBLIC_ID = process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID || 'pk_010e833ca96a3c4ee7ce1d692c840'
export const MERCHANT_ID = 'BCR2DN6T36IJ3Z2O'
export const APPLE_PAY_MERCHANT_ID = 'merchant.help.iloveschool'

// DADATA
export const API_KEY_DADATA = 'a3056759cc530a55d77291c42dbb81eb73d5e6b4'
export const BASE_URL_DADATA = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest'
export const SUGGESTIONS_NUMBER = 5

const VK_DATA = {
  AUTH_URL: 'https://oauth.vk.com/authorize',
  CLIENT_ID: '7182338',
  REDIRECT_URI: `${HOSTNAME_URL}/auth/vk/`,
  V: '5.103'
}

export const VK_AUTH_URL = `${VK_DATA.AUTH_URL}?client_id=${VK_DATA.CLIENT_ID}&redirect_uri=${VK_DATA.REDIRECT_URI}` +
  `&scope=email&response_type=code&v=${VK_DATA.V}`

const FB_DATA = {
  AUTH_URL: 'https://www.facebook.com/v9.0/dialog/oauth',
  CLIENT_ID: '1418449958293788',
  REDIRECT_URI: `${HOSTNAME_URL}/auth/fb/`
}

export const FB_AUTH_URL = `${FB_DATA.AUTH_URL}?client_id=${FB_DATA.CLIENT_ID}&redirect_uri=${FB_DATA.REDIRECT_URI}` +
 '&scope=email'

export const BASE_REQUEST = {
  apiVersion: 2,
  apiVersionMinor: 0,
  emailRequired: true
}

export const TOKENIZATION_SPECIFICATION = {
  type: 'PAYMENT_GATEWAY',
  parameters: {
    'gateway': 'cloudpayments',
    'gatewayMerchantId': PUBLIC_ID
  }
}

export const ALLOWED_CARD_NETWORKS = [ 'AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA' ]
export const ALLOWED_CARD_AUTH_METHODS = [ 'PAN_ONLY', 'CRYPTOGRAM_3DS' ]

export const BASE_CARD_PAYMENT_METHOD = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods: ALLOWED_CARD_AUTH_METHODS,
    allowedCardNetworks: ALLOWED_CARD_NETWORKS
  }
}

export const CARD_PAYMENT_METHOD = {
  ...BASE_CARD_PAYMENT_METHOD,
  tokenizationSpecification: TOKENIZATION_SPECIFICATION
}
