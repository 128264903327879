import React, { useState, useEffect, useRef } from 'react'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'

import { SliderButton } from '../Button'
import { formatYoutubeLink } from '../../pages/utils/formatFilesData'
import { API_IMG } from '../../constants/Api.constants'

/**
 * Shows specific materials selected on the Project's page.
 * Shows neighboring materials in endless mode (Next-Prev material).
 * Materials (data) is Images and Videos by YouTube Link, description.
 *
 * @param src
 * @param videoLink
 * @param onClose
 * @param videoDescription
 * @param data
 * @returns {*}
 * @constructor
 */

const MediaPopup = ({ src, videoLink, onClose, videoDescription, data }) => {
  const [ imageSrc, setImageSrc ] = useState(src)

  const [ youtubeLink, setYoutubeLink ] = useState(videoLink)
  const [ formattedLink, setFormattedLink ] = useState(formatYoutubeLink(videoLink))
  const [ description, setDescription ] = useState(videoDescription)

  const modalRef = useRef(null)

  useEffect(() => {
    disableBodyScroll(modalRef.current)
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  const clearMedia = (mode) => {
    if (mode === 'video') {
      setYoutubeLink(null)
      setDescription(null)
      setFormattedLink(null)
    } else if (mode === 'img') {
      setImageSrc(null)
    }
  }

  const setVideoData = (video) => {
    setYoutubeLink(video.link)
    setFormattedLink(formatYoutubeLink(video.link))
    setDescription(video.description)
  }

  const handleSetSrc = (path) => {
    setImageSrc(`${API_IMG}/${path}`)
  }

  const handleChangeMedia = (action, e) => {
    e.stopPropagation()
    const dataSize = data.length - 1
    const lastElement = data[dataSize]

    const currentMediaPosition = data
      .findIndex(item =>
        (imageSrc && item.filename === imageSrc.split('media/')[1]) || item.link === youtubeLink
      )

    const anotherMediaElement = data
      .filter((item, id) =>
        id === (action === 'left' ? currentMediaPosition - 1 : currentMediaPosition + 1)
      )

    if (anotherMediaElement.length !== 0 && anotherMediaElement[0].filename) {
      clearMedia('video')
      handleSetSrc(anotherMediaElement[0].filename)
    }

    if (anotherMediaElement.length !== 0 && anotherMediaElement[0].link) {
      clearMedia('img')
      setVideoData(anotherMediaElement[0])
    }

    if (anotherMediaElement.length === 0 && data.length !== 0) {
      if (action === 'right' && currentMediaPosition === dataSize) {
        if (data[0].filename) {
          clearMedia('video')
          handleSetSrc(data[0].filename)
        } else if (data[0].link) {
          clearMedia('img')
          setVideoData(data[0])
        }
      }

      if (action === 'left' && currentMediaPosition === 0) {
        if (lastElement.filename) {
          clearMedia('video')
          handleSetSrc(lastElement.filename)
        } else if (lastElement.link) {
          clearMedia('img')
          setVideoData(lastElement)
        }
      }
    }
  }

  return (
    <div className="modal">
      <div className="modal-content" ref={modalRef}>
        <div>
          <div className="modal-content__close-icon" onClick={() => onClose(false)} />
          <div className="modal-content__file-controls">
            <div className="modal-content__controls">
              <SliderButton
                blue
                white={false}
                light={false}
                left
                onClick={(e) => handleChangeMedia('left', e)}
              />
              <SliderButton
                blue
                white={false}
                light={false}
                right
                onClick={(e) => handleChangeMedia('right', e)}
              />
            </div>
            {imageSrc &&
              <div className="modal-content__file">
                <img src={`${imageSrc}`} alt="material of project" />
              </div>
            }
          </div>

          {formattedLink &&
            <div className="modal-content__file">
              <iframe
                title="youtube"
                frameBorder="0"
                allow="accelerometer; autoplay; preview; encrypted-media; gyroscope; picture-in-picture"
                src={formattedLink}
              />
            </div>
          }

          {description &&
            <div className="modal-content__video-block">
              <h3>Описание</h3>
              <p>{description}</p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default MediaPopup
