import * as Yup from 'yup'

export const getProfileValidation = () => {
  return (
    Yup.object().shape({
      email: Yup.string()
        .required('Данное поле должно быть заполнено.')
        .matches(/^\S+@\S+\.\S+$/, 'Введите корректный адрес электронной почты.'),
      first_name: Yup.string()
        .required('Данное поле должно быть заполнено.')
        .matches(/^[a-zA-Zа-яёА-ЯЁ'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, 'Имя должно состоять из русских или английских символов.'),
      last_name: Yup.string()
        .required('Данное поле должно быть заполнено.')
        .matches(/^[a-zA-Zа-яёА-ЯЁ'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, 'Фамилия должна состоять из русских или английских символов.')
    })
  )
}
