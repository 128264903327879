export const getResponsiveConfig = (slidesToShow) => {
  return ([
    {
      breakpoint: 600,
      settings: {
        slidesToShow: slidesToShow === 1 ? 1 : 3,
        slidesToScroll: 3,
        initialSlide: 0
      }
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: slidesToShow === 1 ? 1 : 2,
        slidesToScroll: 2,
        initialSlide: 0
      }
    }
  ])
}
