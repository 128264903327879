
import loadReduxTypes from '../../helpers/loadReduxTypes'

const GET_SCHOOLS = loadReduxTypes('GET_SCHOOLS')
const SCHOOLS_CLEAR_STATE = 'SCHOOLS_CLEAR_STATE'

const initialState = {
  data: [],
  isFetching: false,
  error: null,
  total: 0,
  isEmpty: true
}

export default function schoolsReducer (state = initialState, { type, payload }) {
  switch (type) {
  case GET_SCHOOLS.REQUEST:
    return {
      ...state,
      isFetching: true,
      isEmpty: false
    }

  case GET_SCHOOLS.SUCCESS:
    return {
      ...state,
      data: payload.schools,
      total: payload.total,
      isFetching: false
    }

  case GET_SCHOOLS.FAILURE:
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }

  case SCHOOLS_CLEAR_STATE:
    return initialState

  default: return {
    ...state
  }
  }
}
