import React from 'react'
import moment from 'moment'

import { Button } from '../Button/Button'

const PaymentCheck = ({ info, onClose }) => {
  const {
    data: {
      cardholder_message: message,
      data: {
        amount
      },
      currency,
      card_last_four: lastDigitsCard,
      auth_date: authDate,
      email
    },
    event_type: resultPayment
  } = info
  const isFailedPayment = resultPayment === 'fail'

  const createTemplateCard = () => {
    return Array(16).fill('*').join('').replace(/.{4}$/, `${lastDigitsCard}`).replace(/(.{4})/g, '$1 ')
  }

  return (
    <div className="check-container">
      <h1 className="check-title">{isFailedPayment ? 'Ошибка операции' : 'Успешная операция'}</h1>
      <div className="check">
        <h2 className="check-result">{message}</h2>
        <ul className="check-list">
          <li
            className="check-list__item">
            <span className="check-list__item-title">Сумма: </span>
            {amount || '—'} {currency || 'RUB'}
          </li>
          {!isFailedPayment && (
            <li
              className="check-list__item">
              <span className="check-list__item-title">Номер карты: </span>
              {(!isFailedPayment && createTemplateCard()) || '—'}
            </li>)}
          <li
            className="check-list__item">
            <span className="check-list__item-title">Дата: </span>
            {moment(authDate).format('DD.MM.YYYY kk:mm')}
          </li>
          {email && (<li
            className="check-list__item">
            <span className="check-list__item-title">Email: </span>
            {email}
          </li>)}
        </ul>
      </div>
      <Button
        medium
        onClick={onClose}
        className="check__button-close"
        label="Закрыть"
        color="blue"
      />
    </div>
  )
}

export default PaymentCheck
