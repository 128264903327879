import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import ProjectCard from '../../components/ProjectCard'
import BaseLayout from '../../layouts/BaseLayout'
import { Button } from '../../components/Button'
import { SCHOOLS_PER_PAGE } from '../Schools/Schools.constants'
import Loader from '../../components/Loader'
import { useStateClearance } from '../../hooks/useStateClearance.hook'
import PaymentFormContainer from '../../components/PaymentFormContainer/PaymentFormContainer'

import { SCHOOL_PROJECTS_PER_PAGE } from './SchoolsProjects.constants'
import { getSchoolProjectsAction, clearState } from './SchoolProjects.action'

const SchoolProjects = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const {
    schoolProjects: { data, isFetching, total, error, schoolName },
    project: { isPaymentFormVisible }
  } = useSelector(state => state)
  const [ selectedProject, setSelectedProject ] = useState()
  const [ isSuccessPaymentModal, setIsSuccessPaymentModal ] = useState()
  const dispatch = useDispatch()
  const [ schoolsCount, setSchoolsCount ] = useState(SCHOOL_PROJECTS_PER_PAGE)

  useStateClearance(clearState)

  useEffect(() => {
    dispatch(getSchoolProjectsAction(match.params.id, schoolsCount))
  }, [ dispatch, schoolsCount, match.params.id ])

  if ((isFetching && (schoolsCount < SCHOOLS_PER_PAGE)) || !data) {
    const errorMessage = (error && error === 'not_found' && 'Инициатор проекта не найден') || 'Возникла ошибка'

    return (
      <BaseLayout>
        <div className="container-app">
          <div className="container-fluid school-projects__content-wrapper">
            {error &&
              <div>
                <h1>{errorMessage}</h1>
                <Button
                  label="На страницу инициатора"
                  color="blue"
                  onClick={() => history.push('/schools')}
                  width="256px"
                />
              </div>
            }
            {!error && <Loader />}
          </div>
        </div>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout>
      <div className="container-app">
        <div className="container-fluid">
          <div className="school-projects">
            <h1 className="school-projects__title">Проекты инициатора {schoolName}</h1>
            <div className="row project-cards school-projects__content">
              {(data && data.length === 0 && !error && !isFetching) &&
                <div className="school-projects__content-wrapper">
                  В настоящий момент проекты у инициатора отсутствуют.
                </div>
              }

              {data && data.map((project) => (
                <div className="col-6 col-xl-3 px-1 school-projects__card" key={`item_${project.id}`}>
                  <ProjectCard
                    openPaymentForm={() => setSelectedProject(project)}
                    key={`project_${project.id}`}
                    title={project.title}
                    link={`/projects/${project.id}`}
                    stage={project.stage}
                    collected={project.collected}
                    goal={project.goal}
                    logo={project.logo}
                    description={project.description}
                    balance={project.balance}
                  />
                </div>
              )
              )}
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-auto col">
                {schoolsCount < total && <Button
                  disabled={isFetching}
                  label={isFetching ? 'Загрузка' : 'Показать еще'}
                  width="100%"
                  className="btn__show-more"
                  onClick={() => setSchoolsCount(schoolsCount + SCHOOL_PROJECTS_PER_PAGE)}
                  large
                  nobg
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaymentFormContainer
        isPaymentFormVisible={isPaymentFormVisible}
        isSuccessPaymentModal={isSuccessPaymentModal}
        setIsSuccessPaymentModal={setIsSuccessPaymentModal}
        selectedProject={selectedProject}
      />
    </BaseLayout>
  )
}

export default SchoolProjects
