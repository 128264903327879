import { toast } from 'react-toastify'

import agent from '../../config/methods'

import {
  GET_NEWS_ARTICLE_FAIL,
  GET_NEWS_ARTICLE_REQUEST,
  GET_NEWS_ARTICLE_SUCCESS,
  CLEAR_NEWS_STORAGE
} from './NewsArticle.constants'

function getNewsArticle (params) {
  return async function action (dispatch) {
    dispatch({ type: GET_NEWS_ARTICLE_REQUEST })

    try {
      const response = await agent.GET(`/news/${params}`)
      dispatch({
        type: GET_NEWS_ARTICLE_SUCCESS,
        payload: {
          newsArticle: response.data,
          nextArticle: response.data.next_id,
          previousArticle: response.data.previous_id
        }
      })
    } catch (error) {
      if (error.response.status === 404) {
        toast.error('Новость по указанному адресу не найдена.')
      }

      dispatch({
        type: GET_NEWS_ARTICLE_FAIL,
        payload: {
          error
        }
      })

      if (error.response.status > 300) {
        throw new Error('not-found')
      }
    }
  }
}

function clearNewsStorage () {
  return async function action (dispatch) {
    dispatch({ type: CLEAR_NEWS_STORAGE })
  }
}

export { getNewsArticle, clearNewsStorage }
