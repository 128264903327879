import PropTypes from 'prop-types'
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { openAuthorizationForm } from '../layouts/BaseLayout.actions'

/* Components, which required Authorization: CreateProject-page  */

const PrivateRoute = ({ component, path, ...rest }) => {
  const { isAuthorized } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  if (!isAuthorized) {
    dispatch(openAuthorizationForm())
    return (
      <Redirect to="/" />
    )
  }

  return (
    <Route path={path} component={component} {...rest} />
  )
}

export default PrivateRoute

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired
}
