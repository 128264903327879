import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { closeAuthorizationForm, closeRegistrationForm, closeRegistrationSuccess } from '../BaseLayout.actions'
import { GenderIcon } from '../../components/GenderIcon/GenderIcon'
import { Button } from '../../components/Button'

const RegistrationSuccess = ({
  closeAuthorizationForm,
  registeredUser,
  closeRegistrationForm,
  closeRegistrationSuccess
}) => {
  const [ isViewMount, setIsViewMount ] = useState(false)

  const registrationSuccessForm = classNames({
    'authorization-form': true,
    'authorization-form--show': isViewMount
  })

  useEffect(() => {
    setIsViewMount(true)
  }, [])

  const onCloseForm = () => {
    setIsViewMount(false)
    closeAuthorizationForm()
    closeRegistrationForm()
    setTimeout(() => closeRegistrationSuccess(), 300)
  }

  const userFirstName = registeredUser.data.user.first_name
  const userPicture = registeredUser.data.user.avatar_type

  const userPath = userPicture ? `/images/${userPicture}.svg` : '/images/female-icons/no-userpic.svg'

  return (
    <div className={registrationSuccessForm}>
      <div
        className="authorization-form__mask"
        onClick={() => onCloseForm()}
      />

      <div className="authorization-form__content">
        <div className="authorization-form__content-wrap text-center">
          <i
            className="icon icon--close authorization-form__close"
            onClick={() => onCloseForm()}
          />
          <h4 className="registration-success-form__title">Регистрация прошла успешно!</h4>

          <div className="registration-success-form__avatar">
            <GenderIcon
              key={1}
              item={{ id: '1', img: userPath }}
              selectedIcon=""
              modifier="large"
            />
          </div>
          <div className="authorization-form__hello-messages">
            <div className="registration-success-form__hello-message">
              <h2>Привет, {userFirstName}</h2>
            </div>
            <div className="registration-success-form__glad-message">
              Мы направили вам на указанный адрес письмо — перейдите по ссылке внутри этого письма и подтвердите адрес. Тогда регистрация будет завершена!
            </div>
            <Button
              tabIndex="3"
              label="Готово"
              color="orange"
              width="100%"
              className="registration-success-form__button"
              onClick={() => onCloseForm()}
            />
          </div>
        </div>
      </div>

    </div>
  )
}

const mapDispatchToProps = {
  closeAuthorizationForm,
  closeRegistrationForm,
  closeRegistrationSuccess
}

const mapStateToProps = ({ baseLayoutReducer: { registeredUser } }) => ({
  registeredUser
})

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationSuccess)
