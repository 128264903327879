import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

const Switch = ({
  leftText,
  rightText,
  wrapperClass,
  changeHandler,
  leftDisabled,
  rightDisabled,
  onLeftHandler,
  onRightHandler
}) => {
  const [ type, setType ] = useState('left')

  const getClassButton = (currentType) => classNames('switch-button', {
    'switch-button_active': type === currentType
  })

  const onButtonHandler = (typeButton, handler) => {
    return handler ? handler(() => setType(typeButton)) : setType(typeButton)
  }

  useEffect(() => {
    if (changeHandler) {
      changeHandler(type === 'right')
    }
  }, [ type, changeHandler ])

  return (
    <div className={classNames('switch-wrapper', wrapperClass)}>
      <button
        type="button"
        className={getClassButton('left')}
        onClick={() => onButtonHandler('left', onLeftHandler)}
        disabled={leftDisabled}
      >
        {leftText}
      </button>
      <button
        type="button"
        className={getClassButton('right')}
        onClick={() => onButtonHandler('right', onRightHandler)}
        disabled={rightDisabled}
      >
        {rightText}
      </button>
    </div>
  )
}

export default Switch
