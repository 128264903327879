export const WORK_INFO = [
  { id: '01',
    title: 'Размещение',
    text: 'Преподаватель или представитель образовательного учреждения размещают свой проект на платформе ' +
    '«ILoveSchool» с описанием того, что нужно для его реализации – сроки, стоимость и предполагаемые результаты. ' +
    'После этого наша команда проверяет и оценивает проект с помощью внешних экспертов. '
  },
  { id: '02',
    title: 'Продвижение',
    text: 'Продвижение, основанное на собственной технологии «ILoveSchool», ' +
    'позволяет нам максимально быстро и эффективно распространить информацию о проекте и привлечь необходимое ' +
    'финансирование.'
  },
  { id: '03',
    title: 'Реализация',
    text: '«ILoveSchool» самостоятельно реализует заявленные проекты – приобретает, ' +
    'устанавливает и настраивает необходимое оборудование, проводит юридическое оформление и передачу имущества в ' +
    'собственность образовательного учреждения. Важным условием нашей работы является обеспечение обратной связи ' +
    'от образовательных учреждений и отчетность за потраченные средства партнерам проекта.'
  },
  { id: '04',
    title: 'Завершение',
    text: 'После реализации идеи «I Love School» помогает оставаться на связи сообществу партнеров проекта и образовательному учреждению, а также привлекает заинтересованных выпускников и родителей учащихся для реализации новых инициатив.'
  }
]
