import { Link } from 'react-router-dom'
import React from 'react'

import imgBecomePartner1 from '../../ge/become-partner/become-partner__1.svg'
import imgBecomePartner2 from '../../ge/become-partner/become-partner__2.svg'
import imgBecomePartner3 from '../../ge/become-partner/become-partner__3.svg'
export const BP_GUIDE_BLOCK = [
  {
    img: imgBecomePartner1,
    text: <span>Выберите <Link to="/schools">инициатора</Link> или <Link to="/projects">проект</Link> - по категории или названию.</span>
  },
  {
    img: imgBecomePartner2,
    text: 'Примите участие в финансировании проекта.'
  },
  {
    img: imgBecomePartner3,
    text: 'Следите за всеми этапами реализации проекта на нашей платформе.'
  }
]

export const BP_TEST_BLOCK = [
  {
    stage: 'Публикация',
    text: 'Перед тем, как каждый проект инициатора будет опубликован и доступен для поиска на «ILoveSchool», мы внимательно его проверяем. Члены нашей команды совместно с экспертным советом смотрят, чтобы проект отвечал требованиям надежности и эффективности.'
  },
  {
    stage: 'Реализация',
    text: 'Реализацию всех проектов мы берем на себя. При этом вы можете следить за каждым этапом их выполнения.'
  },
  {
    stage: 'Завершение',
    text: 'После завершения проекта предоставляют партнерам проекта обратную связь. Они публикуют на нашем сайте отчет и фотографии о работе действующего проекта, а также отзывы учителей и учеников, которые в нем участвовали.'
  }
]
