import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'

import { CheckBoxField, TextareaField } from '../Input'
import { Button } from '../Button'

import { POST_REVIEW_VALIDATION, POST_REVIEW_FIELDS, POST_REVIEW_INITIAL } from './consants'

const ReviewPopup = ({ onClose, onSendReview, schoolId, innerRef }) => {
  const dispatch = useDispatch()
  const { school: {
    isReviewPosting
  } } = useSelector(state => state)

  const onSubmit = ({ text }) => {
    const _params = {
      text,
      school: schoolId
    }

    dispatch(onSendReview(_params, () => onClose(false)))
  }

  const link = <a className="subscribe-form__link" href="/docs/personal_data.pdf" target="_blank">обработкой данных</a>
  const personalSpan = <span>Я согласен с {link}</span>

  return (
    <div className="review-popup" ref={innerRef}>
      <div className="review-popup-content">
        <div
          className="review-popup-content__close-icon"
          onClick={() => onClose(false)}
        />
        <h1>Напишите отзыв</h1>
        <Formik
          initialValues={POST_REVIEW_INITIAL}
          enableReinitialize={true}
          validationSchema={POST_REVIEW_VALIDATION}
          onSubmit={values => onSubmit(values)}
        >
          {(f) => {
            return (
              <form onSubmit={f.handleSubmit}>
                <TextareaField
                  name={POST_REVIEW_FIELDS.TEXT}
                  label="Напишите, что вы думаете о инициаторе и его проектах"
                  value={f.values.text}
                  error={f.errors.text && f.touched.text && f.errors.text}
                  onChange={event => f.setFieldValue('text', event.target.value)}
                  onBlur={f.handleBlur}
                />

                <div className="review-popup-content__agree-terms">
                  <CheckBoxField
                    id="checkbox"
                    name={POST_REVIEW_FIELDS.CHECKBOX}
                    label={personalSpan}
                    remember
                    value={f.values.checkbox}
                    onBlur={f.handleBlur}
                    onChange={f.handleChange}
                  />
                </div>

                <Button
                  label="Отправить"
                  type="submit"
                  width={200}
                  height={56}
                  disabled={(!f.isValid || isReviewPosting)}
                  color={(!f.isValid || isReviewPosting) ? 'gray' : 'blue'}
                />
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default ReviewPopup
