import React from 'react'
import classNames from 'classnames'
import { NavHashLink } from 'react-router-hash-link'
import { withRouter } from 'react-router'

const HashNavigationComponent = ({ items, base = '', className, location }) => {
  return (
    <div className={classNames(className, 'hash-navigation__items-container')}>
      {
        items.map(({ target, title }) => (
          <NavHashLink
            to={base.concat('#').concat(target)}
            className="hash-navigation__link"
            activeClassName="hash-navigation__link--active"
            isActive={() => location.hash === `#${target}`}
            smooth
          >
            {title}
          </NavHashLink>
        ))
      }
    </div>
  )
}

export const HashNavigation = withRouter(HashNavigationComponent)
