import React, { useState, useEffect } from 'react'

import { Button } from '../Button/Button'
import crossButton from '../../ge/crossButton.svg'

const CookieBanner = () => {
  const [ isBannerShow, setIsBannerShow ] = useState(false)

  useEffect(() => {
    setIsBannerShow(true)
    if (localStorage.getItem('isBannerShow') === 'false') {
      setIsBannerShow(false)
    }
  }, [])

  const setLocalStorageValue = () => {
    setIsBannerShow(false)
    localStorage.setItem('isBannerShow', 'false')
  }

  return (
    <>{isBannerShow && (
      <div className='cookie-banner'>
        <div className='container-app'>
          <div className='cookie-banner__content'>
            <p className='cookie-banner__text'>
              Сайт использует куки-файлы, чтобы предоставить вам лучший пользовательский опыт.
            </p>
            <div className='cookie-banner__buttons'>
              <Button
                label='Принять'
                color='orange'
                nobg
                width={171}
                onClick={() => setLocalStorageValue()}
              />
              <Button
                label=''
                color='orange'
                nobg
                noborder
                width={72}
                icon={crossButton}
                onClick={() => setIsBannerShow(false)}
              />
            </div>
          </div>
        </div>
      </div>
    )}</>
  )
}

export default CookieBanner
