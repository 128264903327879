import { toast } from 'react-toastify'

import agent from '../../config/methods'
import loadReduxTypes from '../../helpers/loadReduxTypes'

import { SCHOOLS_PROJECTS_CLEAR_STATE } from './SchoolsProjects.constants'

const GET_SCHOOL_PROJECTS = loadReduxTypes('GET_SCHOOL_PROJECTS')
const GET_SCHOOL = loadReduxTypes('GET_SCHOOL')

function getSchoolProjectsAction (id, perPage) {
  return async function action (dispatch) {
    dispatch({ type: GET_SCHOOL.REQUEST })

    try {
      const response = await agent.GET(`/school/${id}`, {
        fields: [ 'title' ],
        'order': 'desc',
        'sort_by': 'created_at'
      })
      await Promise.all([ dispatch({
        type: GET_SCHOOL.SUCCESS,
        payload: {
          schoolName: response.data.data.title
        }
      }),
      dispatch(getProjectDetails(id, perPage)) ])
    } catch (error) {
      const isNotFoundError = error.response && error.response.status === 404 && 'not_found'

      dispatch({
        type: GET_SCHOOL.FAILURE,
        payload: {
          error: isNotFoundError || error
        }
      })
    }
  }
}

function getProjectDetails (id, perPage) {
  return async function action (dispatch) {
    const params = { 'q': [ `school.id__eq__${id}` ], 'per_page': perPage, 'order': 'desc' }

    try {
      const response = await agent.GET(`/school_projects/${id}`, params)
      dispatch({
        type: GET_SCHOOL_PROJECTS.SUCCESS,
        payload: {
          data: response.data.data.projects,
          total: response.data.data.total
        }
      })
    } catch (error) {
      dispatch({
        type: GET_SCHOOL_PROJECTS.FAILURE,
        payload: {
          error
        }
      })

      toast.error('Произошла ошибка при загрузке деталей проекта.')
    }
  }
}

function clearState () {
  return function action (dispatch) {
    dispatch({ type: SCHOOLS_PROJECTS_CLEAR_STATE })
  }
}

export { clearState, getSchoolProjectsAction }
