import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import CardSlider from '../../components/CardSlider'
import ProjectCard from '../../components/ProjectCard'
import { getSectionName } from '../../pages/Project/constants'
import Loader from '../../components/Loader'
import RightArrow from '../../ge/arrow-right-blue.svg'
import PaymentFormContainer from '../../components/PaymentFormContainer/PaymentFormContainer'

/**
 * Container contains projects for show cards on the Main page of project.
 * Project may have 1 of 3 stage types.
 * @param stageType
 * @param projects
 * @param total
 * @param isFetching
 * @param history
 * @returns {null|*}
 * @constructor
 */

const ProjectsContainer = ({ stageType, projects, total, isFetching }) => {
  const history = useHistory()
  const [ selectedProject, setSelectedProject ] = useState()

  const { isPaymentFormVisible } = useSelector(state => state.project)
  const [ isSuccessPaymentModal, setIsSuccessPaymentModal ] = useState()
  if (total === 0) return null

  if (isFetching) {
    return (
      <Loader />
    )
  }

  const sectionName = getSectionName(stageType)

  return (
    <React.Fragment>
      {/* Desktop */}
      {isFetching && <div className="active-projects"><Loader /></div>}
      {projects && <div className="active-projects">
        <div className="active-projects__head-container">
          <h4 className="active-projects__title">{sectionName}</h4>
          <Link
            className="active-projects__all-link"
            to={'/projects?type=all'}>
            Все проекты  <img src={RightArrow} alt="right arrow" />
          </Link>
        </div>
        <div className="active-projects__projects-container">
          {projects.map((project) => (
            <ProjectCard
              openPaymentForm={() => setSelectedProject(project)}
              key={`project_${project.id}`}
              title={project.title}
              link={`/projects/${project.id}`}
              stage={project.stage}
              collected={project.collected}
              goal={project.goal}
              logo={project.logo}
              description={project.description}
              balance={project.balance}
            />
          )
          )}
        </div>
      </div>}
      {selectedProject && <PaymentFormContainer
        isPaymentFormVisible={isPaymentFormVisible}
        isSuccessPaymentModal={isSuccessPaymentModal}
        setIsSuccessPaymentModal={setIsSuccessPaymentModal}
        selectedProject={selectedProject}
      />}

      {/* Mobile */}
      {projects && <div className="mobile__slider-active-projects">
        <div className="active-projects__head-container">
          <h4>{sectionName}</h4>
          <div className="active-projects__all-link" onClick={() => history.push('/projects?type=all')}>Все
            проекты
          </div>
        </div>
        <CardSlider
          slidesToShow={1}
          isShowLoader={isFetching}
          slidesToScroll={1}>
          {projects.map((project) => (
            <div key={`wrapper_${project.id}`} className="active-projects__slide">
              <ProjectCard
                openPaymentForm={() => setSelectedProject(project)}
                key={`project_${project.id}`}
                title={project.title}
                link={`/projects/${project.id}`}
                stage={project.stage}
                collected={project.collected}
                goal={project.goal}
                logo={project.logo}
                description={project.description}
                balance={project.balance}
              />
            </div>
          ))}
        </CardSlider>
      </div>
      }
    </React.Fragment>
  )
}

export default ProjectsContainer
