import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export class Button extends Component { // todo refactoring
  render () {
    const {
      label = 'Кнопка',
      color = 'blue',
      nobg = false,
      nocaps = false,
      noborder = false,
      medium = false,
      large = false,
      width = 'initial',
      height = null,
      className = '',
      tabIndex = '0',
      onClick,
      link,
      href,
      icon = false,
      disabled = false,
      type = 'button'
    } = this.props

    const buttonClassNames = classNames({
      [`btn btn--${color}`]: true,
      'btn--no-bg': nobg,
      'btn--no-caps': nocaps,
      'btn--no-border': noborder,
      'btn--size-medium': medium,
      'btn--size-large': large,
      'btn--icon': icon,
      [className]: !!className,
      'margin-bottom-30': true
    })

    if (link) {
      return (
        <Link
          to={link}
          tabIndex={tabIndex}
          className={buttonClassNames}
          style={{ width, height }}
        >
          {label}
        </Link>
      )
    } else if (href) {
      return (
        <a
          href={href}
          rel="noopener noreferrer"
          target="_blank"
          tabIndex={tabIndex}
          className={buttonClassNames}
          style={{ width, height }}
        >
          {label}
        </a>
      )
    } else {
      return (
        <button
          tabIndex={tabIndex}
          className={buttonClassNames}
          style={{ width, height }}
          onClick={onClick}
          disabled={disabled}
          type={type}
        >
          <div>{!icon ? label : <img src={icon} alt="Button" />}</div>
        </button>
      )
    }
  }
}

export class SliderButton extends Component { // TODO: Refactor
  render () {
    const {
      blue = false,
      white = false,
      light = false,
      lightBlue = false,
      left = false,
      right = false,
      className,
      onClick
    } = this.props

    return (
      <div
        className={`btn-slider
          ${blue ? ' btn-slider--blue' : ''}
          ${white ? ' btn-slider--white' : ''}
          ${light ? ' btn-slider--light' : ''}
          ${lightBlue ? ' btn-slider--light-blue' : ''}
          ${left ? ' btn-slider--left' : ''}
          ${right ? ' btn-slider--right' : ''}
          ${' ' + className}`}
        onClick={onClick}
      >
      </div>
    )
  }
}
