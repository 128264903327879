import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import BaseLayout from '../../layouts/BaseLayout'
import imgProjectInfoCover from '../../ge/how-to-create-project/how-to-create-project__cover.svg'
import imgProjectInfo1 from '../../ge/how-to-create-project/how-to-create-project__1.svg'
import imgProjectInfo2 from '../../ge/how-to-create-project/how-to-create-project__2.svg'
import imgProjectInfo3 from '../../ge/how-to-create-project/how-to-create-project__3.svg'
import imgProjectInfo4 from '../../ge/how-to-create-project/how-to-create-project__4.svg'
import { openRegistrationForm } from '../../layouts/BaseLayout.actions'
import CreateProjectSection from '../../components/ActionSection/ActionSection'
import MetaTags from '../../components/MetaTags'
import { META_TAGS_LIST } from '../../constants/Meta.constants'

import { PI_INTRO_TEXT } from './ProjectInfo.constants'

const ProjectInfoPage = () => {
  const dispatch = useDispatch()

  return (
    <BaseLayout>
      <MetaTags
        title={META_TAGS_LIST.PROJECT_INFO_TITLE}
        description={META_TAGS_LIST.PROJECT_INFO_DESCRIPTION}
      />
      <div className="project-info">
        <div className="row justify-content-center">
          <h1 className="project-info__header margin-bottom-75 col-auto">
            Как создать проект
          </h1>
        </div>
        <div className="row justify-content-center">
          <div className="project-info__cover col-auto">
            <img src={imgProjectInfoCover} alt="intro img" className="project-info__cover-image" />
            <span className="project-info__cover-text">
              {PI_INTRO_TEXT}
            </span>
          </div>
        </div>

        <div className="row justify-content-center margin-top-90">
          <div className="col-auto">
            <img src={imgProjectInfo1} alt={'Column 1'} />
          </div>
        </div>
        <div className="row justify-content-center margin-top-30">
          <div className="project-info__item-text col-auto">
            <span className="project-info__item-link" onClick={() => dispatch(openRegistrationForm())}>
              Зарегистрируйтесь
            </span> на нашей платформе – без этого вы не сможете перейти к созданию проекта!
          </div>
        </div>

        <div className="row justify-content-center margin-top-90">
          <div className="col-auto">
            <img src={imgProjectInfo2} alt={'Column 2'} />
          </div>
        </div>
        <div className="row justify-content-center margin-top-30">
          <span className="project-info__item-text col-auto">
            На главной странице «ILoveSchool» нажмите кнопку <Link to="/projects/new">«Создать проект»</Link>
          </span>
        </div>

        <div className="row justify-content-center margin-top-90">
          <div className="col-auto">
            <img src={imgProjectInfo3} alt={'Column 3'} />
          </div>
        </div>
        <div className="row justify-content-center margin-top-30">
          <span className="project-info__item-text col-auto">
            Вместе с экспертным советом «ILoveSchool» мы доработаем проект.
          </span>
        </div>

        <div className="row justify-content-center margin-top-90">
          <div className="col-auto">
            <img src={imgProjectInfo4} alt={'Column 4'} />
          </div>
        </div>
        <div className="row justify-content-center margin-top-30">
          <span className="project-info__item-text col-auto">
            Следите за обновлениями и реализацией проекта у нас на платформе.
          </span>
        </div>
        <div className="project-info__bottom-container">
          <div className="project-info__bottom-section">
            <CreateProjectSection action="create" />
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default ProjectInfoPage
