import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import BaseLayout from '../../layouts/BaseLayout'
import { Button } from '../../components/Button'
import SearchInput from '../../components/SearchInput'
import Loader from '../../components/Loader'
import SchoolCard from '../../components/SchoolCard/SchoolCard'
import { useDebounce } from '../../hooks/useDebounce.hook'

import { getSchoolsAction, clearState } from './Schools.actions'
import {
  SCHOOLS_SEARCH_FORM_INITIAL,
  SCHOOLS_SEARCH_FORM_NAMES,
  SCHOOLS_PER_PAGE
} from './Schools.constants'

const fetchSchools = (schoolsCount, searchQuery, dispatch) => {
  dispatch(getSchoolsAction(schoolsCount, searchQuery, schoolsCount <= SCHOOLS_PER_PAGE))
}

const SchoolsPage = () => {
  const dispatch = useDispatch()
  const { schools: { data, isFetching, total, isEmpty } } = useSelector(state => state)

  const [ schoolsCount, setSchoolsCount ] = useState(SCHOOLS_PER_PAGE)
  const [ searchQuery, setSearchQuery ] = useState('')

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [ dispatch ])

  useDebounce(fetchSchools, 600, [ schoolsCount, searchQuery, dispatch ])

  const onClearClick = () => {
    setSearchQuery('')
    setSchoolsCount(SCHOOLS_PER_PAGE)
  }

  return (
    <BaseLayout>
      <div className="container-app">
        <div className="container-fluid">
          <h1>Инициаторы проектов</h1>
          <Formik
            initialValues={SCHOOLS_SEARCH_FORM_INITIAL}
            render={(f) => (
              <form
                onSubmit={f.handleSubmit}
                className="margin-bottom-75"
              >
                <SearchInput
                  name={SCHOOLS_SEARCH_FORM_NAMES.QUERY}
                  error={f.errors.query && f.touched.query && f.errors.query}
                  value={searchQuery}
                  onChange={(e) => {
                    setSchoolsCount(SCHOOLS_PER_PAGE)
                    f.handleChange(e)
                    setSearchQuery(e.target.value)
                  }}
                  placeholder="Введите инициатора"
                  onBlur={f.handleBlur}
                  disabled={!f.isValid || isFetching}
                  isShowCancelButton={searchQuery.length !== 0}
                  onClear={onClearClick}
                />
              </form>
            )}
          />
          <div className="row school-card__schools-container">
            {((isFetching && (schoolsCount <= SCHOOLS_PER_PAGE)) || isEmpty) &&
              <div className="school-card__loader">
                <Loader />
              </div>
            }

            {data && data.map((school, id) => (
              <div className="col col-xl-3 margin-bottom-45" key={id}>
                <SchoolCard
                  school={school}
                />
              </div>
            ))}

            {(!isFetching && data.length === 0 && searchQuery.length !== 0) &&
              <div className="school-card__not-found">
                <p>Извините, но по вашему запросу мы ничего не нашли.</p>
              </div>
            }
          </div>
          {(isFetching &&
            <div className="school-card__loader">
              <Loader />
            </div>) ||
            <div className="row justify-content-center margin-top-15">
              <div className="col-xl-auto col">
                {schoolsCount < total &&
                  <Button
                    type="submit"
                    onClick={() => {
                      setSchoolsCount(schoolsCount + SCHOOLS_PER_PAGE)
                    }}
                    label={isFetching ? 'Загрузка' : 'Показать еще'}
                    large
                    nobg
                    disabled={isFetching}
                    width="100%"
                    className="btn__show-more"
                  />
                }
              </div>
            </div>
          }
        </div>
      </div>
    </BaseLayout>
  )
}

export default SchoolsPage
