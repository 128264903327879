import React from 'react'
import classNames from 'classnames'

export const PartnersLogoGrid = ({
  className,
  partners,
  isCustomStyles = false,
  modifier = null
}) => {
  /*
    isCustomStyles – is specified key for setting styles for mobile versions of pages: –About, School
  */

  const partnersClassNames = classNames({
    'row partners': true,
    'flex-wrap': isCustomStyles,
    'align-items-center': !isCustomStyles,
    [`${className}`]: className
  })

  const partnersContentClassNames = classNames('partners__link', {
    'partners__content': isCustomStyles,
    'partners__content--small': modifier === 'small',
    'col-6 col-sm-4 col-md-3 col-xl-3 px-3': !isCustomStyles
  })

  return (
    <div className={partnersClassNames}>
      {
        partners.map((partner, index) => (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={partner.link}
            className={partnersContentClassNames}
            key={index}
          >
            <img
              className="partners__partner"
              src={partner.logo}
              alt="partner"
            />
          </a>
        ))
      }
    </div>
  )
}
