import { toast } from 'react-toastify'

import loadReduxTypes from '../helpers/loadReduxTypes'
import agent from '../config/methods'

import {
  OPEN_AUTHORIZATION_FORM,
  CLOSE_AUTHORIZATION_FORM,
  OPEN_REGISTRATION_FORM,
  CLOSE_REGISTRATION_FORM,
  CLOSE_CHANGE_PASSWORD_FORM,
  OPEN_CHANGE_PASSWORD_FORM,
  OPEN_CONFIRM_PASSWORD_FORM,
  CLOSE_CONFIRM_PASSWORD_FORM,
  CLOSE_REGISTRATION_SUCCESS,
  OPEN_REGISTRATION_SUCCESS,
  RESET_ERROR_SUCCESS,
  OPEN_MOBILE_MENU,
  CLOSE_MOBILE_MENU
} from './BaseLayout.constants'

const GET_RESTORATION_PASSWORD = loadReduxTypes('GET_RESTORATION_PASSWORD')
const SAVE_NEW_PASSWORD = loadReduxTypes('SAVE_NEW_PASSWORD')

function openAuthorizationForm () {
  return function action (dispatch) {
    dispatch({ type: OPEN_AUTHORIZATION_FORM })
  }
}

function closeAuthorizationForm () {
  return function action (dispatch) {
    dispatch({ type: CLOSE_AUTHORIZATION_FORM })
  }
}

function openRegistrationForm () {
  return function action (dispatch) {
    dispatch({ type: OPEN_REGISTRATION_FORM })
  }
}

function closeRegistrationForm () {
  return function action (dispatch) {
    dispatch({ type: CLOSE_REGISTRATION_FORM })
  }
}

function openChangePasswordForm () {
  return function action (dispatch) {
    dispatch({ type: OPEN_CHANGE_PASSWORD_FORM })
  }
}

function closeChangePasswordForm () {
  return function action (dispatch) {
    dispatch({ type: CLOSE_CHANGE_PASSWORD_FORM })
  }
}

function openConfirmPasswordForm () {
  return function action (dispatch) {
    dispatch({ type: OPEN_CONFIRM_PASSWORD_FORM })
  }
}

function closeConfirmPasswordForm () {
  return function action (dispatch) {
    dispatch({ type: CLOSE_CONFIRM_PASSWORD_FORM })
  }
}

function openRegistrationSuccess () {
  return function action (dispatch) {
    dispatch({ type: OPEN_REGISTRATION_SUCCESS })
  }
}

function closeRegistrationSuccess () {
  return function action (dispatch) {
    dispatch({ type: CLOSE_REGISTRATION_SUCCESS })
  }
}

function mobileMenuHandler (openAction) {
  return function action (dispatch) {
    openAction ? dispatch({ type: OPEN_MOBILE_MENU }) : dispatch({ type: CLOSE_MOBILE_MENU })
  }
}

function getRestorationPassword (params) {
  return function action (dispatch) {
    dispatch({ type: GET_RESTORATION_PASSWORD.REQUEST })
    agent.POST('/forgot_password', params)
      .then(() => {
        dispatch({
          type: GET_RESTORATION_PASSWORD.SUCCESS,
          payload: params.email
        })
      })

      .catch((error) => {
        dispatch({
          type: GET_RESTORATION_PASSWORD.FAILURE,
          payload: {
            error
          }
        })

        if (error.response && error.response.data.error === 'User does not exists') {
          toast.error('Пользователь с указанным адресом не найден')
        } else {
          toast.error('Возникла ошибка. Повторите попытку позже')
        }
      })
  }
}

function resetError () {
  return function action (dispatch) {
    dispatch({ type: RESET_ERROR_SUCCESS })
  }
}

function saveNewPassword (params) {
  return function action (dispatch) {
    dispatch({ type: SAVE_NEW_PASSWORD.REQUEST })
    agent.POST('/reset_password', params)
      .then(() => {
        dispatch({ type: SAVE_NEW_PASSWORD.SUCCESS })
        dispatch({ type: CLOSE_CONFIRM_PASSWORD_FORM })
        dispatch({ type: OPEN_AUTHORIZATION_FORM })

        toast.success('Пароль успешно изменен. Пожалуйста, авторизуйтесь.')
      })

      .catch((error) => {
        dispatch({
          type: SAVE_NEW_PASSWORD.FAILURE,
          payload: {
            error
          }
        })

        if (error.response && error.response.data.error === 'Invalid token') {
          toast.error('Ссылка для сброса пароля не корректна')
        } else {
          toast.error(error.response.data.error[0])
        }
      })
  }
}

export {
  openAuthorizationForm,
  closeAuthorizationForm,
  openRegistrationForm,
  closeRegistrationForm,
  openChangePasswordForm,
  closeChangePasswordForm,
  openConfirmPasswordForm,
  closeConfirmPasswordForm,
  closeRegistrationSuccess,
  openRegistrationSuccess,
  getRestorationPassword,
  resetError,
  saveNewPassword,
  mobileMenuHandler
}
