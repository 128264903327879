import React from 'react'

const TeamCard = ({ member }) => {
  return (
    <div className="team-slider__slide team-card">
      <img
        alt={`Team member ${member.name}`}
        className="team-card__photo"
        src={member.img}
      />
      <p className="team-card__name">{member.name}</p>
      <p className="team-card__position">{member.position}</p>
      <p className="team-card__text">{member.text}</p>
    </div>
  )
}

export default TeamCard
