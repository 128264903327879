import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import agent from '../../config/methods'
import BaseLayout from '../../layouts/BaseLayout'
import MetaTags from '../../components/MetaTags'
import { META_TAGS_LIST } from '../../constants/Meta.constants'
import Loader from '../../components/Loader'
import chatIcon from '../../ge/chat_icon.svg'

const QuestionsPage = () => {
  const [ isLoading, setLoading ] = useState(false)
  const [ questions, setQuestions ] = useState([])

  useEffect(() => {
    const getQuestions = async () => {
      try {
        setLoading(true)
        const response = await agent.GET('/faq/sections')
        const data = await response.data
        setQuestions(data)
      } catch (error) {
        console.log(error.message)
      } finally {
        setLoading(false)
      }
    }

    getQuestions()

    return () => {
      setQuestions([])
    }
  }, [])

  return (
    <BaseLayout>
      <MetaTags
        title={META_TAGS_LIST.FAQ_TITLE}
        description={META_TAGS_LIST.FAG_DESCRIPTION}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-app">
          <div className="container-fluid">
            <div className="questions">
              <h1>Частые вопросы</h1>
              <p className="questions__text">
                В этом разделе Вы можете прочитать ответы на наиболее популярные вопросы, а также увидеть ответ на свой вопрос.
              </p>
              <p className="questions__text">
                Вы можете задать нам вопрос по электронной почте <a href="mailto:info@iloveschool.help">info@iloveschool.help</a> или через форму в правом нижнем углу<img className="questions__chatIcon" src={chatIcon} alt="Чат" />
              </p>
              <main className="questions__container">
                {questions.length > 0 && questions.map((question) => (
                  <section key={`question-${question.id}`} className="questions__column">
                    <h3>{question.title}</h3>
                    {question.articles.length > 0 && (
                      <ul className="questions__list">
                        {question.articles.map((article) => (
                          <li className="questions__list-item" key={`article-${article.id}`}>
                            <Link to={`/faq/${question.id}#paragraph${article.id}`}>{article.title}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </section>
                ))}
              </main>
            </div>
          </div>
        </div>
      )}

    </BaseLayout>
  )
}

export default QuestionsPage
