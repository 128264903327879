import { toast } from 'react-toastify'

import loadReduxTypes from '../../helpers/loadReduxTypes'
import agent from '../../config/methods'
import { getUserAction } from '../../layouts/authorization/Authorization.actions'

import {
  PROFILE_SAVE_FAILURE,
  PROFILE_SAVE_REQUEST,
  PROFILE_SAVE_SUCCESS
} from './Profile.constants'

const UPLOAD_AVATAR = loadReduxTypes('UPLOAD_AVATAR')
const REMOVE_AVATAR = loadReduxTypes('REMOVE_AVATAR')

function saveProfileAction (data) {
  return function action (dispatch) {
    dispatch({ type: PROFILE_SAVE_REQUEST })

    const user = data.user
    const id = user.id
    const changeMail = user.email !== data.email

    if (data.email === user.email) delete data.email
    if (data.first_name === user.first_name) delete data.first_name
    if (data.last_name === user.last_name) delete data.last_name
    if (data.middle_name === user.middle_name) delete data.middle_name
    if (data.company === user.company) delete data.company
    if (data.position === user.position) delete data.position
    if (data.avatar === user.avatar) delete data.avatar

    delete data.user

    agent.PUT(`/user/${id}`, { ...data })
      .then(response => {
        dispatch({
          type: PROFILE_SAVE_SUCCESS,
          payload: {
            registeredUser: response.data
          }
        })

        dispatch(getUserAction())

        toast.success('Данные успешно сохранены')

        if (changeMail) {
          toast.success('Для подтверждения email вам было отправлено письмо на электронную почту')
        }
      })

      .catch((error) => {
        dispatch({
          type: PROFILE_SAVE_FAILURE,
          payload: {
            error
          }
        })

        if (error.response && error.response.data.error_code === 'email_in_use') {
          const email = error.response.data.error.split(' ')[1]
          toast.error(`Почта ${email} уже используется!`)
        } else {
          toast.error('Возникла ошибка. Повторите попытку позже.')
        }
      })
  }
}

function uploadAvatarAction (params) {
  return function action (dispatch) {
    dispatch({ type: UPLOAD_AVATAR.REQUEST })
    return agent.POST('/upload', { file: params })
      .then(response => {
        dispatch({
          type: UPLOAD_AVATAR.SUCCESS,
          payload: {
            avatar: response.data.data
          }
        })
      })

      .catch(error => {
        dispatch({
          type: UPLOAD_AVATAR.FAILURE,
          payload: error
        })
      })
  }
}

function removeAvatarAction () {
  return function action (dispatch) {
    dispatch({ type: REMOVE_AVATAR.REQUEST })
  }
}

export {
  saveProfileAction,
  uploadAvatarAction,
  removeAvatarAction
}
