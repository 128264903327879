
export const REGISTRATION_FORM_INITIAL_VALUES = {
  first_name: '',
  last_name: '',
  password: '',
  confirm_password: '',
  email: '',
  subscription_checkbox: false,
  offer_checkbox: false,
  personal_checkbox: false
}

export const REGISTRATION_FORM_FIELDS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm_password',
  EMAIL: 'email',
  SUBSCRIPTION: 'subscription_checkbox',
  OFFER: 'offer_checkbox',
  PERSONAL_DATA: 'personal_checkbox'
}

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE'
