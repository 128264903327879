import { STAGE_TYPES, STAGES } from '../Project/constants'

/**
 * Determines the activity of the stage, includes the specified stage name for the progress of the bar
 * @param stageName
 * @returns {object}
 */

export const getStagesProgress = (stageName) => {
  let staged = true
  return STAGES.map(item => {
    if (item.value === stageName) { staged = false }
    return { ...item, staging: item.value === stageName || staged }
  })
}

/**
 * Detects life-cycle stage for some project: fundraising, integration or finish.
 * @param stage
 * @returns {string}
 */

export const getProjectStageType = (stage) => {
  let stageType

  if (stage === 'financing') {
    stageType = STAGE_TYPES.FUNDRAISING
  } else if (stage === 'training' || stage === 'installing' || stage === 'purchasing') {
    stageType = STAGE_TYPES.INTEGRATION
  } else if (stage === 'done') {
    stageType = STAGE_TYPES.FINISHED
  }

  return stageType
}

export const getPercentage = (value, amount) => Math.round(value * (100 / amount))

/**
 * Calculates percentages by stage or by counted cash specified for progressbar.
 * @param stageType
 * @param collected
 * @param goal
 * @param stage
 * @returns {number}
 */

export const getPercentageByStage = (stageType, collected, goal, stage) => {
  let progressPercentage

  if (stageType === STAGE_TYPES.FUNDRAISING) {
    const value = getPercentage(collected, goal)
    progressPercentage = value >= 100 ? 100 : value
  } else if (stageType === STAGE_TYPES.INTEGRATION) {
    const stagesFiltered = STAGES.filter(stage => stage.value !== 'financing' && stage.value !== 'done')
    const stageIndex = stagesFiltered.findIndex(projectStage => projectStage.value === stage)
    progressPercentage = getPercentage(stageIndex + 1, stagesFiltered.length)
  }

  return progressPercentage
}
