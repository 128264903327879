import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import noUserPicture from '../../ge/no-userpic.svg'

const SchoolCard = ({ school }) => {
  const isLogoExist = school.logo

  return (
    <div className="school-card">
      <div className="school-card__avatar">
        <img alt="Cover of school" src={isLogoExist ? school.logo : noUserPicture} />
      </div>
      <div className="school-card__name">
        <Link to={`/school/${school.id}`}>
          <h1>{school.title}</h1>
        </Link>
      </div>
      <div className="school-card__city">
        {school.city}
      </div>
      <div className="school-card__url">
        <Link to={`/school-projects/${school.id}`}>
          <span>К проектам инициатора</span>
        </Link>
      </div>
    </div>
  )
}

export default withRouter(SchoolCard)
