import { toast } from 'react-toastify'

import agent from '../../config/methods'
import loadReduxTypes from '../../helpers/loadReduxTypes'

const CHANGE_MAIL = loadReduxTypes('CHANGE_MAIL')

export default function changeMail ({ code }) {
  return function action (dispatch) {
    dispatch({
      type: CHANGE_MAIL.REQUEST
    })

    return agent.POST('/email-change', { code })
      .then(() => {
        dispatch({
          type: CHANGE_MAIL.SUCCESS
        })

        toast.success('Почта успешна подтверждена!')
      })

      .catch((error) => {
        dispatch({
          type: CHANGE_MAIL.FAILURE,
          payload: {
            error
          }
        })

        if (error.response && error.response.data.error_code === 'email_in_use') {
          toast.error('Почта уже подтверждена!')
        } else if (error.response && error.response.data.error === 'Invalid token') {
          toast.error('Ссылка для подтверждения почты не корректна')
        } else {
          toast.error('Произошла ошибка подтверждения почты')
        }
      })
  }
}
