import React from 'react'
import { useDispatch } from 'react-redux'

import { Button } from '../Button'
import { onPaymentFormAction } from '../../pages/Project/Project.actions'

const SupportProject = () => {
  const dispatch = useDispatch()

  return (
    <div className="payment-button__wrapper">
      <Button
        label={'Поддержать проект'}
        color="orange"
        onClick={() => dispatch(onPaymentFormAction(true))}
      />
    </div>
  )
}

export default SupportProject
