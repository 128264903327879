export const SEARCH_PROJECT_FORM = {
  QUERY: 'query'
}

export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL'
export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST'
export const PROJECTS_PER_PAGE = 12
export const PROJECTS_CLEAR_STATE = 'PROJECTS_CLEAR_STATE'

// Этапы следующие: финансирование, закупка, установка, обучение, проект реализован. Категории мы пока все не сможем полностью назвать, это зависит от проектов. Пока есть следующие категории: наборы для обучения, интерактивные панели, компьютерные класс, лаборатории и мастерские,  учебные пособия, мастер-классы, гаджеты

export const SORT_OPTIONS = [
  {
    label: 'От ранних к поздним',
    value: 1,
    queryParams: {
      order: 'asc',
      sort_by: 'created_at'
    }
  },
  {
    label: 'От поздних к ранним',
    value: 2,
    queryParams: {
      order: 'desc',
      sort_by: 'created_at'
    }
  }
]

export const SEARCH_PROJECT_INITIAL = {
  'query': '',
  'sort': SORT_OPTIONS[0]
}

export const getInitialValues = query => {
  return {
    query: query || SEARCH_PROJECT_INITIAL.query
  }
}
