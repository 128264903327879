import React from 'react'
import { useHistory } from 'react-router-dom'

import SuccessPicture from '../../../ge/payment/success_picture.svg'
import { Button } from '../../Button'
import SharingButtons from '../../SharingButtons'

const SuccessForm = ({ setIsSuccessPaymentModal, projectName, projectID }) => {
  const history = useHistory()
  const onCloseModal = () => setIsSuccessPaymentModal(false)

  return (
    (
      <div className="payment-form">
        <div className="payment-form__wrapper">
          <div className="payment-form__close-icon" onClick={onCloseModal} />
          <img src={SuccessPicture} alt="Success message with peoples" />
          <div className="payment-form__success-message">
            <h1>Спасибо что вы поддержали {projectName}!</h1>
            <p>Вы можете сделать еще больше — пожалуйста, расскажите друзьям в социальных сетях, что реализуете проект вместе с нами.</p>
            <SharingButtons
              title={`Я поддерживаю ${projectName}`}
              newUrl={`/projects/${projectID}`}
            />
          </div>
          <div>
            <Button
              type="submit"
              onClick={() => {
                history.push(`/projects/${projectID}`)
                onCloseModal()
              }}
              label="К проекту"
              width="221px"
              height="56px"
            />
          </div>
        </div>
      </div>
    )
  )
}

export default SuccessForm
