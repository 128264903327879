import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { Formik } from 'formik'
import classNames from 'classnames'

import { TextField } from '../components/Input'
import { Button } from '../components/Button'
import Loader from '../components/Loader'

import {
  getRestorationPassword,
  closeChangePasswordForm,
  resetError
} from './BaseLayout.actions'
import {
  CHANGE_PASSWORD_FORM_INITIAL_VALUES,
  CHANGE_PASSWORD_FORM_VALIDATION,
  CHANGE_PASSWORD_FORM_FIELDS
} from './BaseLayout.constants'

const ChangePasswordForm = () => {
  const {
    baseLayoutReducer: {
      restorationEmail,
      isFetching,
      error
    }
  } = useSelector(state => state)

  const dispatch = useDispatch()

  const [ isMailSend, setIsMailSend ] = useState(false)
  const [ isFormMount, setFormMount ] = useState(false)

  const formClassName = classNames({
    'authorization-form': true,
    'authorization-form--show': isFormMount
  })

  useEffect(() => {
    setFormMount(true)
  }, [])

  if (isMailSend && error && error.response) {
    setIsMailSend(false)
    dispatch(resetError())
  }

  const onCloseForm = () => {
    setFormMount(false)
    setTimeout(() => dispatch(closeChangePasswordForm()), 300)
  }

  const onBack = () => {
    if (isMailSend) {
      setIsMailSend(false)
    } else {
      onCloseForm()
    }
  }

  const onSubmit = value => {
    setIsMailSend(true)
    dispatch(getRestorationPassword(value))
  }

  const urlMail = restorationEmail && restorationEmail.match(/@([\w-]+\.+[\w-]+)/)[1]

  return (
    <div className={formClassName}>
      <div
        className='authorization-form__mask'
        onClick={() => onCloseForm()}
      />
      <div className='authorization-form__content'>
        <div className='authorization-form__content-wrap text-center'>
          <span
            onClick={() => onBack()}
            className='authorization-form__back'
          >
            Назад
          </span>
          <i className='icon icon--close authorization-form__close' onClick={() => onCloseForm()} />
          <h4
            className='change-password-form__title'>{restorationEmail ? 'Проверьте почту' : 'Введите почту'}</h4>
          {!restorationEmail && isFetching &&
            <div className='authorization-form__loader'>
              <Loader />
            </div>
          }
          {!restorationEmail && !isFetching &&
          <Formik
            initialValues={CHANGE_PASSWORD_FORM_INITIAL_VALUES}
            validationSchema={CHANGE_PASSWORD_FORM_VALIDATION}
            onSubmit={(values) => onSubmit(values)}
          >
            {(f) => (
              <form className='change-password-form'
                onSubmit={f.handleSubmit}
              >
                <TextField
                  required
                  medium
                  tabIndex='1'
                  name={CHANGE_PASSWORD_FORM_FIELDS.EMAIL}
                  label='Email'
                  value={f.values.email}
                  onChange={f.handleChange}
                  onBlur={f.handleBlur}
                  className="authorization-form__input"
                  error={f.errors.email && f.touched.email && f.errors.email}
                />
                <p className='change-password-form__text'>Чтобы мы могли отправить вам ссылку на восстановление пароля</p>
                <Button
                  type="submit"
                  medium
                  tabIndex='2'
                  label='Получить ссылку'
                  width='100%'
                  className='change-password-form__button'
                  color={(!f.isValid || isFetching) ? 'gray' : 'orange'}
                  disabled={!f.isValid || isFetching}
                />
              </form>
            )
            }
          </Formik>}
          {restorationEmail && !isFetching && <React.Fragment>
            <p className='change-password-form__text'>
              Инструкция по восстановлению отправлена на {restorationEmail}
            </p>
            <a
              href={`https://${urlMail}`}
              rel="noopener noreferrer"
              target="_blank"
              onClick={onCloseForm}
              className="btn btn--orange btn--size-medium change-password-form__button-mail">
              Перейти на {urlMail}
            </a>
          </React.Fragment>}
        </div>
      </div>
    </div>
  )
}

export default withRouter(ChangePasswordForm)
