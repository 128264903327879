import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
/* eslint-disable */

import Loader from '../../components/Loader'

import activateAccount from './SignupComplete.actions'
import { useHistory } from 'react-router-dom'
const SignupComplete = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    signupComplete: { isFetching }
  } = useSelector(state => state)

  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get('code')
    dispatch(activateAccount({ 'token': code }))
    history.push('/')
  }, [])

  return (
    <div>
      <div className='container-app'>
        <div className='container-fluid'>
          <div className='margin-bottom-30 signup-complete-wrapper'>
            <h1>Активация аккаунта...</h1>
            {isFetching && <Loader />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupComplete
