import loadReduxTypes from '../../helpers/loadReduxTypes'

const ACTIVATE_ACCOUNT = loadReduxTypes('ACTIVATE_ACCOUNT')

const initialState = {
  isFetching: false,
  error: null
}

export default function signupCompleteReducer (state = initialState, { type, payload }) {
  switch (type) {
  case ACTIVATE_ACCOUNT.REQUEST:
    return {
      ...state,
      isFetching: true
    }
  case ACTIVATE_ACCOUNT.SUCCESS:
    return {
      ...state,
      isFetching: false
    }
  case ACTIVATE_ACCOUNT.FAILURE:
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }

  default: return state
  }
}
