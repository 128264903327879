/**
 * Calculates scroll height of nodes.
 * @param className
 * @returns {number}
 */

export const getOriginalHeight = (className) => {
  const element = document.getElementsByClassName(className)
  return (element[0] && element[0].scrollHeight) || 0
}
