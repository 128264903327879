import { API_IMG, YOUTUBE_IMG_URL } from '../../constants/Api.constants'

/**
 * Adds new attribute link to image file
 * @param files array with files
 * @returns {files}
 */

export const formatFilesData = (files) => {
  files.forEach(fileItem => {
    return (fileItem.src = `${API_IMG}/${fileItem.filename}`)
  })
  return files
}

/**
 * Adds new attribute preview for video item and link with unique link
 * @param videos array with video
 * @returns {videos}
 */

export const formatVideoData = (videos) => {
  videos.forEach((videoItem) => {
    videoItem.preview = `${YOUTUBE_IMG_URL}${getVideoId(videoItem.link)}/0.jpg`
    return (videoItem.link = `${videoItem.link}`)
  })
  return videos
}

const getVideoId = (url) => {
  // eslint-disable-next-line no-useless-escape
  const match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/)
  return (match && match[7].length === 11) ? match[7] : false
}

/**
 * Returns new youtube link for youtube iframe
 * @param link
 * @returns {link}
 */

export const formatYoutubeLink = (link) => {
  return link && link.replace('youtube.com/watch?v=', 'youtube.com/embed/')
}

/**
 * Converts array with id values of avatars to string
 * @param avatar
 * @returns {string}
 *
 * @example
 *  > convertAvatarParamsToString(avatar)
 *  > 1,2,3
 *
 */

export const convertAvatarParamsToString = (avatar) => {
  return avatar.data.data.items.reduce((result, data) => {
    if (data.user && data.user.avatar) {
      result.push(data.user.avatar)
    }
    return result
  }, []).toString()
}

/**
 * Identifies unique items for array and return new updated data
 * @param array
 * @returns {array}
 *
 */

export const identifyUniqueItems = (array) => {
  return array.data.data.items.reduce((result, data) => {
    //! data.user || result[data.user.id]
    if (!data.email || result[data.email]) {
      return result
    }

    result[data.email] = data

    return result
  }, {})
}
