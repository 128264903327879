import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import RightArrow from '../../ge/arrow-right-orange.svg'
import { fCash } from '../../helpers/formatters'

const ProjectFields = ({ fields }) => {
  const categoryName = (fields.category && fields.category.name) || 'Категория проекта не указана'

  const projectGoalString = fCash(fields.goal) || 0
  const schoolLink = fields && fields.school && `/school/${(fields.school && fields.school.id)}`

  return (
    <div className="info-block project-details">
      <div className="project-details__block">
        <div className="project-details__name">{categoryName}</div>
        <div className="project-details__description">категория проекта</div>
      </div>
      <div className="project-details__block">
        <div className="project-details__name">{projectGoalString}</div>
        <div className="project-details__description">сумма на реализацию</div>
      </div>
      {schoolLink && <div className="project-details__block">
        <Link
          className="project-details__link"
          to={schoolLink}>На страницу инициатора проекта<img src={RightArrow} alt="right arrow" />
        </Link>
      </div>}
    </div>
  )
}

export default ProjectFields

ProjectFields.propTypes = {
  fields: PropTypes.object.isRequired
}
