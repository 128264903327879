export const META_TAGS_LIST = {
  MAIN_PAGE_TITLE: 'I Love School - Реализация образовательных проектов',
  MAIN_PAGE_DESCRIPTION: 'Делаем образование детей более разнообразным и  успешным. Здесь учителя и ученики могут заявить о своих проектах, а партнеры - выбрать проекты и следить за ходом их выполнения.',
  SEARCH_TITLE: 'Проекты образовательных учреждений | I Love School',
  SEARCH_DESCRIPTION: 'Наши проекты направлены на улучшение образовательного процесса наших детей по всей России. Здесь вы можете сделать вклад в развитие образовательных проектов как вклад в собственное будущее, в общество, которое будет окружать человека через 5-10 лет.',
  PROJECT_INFO_TITLE: 'Создание образовательного проекта | I Love School',
  PROJECT_INFO_DESCRIPTION: 'Платформа I love school является удобной формой для создателей образовательных проектов. Здесь можно заявить проекты и получить площадку, где можно собрать ресурсы из разных источников, с использованием которых и будет затем реализован заявленный проект.',
  PARTNER_INFO_TITLE: 'Поддержка образовательных проектов | I Love School',
  PARTNER_INFO_DESCRIPTION: 'В сотрудничестве с вами мы реализуем идеи, которые дают новые возможности для развития образования.  Поддержать любой проект и следить за его ходом можно у нас на сайте.',
  FAQ_TITLE: 'Частые вопросы | I Love School',
  FAG_DESCRIPTION: 'Здесь вы найдете ответы на самые частые вопросы по реализации образовательных проектов с помощью нашей платформы I love school',
  ABOUT_TITLE: 'О платформе I Love School',
  ABOUT_DESCRIPTION: 'Наша платформа работает для того, чтобы детям в школе было интереснее. Главная цель наших проектов – повысить интерес ребенка к обучению и раскрыть их интеллектуальный и творческий потенциал.',
  PARTNER_TITLE: 'Партнеры образовательных проектов I Love School',
  PARTNER_DESCRIPTION: 'Партнеры и участники проектов I love school , которые уже внесли свой вклад в развитие образования детей. Благодаря им, мы приобретаем только необходимое оборудование для успешного обучения и развития школьников.',
  EXPERTS_TITLE: 'Экспертный совет I Love School',
  EXPERTS_DESCRIPTION: 'Команда экспертов образовательной платформы I Love School',
  PLATFORM_TITLE: 'АНО «Платформа образовательных проектов» I Love School',
  PLATFORM_DESCRIPTION: 'Наша платформа работает для того, чтобы детям в школе было интереснее. Главная цель наших проектов – повысить интерес ребенка к обучению и раскрыть их интеллектуальный и творческий потенциал.'
}
