import loadTypes from '../../helpers/loadReduxTypes'

import {
  GET_NEWS_ARTICLE_SUCCESS,
  GET_NEWS_ARTICLE_REQUEST,
  GET_NEWS_ARTICLE_FAIL,
  CLEAR_NEWS_STORAGE
} from './NewsArticle.constants'

const NEXT_ARTICLE = loadTypes('NEXT_ARTICLE')
const PREVIOUS_ARTICLE = loadTypes('PREVIOUS_ARTICLE')

const initialState = {
  newsArticle: null,
  isFetching: false,
  isNextArticleFetching: false,
  isPrevArticleFetching: false,
  nextArticle: null,
  previousArticle: null,
  articlesError: null,
  nextA: null
}

export default function newsArticleReducer (state = initialState, { type, payload }) {
  switch (type) {
  case GET_NEWS_ARTICLE_REQUEST:
    return {
      ...state,
      isFetching: true
    }
  case GET_NEWS_ARTICLE_SUCCESS:
    return {
      ...state,
      newsArticle: payload.newsArticle,
      isFetching: false,
      nextArticle: payload.nextArticle,
      previousArticle: payload.previousArticle
    }
  case GET_NEWS_ARTICLE_FAIL:
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }

  case CLEAR_NEWS_STORAGE:
    return {
      ...state,
      newsArticle: null
    }

  case NEXT_ARTICLE.REQUEST: {
    return {
      ...state,
      isNextArticleFetching: true,
      isFetching: true
    }
  }

  case NEXT_ARTICLE.SUCCESS: {
    return {
      ...state,
      nextArticle: payload.nextArticle,
      isNextArticleFetching: false,
      isFetching: false
    }
  }

  case NEXT_ARTICLE.FAILURE: {
    return {
      ...state,
      isNextArticleFetching: false,
      articlesError: payload.articlesError,
      isFetching: false
    }
  }

  case PREVIOUS_ARTICLE.REQUEST: {
    return {
      ...state,
      isPrevArticleFetching: true,
      isFetching: true
    }
  }

  case PREVIOUS_ARTICLE.SUCCESS: {
    return {
      ...state,
      prevArticle: payload.prevArticle,
      isPrevArticleFetching: false,
      isFetching: false
    }
  }

  case PREVIOUS_ARTICLE.FAILURE: {
    return {
      ...state,
      articlesError: payload.articlesError,
      isPrevArticleFetching: false,
      isFetching: false
    }
  }

  default: return state
  }
}
