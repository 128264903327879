import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import 'moment/locale/ru'
import 'moment-timezone'
import { API_IMG } from '../../constants/Api.constants'
import { formatPicturePath } from '../../helpers/formatters'

const Review = ({ text, name, user, date }) => {
  const position = user.position || ''
  const picture = (user.avatar && `${API_IMG}/${user.avatar.filename}`) || formatPicturePath(user.avatar_type)
  const _date = moment.tz(date, 'GMT').locale('ru').fromNow()

  return (
    <div className="school-details-review__container">
      <div className="school-details-review__header">
        <img
          alt={'User profile portrait'}
          className="school-details-review__picture"
          src={picture}
        />
        <div className="school-details-review__middle-section">
          <h6>{name}</h6>
          <div className="school-details-review__position">{position}</div>
          <div className="school-details-review__time">{_date}</div>
        </div>
      </div>
      <div className="school-details-review__body">
        <p>{text}</p>
      </div>
    </div>
  )
}

Review.propTypes = {
  text: PropTypes.string
}

export default Review
