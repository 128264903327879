
import {
  GET_ACTIVE_PROJECTS_SUCCESS,
  GET_ACTIVE_PROJECTS_REQUEST,
  GET_ACTIVE_PROJECTS_FAIL,
  GET_COMPLETED_PROJECTS_FAIL,
  GET_COMPLETED_PROJECTS_REQUEST,
  GET_COMPLETED_PROJECTS_SUCCESS,
  SUBSCRIBE_FORM_SUCCESS,
  SUBSCRIBE_FORM_REQUEST,
  SUBSCRIBE_FORM_FAIL, REALISATION_PROJECTS
} from './Index.constants'

const initialState = {
  projects: null,
  completedProjects: null,
  realisationProjects: null,
  isFetching: false
}

export default function indexProjectReducer (state = initialState, { type, payload }) {
  switch (type) {
  case GET_ACTIVE_PROJECTS_REQUEST:
    return {
      ...state,
      isFetching: true
    }
  case GET_ACTIVE_PROJECTS_SUCCESS:
    return {
      ...state,
      projects: payload.projects,
      isFetching: false
    }
  case GET_ACTIVE_PROJECTS_FAIL:
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }

  case GET_COMPLETED_PROJECTS_REQUEST:
    return {
      ...state,
      isFetching: true
    }
  case GET_COMPLETED_PROJECTS_SUCCESS:
    return {
      ...state,
      completedProjects: payload.completedProjects,
      isFetching: false
    }
  case GET_COMPLETED_PROJECTS_FAIL:
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }

  case SUBSCRIBE_FORM_REQUEST:
    return {
      ...state,
      isFetching: true
    }

  case REALISATION_PROJECTS.SUCCESS:
    return {
      ...state,
      realisationProjects: payload.realisationProjects,
      isFetching: false
    }
  case REALISATION_PROJECTS.FAILURE:
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }

  case REALISATION_PROJECTS.REQUEST:
    return {
      ...state,
      isFetching: true
    }

  case SUBSCRIBE_FORM_SUCCESS:
    return {
      ...state,
      isFetching: false
    }
  case SUBSCRIBE_FORM_FAIL:
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }

  default: return state
  }
}
