import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Loader from '../../components/Loader'
import { getUserAction } from '../../layouts/authorization/Authorization.actions'

import changeMail from './ChangeMail.actions'

const ChangeMailPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    signupComplete: { isFetching }
  } = useSelector(state => state)

  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get('code')
    dispatch(changeMail({ 'code': code }))
    history.push('/')
    dispatch(getUserAction())
  }, [ dispatch, history ])

  return (
    <div>
      <div className="container-app">
        <div className="container-fluid">
          <div className="margin-bottom-30 change-mail-wrapper">
            <h1>Подтверждение смены почты...</h1>
            {!isFetching && <Loader />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangeMailPage
