import agent from '../../config/methods'

import {
  GET_USER_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  LOGOUT_SUCCESS,
  USER_AUTH_FAIL,
  USER_AUTH_REQUEST,
  USER_AUTH_SUCCESS
} from './Authorization.constants'

const initialState = {
  isAuthorized: agent.isTokenExist(),
  isFetching: false,
  isAuthFetching: false,
  user: null,
  error: null
}

const auth = (state = initialState, { type, payload }) => {
  switch (type) {
  /* User's Authorization */

  case USER_AUTH_REQUEST:
    return {
      ...state,
      isFetching: true
    }

  case USER_AUTH_SUCCESS:
    return {
      ...state,
      isFetching: false,
      user: payload.data,
      isAuthorized: true
    }

  case USER_AUTH_FAIL:
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }

    /* User's Logout action */

  case LOGOUT_SUCCESS:
    return {
      ...state,
      isAuthorized: false,
      user: null
    }

    /* Getting information about current user, if token correct */

  case GET_USER_REQUEST:
    return {
      ...state,
      isAuthFetching: true
    }

  case GET_USER_SUCCESS:
    return {
      ...state,
      user: payload.data,
      isAuthorized: true,
      isAuthFetching: false
    }

  case GET_USER_FAIL:
    return {
      ...state,
      isAuthorized: false,
      isAuthFetching: false,
      error: payload.error
    }

  default:
    return state
  }
}

export default auth
