import { toast } from 'react-toastify'

import { BASE_URL } from '../../constants/Api.constants'
import agent from '../../config/methods'
import axios from '../../config/axios'

export const sendDonate = (projectID, data, isOpenSecure, openPaymentCheck, setErrors, successCardPayment, sessionApplePay) => {
  agent.POST(`/project/${projectID}/donate`, data)
    .then(res => {
      const {
        is_need_3d_secure_authentication: isNeed3D,
        event_type: type,
        data: secureData
      } = res.data

      if (isNeed3D) {
        isOpenSecure(secureData['3dsecure_params'])
      } else {
        if (type === 'pay') {
          successCardPayment()
          sessionApplePay.completePayment(window.ApplePaySession.STATUS_SUCCESS)
        }
        if (type === 'fail') {
          openPaymentCheck(res.data)
          sessionApplePay.completePayment(window.ApplePaySession.STATUS_FAILURE)
        }
      }
    })
    .catch(({ response }) => setErrors(response))
}

export const sendAuthCode = (code, transactionId, setBodyRequest, setErrors) => {
  agent.PUT(`/cloudpayments/3dsecure/${transactionId}`, { code })
    .then(res => {
      setBodyRequest(res.data)
    })
    .catch(({ response }) => setErrors(response.data))
}

export const getLetter = (data, setIsLoading, onClose) => {
  axios.post(`${BASE_URL}/api/v2/letter/`, data)
    .then(() => toast.success('Письмо о благотворительности отправлено на указанную почту'))
    .catch(() => toast.error('Что-то пошло не так, повторите попытку позже'))
    .finally(() => {
      setIsLoading(false)
      onClose()
    })
}

export const startAppleSession = (data) => {
  return agent.POST('/cloudpayments/start_apple_session', data)
}

export const getServiceAmount = (amount, serviceFee) => Math.round((amount * (serviceFee / 100 + 1)) - amount)
