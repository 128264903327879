import { API_ORIGIN, API_KEY_DADATA, BASE_URL_DADATA, SUGGESTIONS_NUMBER, BASE_URL } from '../constants/Api.constants'
import { isServer } from '../constants/Server.constants'

import axios from './axios'

const qs = require('qs')
const timeout = 60000

export default {
  // TODO: Authorization methods.
  token: null,
  POST (path, data) {
    if (path === '/upload') {
      const formData = new FormData()
      formData.append('file', data.file[0])
      axios.defaults.headers['Authorization'] = `Token ${this.token}`
      axios.defaults.headers['Content-Type'] = 'multipart/form-data'
      return axios.post(`${API_ORIGIN}${path}/`, formData, { timeout })
    } else if (path === '/project-brief') {
      axios.defaults.headers['Authorization'] = `Token ${this.token}`
      axios.defaults.headers['Content-Type'] = 'application/json'
      return axios.post(`${BASE_URL}/api/v2${path}/`, data, { timeout })
    } else {
      axios.defaults.headers['Content-Type'] = 'application/json'
      return axios.post(`${API_ORIGIN}${path}/`, data, { timeout })
    }
  },

  PUT (path, data) {
    axios.defaults.headers['Content-Type'] = 'application/json'
    return axios.put(`${API_ORIGIN}${path}/`, data, { timeout })
  },

  GET (path, params) {
    axios.defaults.headers['Content-Type'] = 'application/json'
    return axios.get(`${API_ORIGIN}${path}/`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      timeout
    })
  },

  setToken (token) {
    localStorage.setItem('Authorization', token)
    axios.defaults.headers['Authorization'] = `Token ${token}`
    this.token = token
  },

  isTokenExist () {
    // проверить
    if (isServer) {
      return true
    }
    return !!localStorage.getItem('Authorization')
  },

  loadToken () {
    if (!isServer) {
      this.token = localStorage.getItem('Authorization')
    }

    if (this.token) {
      axios.defaults.headers['Authorization'] = `Token ${this.token}`
    }
  },

  removeToken () {
    this.token = null
    localStorage.removeItem('Authorization')
    delete axios.defaults.headers['Authorization']
  },

  dadataPOST (type, query, parts) {
    axios.defaults.headers['Content-Type'] = 'application/json'
    axios.defaults.headers['Accept'] = 'application/json'
    axios.defaults.headers['Authorization'] = `Token ${API_KEY_DADATA}`
    const body = { query, parts, count: SUGGESTIONS_NUMBER }
    return axios.post(`${BASE_URL_DADATA}/${type}`, JSON.stringify(body))
  }
}
