import React, { Component } from 'react'
import Slick from 'react-slick'

import { SliderButton } from '../../components/Button'

export class Slider extends Component {
  constructor (props) {
    super(props)

    this.slider = {}
  }

  sliderPrev (sliderId) {
    this.slider[sliderId].slickPrev()
  }

  sliderNext (sliderId) {
    this.slider[sliderId].slickNext()
  }

  render () {
    const {
      sliderId = 'slider',
      header = null,
      dots = false,
      infinite = true,
      speed = 300,
      slidesToShow = 4,
      slidesToScroll = 1,
      variableWidth = false,
      buttonsBlue = false,
      buttonsLightBlue = false,
      buttonsWhite = false,
      buttonsLight = false,
      className,
      classNameButton = '',
      children = null,
      count = null,
      responsive = []
    } = this.props

    let _slidesToShow = null

    if (count && count >= 1 && count <= 3) {
      _slidesToShow = count
    }

    return (
      <React.Fragment>
        <div className="row justify-content-between align-items-center">
          <div className="col-auto">
            <div className="row align-items-center">
              <div className="col-auto">
                { header }
              </div>
            </div>
          </div>
          <div className={'col-auto ' + classNameButton}>
            <SliderButton
              blue={buttonsBlue}
              white={buttonsWhite}
              light={buttonsLight}
              lightBlue={buttonsLightBlue}
              left
              className="margin-right-15"
              onClick={() => this.sliderPrev(sliderId)}
            />
            <SliderButton
              blue={buttonsBlue}
              white={buttonsWhite}
              light={buttonsLight}
              lightBlue={buttonsLightBlue}
              right
              onClick={() => this.sliderNext(sliderId)}
            />
          </div>
        </div>
        <Slick
          dots={dots}
          arrows={false}
          infinite={infinite}
          speed={speed}
          slidesToShow={_slidesToShow || slidesToShow}
          slidesToScroll={slidesToScroll}
          variableWidth={variableWidth}
          className={className}
          responsive={responsive}
          ref={slider => (this.slider[sliderId] = slider)}
        >
          { children }
        </Slick>
      </React.Fragment>
    )
  }
}
