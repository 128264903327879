export const theme = {
  container: {
    position: 'relative'
  },
  suggestionsList: {
    position: 'absolute',
    listStyleType: 'none',
    top: '100px',
    right: 0,
    left: 0,
    margin: 0,
    padding: 0,
    border: '1px solid #aaa',
    borderTop: 'none',
    backgroundColor: 'white',
    zIndex: '10',
    textAlign: 'left'
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px'
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd'
  }
}
