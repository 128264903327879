import React, { useState } from 'react'
import { Formik } from 'formik'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { CheckBoxField, TextField } from '../../Input/Input'
import { Button } from '../../Button/Button'
import DonateSupport from '../../DonateSupport/DonateSupport'
import { getLetter } from '../Payment.service'
import Loader from '../../Loader'
import {
  COMPANY_PAYMENT_FORM_SCHEME,
  getPaymentInitialValues
} from '../constants'
import { formatMoney } from '../../../helpers/formatters'
import { setSubscription } from '../../../pages/Index/Index.actions'

const PaymentCompanyForm = ({
  amount, saleValues, projectID, onClose, successCardPayment
}) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ activeSaleIndex, setActiveSaleIndex ] = useState(0)

  const dispatch = useDispatch()

  const formClasses = classNames({
    'payment-form_loading': isLoading
  })

  const onSubmit = (values) => {
    setIsLoading(true)

    if (values.email && values['company-subscribe']) {
      dispatch(setSubscription(values.email))
    }

    successCardPayment()
    getLetter(
      {
        ...values,
        project_donation_amount: values.amount,
        project: projectID,
        platform_donation_amount: Math.round(values.amount * (saleValues[activeSaleIndex] / 100))
      },
      setIsLoading,
      () => dispatch(onClose(false))
    )
  }

  return (
    <>
      {isLoading && (
        <div className="payment__loader">
          <Loader />
        </div>
      )}
      <div className={formClasses}>
        <Formik
          initialValues={{
            ...getPaymentInitialValues(amount)
          }}
          validationSchema={COMPANY_PAYMENT_FORM_SCHEME}
          onSubmit={onSubmit}
        >
          {({ values, touched, handleBlur, errors, setFieldValue, handleSubmit, isValid }) => (
            <form id="payment-form" onSubmit={handleSubmit}>
              <div className="payment-form__payment-sum" >
                <div className="payment-form__amount-container">
                  <TextField
                    name="amount"
                    width={145}
                    className="payment-form__amount"
                    height={54}
                    align="left"
                    value={values.amount}
                    error={touched.amount && errors.amount}
                    onBlur={handleBlur}
                    onChange={event => setFieldValue('amount', event.target.value)}
                    isShowCurrency
                    currency="₽"
                    type="number"
                    noErrorText
                  />
                </div>

                <div className="payment-form__validation-messages">
                  <div>{touched.amount && errors.amount}</div>
                </div>

                <div className="payment-form__payment-details">
                  <span>Минимальный платёж - 100 ₽</span>
                </div>

                <div className="company-payment-form">
                  <div className="company-payment-form__item">
                    <TextField
                      className="payment-form__email"
                      name="email"
                      label="E-mail"
                      error={touched.email && errors.email}
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={event => setFieldValue('email', event.target.value)}
                    />
                  </div>
                  <div className="company-payment-form__item">
                    <TextField
                      className="payment-form__name"
                      name="company"
                      label="Наименование организации"
                      error={touched.company && errors.company}
                      onBlur={handleBlur}
                      value={values.company}
                      onChange={event => setFieldValue('company', event.target.value)}
                    />
                  </div>
                </div>
                <div className="payment-form__checkboxes">
                  <h4 className="payment-form__checkboxes-title">Я ознакомился и согласен</h4>
                  <div className="payment-form__checkboxes-wrapper">
                    <CheckBoxField
                      id="payment-form__checkbox-subscription"
                      label={<span>c подпиской на рассылки и новости</span>}
                      onChange={event => setFieldValue('company-subscribe', event.currentTarget.checked)}
                      remember
                      name={'company-subscribe'}
                      className="payment-form__checkboxes-item"
                    />
                  </div>
                </div>
              </div>
              <DonateSupport
                saleValues={saleValues}
                setActiveSaleIndex={setActiveSaleIndex}
                activeSaleIndex={activeSaleIndex}
              />

              <div className="payment-form__footer">
                <div className="payment-form__footer-content">
                  <div className="payment-form__footer-summary">
                    <h2 className="payment-form__footer-header">Итого</h2>
                    <div className="payment-form__prices-wrapper">
                      <div className="payment-form__price">{formatMoney(values.amount * (saleValues[activeSaleIndex] / 100 + 1))}</div>
                    </div>
                  </div>
                  <div className="payment-form__get-letter">
                    <span>Получить письмо о благотворительной помощи</span>
                  </div>
                  <Button
                    type="submit"
                    label="Получить"
                    className="company-payment__submit-button"
                    height="56px"
                    disabled={(!isValid)}
                    color={(!isValid) ? 'gray' : 'blue'}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default PaymentCompanyForm
