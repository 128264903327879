import * as Yup from 'yup'

import { FORM_FIELD_ERROR } from '../../../constants/Errors.constans'

export const subscribeFormValidation = () => {
  return (
    Yup.object().shape({
      terms: Yup
        .boolean()
        .oneOf([ true ], 'Вы должны подтвердить согласие с обработкой персональных данных.'),
      email: Yup
        .string()
        .email()
        .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
    })
  )
}
