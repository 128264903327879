import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Switch from '../Switch/Switch'

import PaymentBankCardForm from './PaymentBankCardForm/PaymentBankCardForm'
import PaymentCompanyForm from './PaymentCompanyForm/PaymentCompanyForm'
import PaymentCheck from './PaymentCheck'
import { saleValues } from './constants'

const PaymentForm = ({
  title, onClose, projectID, amount, successCardPayment
}) => {
  const [ paymentCheck, setPaymentCheck ] = useState(null)
  const [ isCompanyPayment, setIsCompanyPayment ] = useState(false)
  const dispatch = useDispatch()

  return (
    <div className="payment-form">
      <div className="payment-form__close-icon" onClick={() => {
        dispatch(onClose(false))
      }} />
      {paymentCheck ? (
        <PaymentCheck info={paymentCheck} onClose={() => dispatch(onClose(false))} />
      ) : (
        <>
          <div className="payment-form__project-name">
            <h1>{title}</h1>
          </div>

          <Switch
            leftText="Через интернет"
            rightText="По реквизитам"
            changeHandler={setIsCompanyPayment}
            wrapperClass="main-switch"
          />
        </>
      )}

      {!(paymentCheck || isCompanyPayment) && (
        <PaymentBankCardForm
          amount={amount}
          saleValues={saleValues}
          projectID={projectID}
          setPaymentCheck={setPaymentCheck}
          successCardPayment={successCardPayment}
        />
      )}
      {isCompanyPayment && !paymentCheck && (
        <PaymentCompanyForm
          amount={amount}
          saleValues={saleValues}
          projectID={projectID}
          onClose={onClose}
          successCardPayment={successCardPayment}
        />
      )}
    </div>
  )
}

export default PaymentForm
