import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { TextField } from '../components/Input'
import { Button } from '../components/Button'
import Loader from '../components/Loader'

import {
  closeConfirmPasswordForm,
  saveNewPassword
} from './BaseLayout.actions'
import {
  CONFIRM_PASSWORD_FORM_VALIDATION,
  CONFIRM_PASSWORD_FORM_INITIAL_VALUES,
  CONFIRM_PASSWORD_FORM_FIELDS
} from './BaseLayout.constants'

const ConfirmPasswordForm = () => {
  const history = useHistory()
  const {
    baseLayoutReducer: {
      isFetching
    }
  } = useSelector(state => state)

  const dispatch = useDispatch()

  const [ isFormMount, setFormMount ] = useState(false)

  const formClassName = classNames({
    'authorization-form': true,
    'authorization-form--show': isFormMount
  })

  useEffect(() => {
    setFormMount(true)
  }, [])

  const onCloseForm = () => {
    setFormMount(false)
    setTimeout(() => {
      dispatch(closeConfirmPasswordForm())
      history.push('/')
    }, 300)
  }

  const onSubmit = value => {
    const token = new URLSearchParams(history.location.search).get('code')
    if (token) {
      dispatch(saveNewPassword({ ...value, token }))
      history.push('/')
    }
  }

  return (
    <div className={formClassName}>
      <div
        className="authorization-form__mask"
        onClick={() => onCloseForm()}
      />
      <div className="authorization-form__content">
        <div className="authorization-form__content-wrap text-center">
          <i
            className="icon icon--close authorization-form__close"
            onClick={() => onCloseForm()}
          />
          <h5 className="confirm-password-form__title">
            Придумайте новый пароль
          </h5>
          {isFetching && <div className="authorization-form__loader">
            <Loader />
          </div>}

          {!isFetching && <Formik
            initialValues={CONFIRM_PASSWORD_FORM_INITIAL_VALUES}
            validationSchema={CONFIRM_PASSWORD_FORM_VALIDATION}
            onSubmit={(values) => onSubmit(values)}
          >
            {(f) => (
              <form
                className="confirm-password-form"
                onSubmit={f.handleSubmit}
              >
                <TextField
                  required
                  medium
                  tabIndex="1"
                  name={CONFIRM_PASSWORD_FORM_FIELDS.PASSWORD}
                  type="password"
                  label="Введите новый пароль"
                  value={f.values.password}
                  onChange={f.handleChange}
                  onBlur={f.handleBlur}
                  className="authorization-form__input"
                  error={f.errors.password && f.touched.password && f.errors.password}
                />
                <TextField
                  required
                  medium
                  tabIndex="2"
                  name={CONFIRM_PASSWORD_FORM_FIELDS.CONFIRM_PASSWORD}
                  type="password"
                  label="Повторите пароль"
                  value={f.values.confirm_password}
                  onChange={f.handleChange}
                  className="authorization-form__input"
                  error={f.errors.confirm_password && f.touched.confirm_password && f.errors.confirm_password}
                />
                <Button
                  type="submit"
                  medium
                  tabIndex="3"
                  label="Изменить"
                  width="100%"
                  className="confirm-password-form__button"
                  color={(!f.isValid || isFetching) ? 'gray' : 'orange'}
                  disabled={!f.isValid || isFetching}
                />
              </form>
            )
            }
          </Formik>}
        </div>
      </div>
    </div>
  )
}

export default ConfirmPasswordForm
