import {
  GET_SCHOOL_INFO_REQUEST,
  GET_SCHOOL_INFO_FAIL,
  GET_SCHOOL_INFO_SUCCESS,
  GET_SCHOOL_PROJECT_SUCCESS,
  GET_SCHOOL_PROJECT_REQUEST,
  GET_SCHOOL_PROJECT_FAIL
} from '../School/School.constants'
import loadReduxTypes from '../../helpers/loadReduxTypes'

const initialState = {
  data: null,
  partners: null,
  schoolProjects: null,
  isFetching: null,
  isProjectFetching: false,
  isReviewFetching: null,
  error: false,
  reviewError: false,
  schoolReviews: null,
  totalProjects: null,
  isReviewPosting: false,
  projectsGoal: null,
  vendors: null
}

const SCHOOL_REVIEW = loadReduxTypes('SCHOOL_REVIEW')
const POST_SCHOOL_REVIEW = loadReduxTypes('POST_SCHOOL_REVIEW')
const GET_PROJECTS_GOAL = loadReduxTypes('GET_PROJECTS_GOAL')
const SCHOOL_PAGE_CLEAR_STATE = 'SCHOOL_PAGE_CLEAR_STATE'

export default function schoolReducer (state = initialState, { type, payload }) {
  switch (type) {
  case GET_SCHOOL_INFO_REQUEST:
    return {
      ...state,
      isFetching: true,
      error: false
    }
  case GET_SCHOOL_INFO_SUCCESS:
    return {
      ...state,
      data: payload.school.data,
      isFetching: false
    }

  case GET_SCHOOL_INFO_FAIL: {
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }
  }

  case GET_SCHOOL_PROJECT_REQUEST:
    return {
      ...state,
      isProjectFetching: true
    }

  case GET_SCHOOL_PROJECT_SUCCESS:
    return {
      ...state,
      schoolProjects: payload.schoolProjects.data,
      isProjectFetching: false
    }

  case GET_SCHOOL_PROJECT_FAIL:
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }

  case GET_PROJECTS_GOAL.REQUEST:
    return {
      ...state
    }

  case GET_PROJECTS_GOAL.SUCCESS:
    return {
      ...state,
      projectsGoal: payload.projectsGoal
    }

  case GET_PROJECTS_GOAL.FAILURE:
    return {
      ...state,
      error: payload.error
    }

  case SCHOOL_REVIEW.REQUEST:
    return {
      ...state,
      isReviewFetching: true
    }

  case SCHOOL_REVIEW.SUCCESS:
    return {
      ...state,
      isReviewFetching: false,
      schoolReviews: payload.reviews
    }

  case SCHOOL_REVIEW.FAILURE:
    return {
      ...state,
      isReviewFetching: false,
      reviewError: payload.error
    }

  case POST_SCHOOL_REVIEW.REQUEST:
    return {
      ...state,
      isReviewPosting: true
    }

  case POST_SCHOOL_REVIEW.SUCCESS:
    return {
      ...state,
      isReviewPosting: false
    }

  case POST_SCHOOL_REVIEW.FAILURE:
    return {
      ...state,
      isReviewPosting: false
    }
  case SCHOOL_PAGE_CLEAR_STATE:
    return initialState

  default: return state
  }
}
