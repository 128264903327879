import React from 'react'

import BaseLayout from '../../layouts/BaseLayout'

const ErrorMessage = () => (
  <BaseLayout>
    <div className="row">
      <div className="col-auto">
        <span>Произошла ошибка, повторите попытку!</span>
      </div>
    </div>
  </BaseLayout>
)

export default ErrorMessage
