import { toast } from 'react-toastify'

import agent from '../../config/methods'
import { OPEN_REGISTRATION_SUCCESS } from '../BaseLayout.constants'

import {
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS
} from './RegistrationForm.constants'
import { checkEmptyFields } from './utils/validation'

function registerUserAction (params, gender, avatarType) {
  let userData

  return function action (dispatch) {
    dispatch({ type: REGISTER_USER_REQUEST })

    const { password, confirm_password, email } = params

    userData = {
      ...checkEmptyFields(params.first_name, params.last_name, gender, avatarType),
      password,
      confirm_password,
      email
    }

    agent.removeToken()
    agent.POST('/register', { ...userData })
      .then((response) => {
        dispatch({
          type: REGISTER_USER_SUCCESS,
          payload: {
            registeredUser: response.data
          }
        })

        dispatch({
          type: OPEN_REGISTRATION_SUCCESS
        })
      })

      .catch((error) => {
        dispatch({
          type: REGISTER_USER_FAILURE,
          payload: {
            error
          }
        })

        const errorData = error.response.data
        if (errorData.email) {
          toast.error(errorData.email[0])
        }
        if (errorData.password) {
          toast.error(errorData.password[0])
        }
      })
  }
}

export { registerUserAction }
