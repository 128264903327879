import * as Yup from 'yup'

import { FORM_FIELD_ERROR } from '../../constants/Errors.constans'

export const POST_REVIEW_VALIDATION = Yup.object()
  .shape({
    text: Yup.string().trim().required(FORM_FIELD_ERROR.REQUIRED_FIELD),
    checkbox: Yup.bool().required('Требуется подтвердить').oneOf([ true ], 'Field must be checked')
  })

export const POST_REVIEW_FIELDS = {
  NAME: 'name',
  TEXT: 'text',
  CHECKBOX: 'checkbox'
}

export const POST_REVIEW_INITIAL = {
  name: '',
  text: '',
  checkbox: false
}
