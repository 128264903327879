import ILSProjectImage from '../../ge/platform-ils/project-section__iloveschool__img.svg'
import CybermanProjectImage from '../../ge/platform-ils/project-section__cyberman__img.png'
import FireProjectImage from '../../ge/platform-ils/project-section__fire__img.png'
import FinanceProjectImage from '../../ge/platform-ils/project-section__finance__img.png'
import ProjectPromotionImage from '../../ge/platform-ils/project-section__promo__img.png'
import CharityImage from '../../ge/platform-ils/project-section__charity__img.jpg'
import ReportImage from '../../ge/platform-ils/project-section__report__img.png'

export const NAVIGATION_BASE = '/about'

export const NAVIGATION_ITEMS = [
  {
    target: 'mission',
    title: 'Миссия'
  },
  {
    target: 'work-targets',
    title: 'Основные направления работы'
  },
  {
    target: 'news',
    title: 'Новости'
  },
  {
    target: 'team',
    title: 'Наша команда'
  },
  {
    target: 'volunteers',
    title: 'Нам помогают'
  },
  {
    target: 'structure',
    title: 'Структура управления НКО'
  },
  {
    target: 'projects',
    title: 'Проекты АНО'
  },
  {
    target: 'reports-and-documents',
    title: 'Отчеты и документы'
  },
  {
    target: 'contacts',
    title: 'Контакты'
  }
]

export const WORK_TARGETS_LIST = [
  'Создание и развитие многофункциональной интернет-платформы предоставления услуг в области образования',
  'Предоставление на интернет-платформе услуг по финансированию и реализации проектов развития образования, инфраструктуры образования',
  'Оказание содействия образовательным учреждениям и другим юридическим лицам, а также гражданам услуг в разработке и реализации мероприятий, направленных на развитие образования',
  'Проведение на безвозмездной основе тематических выставок, конференций, лотерей; проведение выставок-продаж, аукционов, конкурсов, семинаров — в соответствии с целями организации',
  'Издательская и полиграфическая деятельность: издание книг, брошюр, журналов, аудио- и видеопродукции в целях информационной поддержки и реализации программ, направленных на достижение уставных целей организации',
  'Оказание услуг по созданию программных продуктов для образовательных учреждений и меценатов, позволяющих им взаимодействовать друг с другом, для достижения уставных целей организации',
  'Привлечение финансов, финансирования проектов, мероприятий в сфере образования',
  'Безвозмездное оказание любым лицам консультационных услуг по работе многофункциональной интернет-платформой'
]

export const PROJECTS = [
  {
    id: 0,
    image: ILSProjectImage,
    title: 'Платформа по реализации образовательных проектов',
    link: 'https://www.iloveschool.help/',
    description: 'iloveschool.help'
  },
  {
    id: 1,
    image: CybermanProjectImage,
    title: 'Академия киберлюдей',
    link: 'https://cyberman.ils.promo/',
    description: 'cyberman.ils.promo'
  },
  {
    id: 2,
    image: FireProjectImage,
    title: 'Уроки пожарной безопасности',
    link: 'http://fire.ils.promo/',
    description: 'fire.ils.promo'
  },
  {
    id: 3,
    image: FinanceProjectImage,
    title: 'Детям про деньги',
    link: 'http://finance.ils.promo/',
    description: 'finance.ils.promo'
  },
  {
    id: 4,
    image: ProjectPromotionImage,
    title: 'Продвижение краудфандингового проекта',
    link: 'https://ils.promo/page29754577.html',
    description: 'ils.promo'
  },
  {
    id: 5,
    image: CharityImage,
    title: 'Руководство по благотворительности для коммерческих компаний',
    link: 'https://ils.promo/charityguide',
    description: 'ils.promo'
  },
  {
    id: 6,
    image: ReportImage,
    title: 'Годовой отчет',
    link: [
      {
        id: 0,
        link: 'http://ils.promo2022.tilda.ws',
        description: 'Годовой отчет — 2022'
      },
      {
        id: 1,
        link: 'https://ils.promo/annual-report-2021',
        description: 'Годовой отчет — 2021'
      }
    ],
    description: ''
  }
]

export const REPORTS_AND_DOCUMENTS = [
  {
    id: 0,
    title: 'Годовые и проектные публичные отчеты',
    items: [
      {
        id: 0,
        title: 'Годовой отчет — 2023',
        uri: 'https://drive.google.com/file/d/1H8LMij879mSrseXk_oBM4_7BuPrFWiXG/view'
      },
      {
        id: 1,
        title: 'Отчет о реализации социального проекта «Академия киберлюдей: площадка онлайн- и офлайн-реабилитации и обучения для детей и взрослых без руки» (2023, при поддержке Фонда президентских грантов)',
        uri: 'https://drive.google.com/file/u/0/d/1ky9pA_2SqBTmi8PoGex7P-G5EVHasuhP/view?usp=sharing&pli=1'
      },
      {
        id: 2,
        title:
          'Отчет о реализации социального проекта «Академия киберлюдей»: проведение online-реабилитации для людей без рук» (2022, при поддержке Фонда президентских грантов)',
        uri: 'https://drive.google.com/drive/folders/1IO33RCVTFTicjUweSC1pHFtGuvTqtDQS'
      },
      {
        id: 3,
        title: 'Годовой отчет — 2022 (лендинг)',
        uri: 'http://ils.promo2022.tilda.ws'
      },
      {
        id: 4,
        title: 'Годовой отчет — 2022 (брошюра)',
        uri: 'https://drive.google.com/file/d/1XgclI8yH-rD6F2Xfmxs_DxyWW8wH76Ul/view?usp=sharing'
      },
      {
        id: 5,
        title: 'Годовой отчет — 2021',
        uri: 'https://ils.promo/annual-report-2021'
      },
      {
        id: 6,
        title: 'Отчет — 2020/2021',
        uri: '/docs/Публичный_отчет_ILS_2020_-_2021-2.pdf'
      },
      {
        id: 7,
        title: 'Годовой отчет — 2020',
        uri: '/docs/Отчет_2020.pdf'
      },
      {
        id: 8,
        title: 'Годовой отчет — 2019',
        uri: '/docs/Годовой_отчет_2019.pdf'
      }
    ]
  },
  {
    id: 1,
    title: 'Организационные и регистрационные документы',
    items: [
      {
        id: 0,
        title: 'Карточка организации',
        uri: '/docs/Реквизиты_компании.pdf',
        date: '05.10.2022'
      },
      {
        id: 1,
        title: 'Свидетельство о постановке на учет в налоговом органе',
        uri: '/docs/АНО_Платформа_ОП_(ИНН+ОГРН).pdf',
        date: '16.12.2021'
      },
      {
        id: 2,
        title: 'Выписка из ЕГРЮЛ',
        uri: '/docs/Выписка_из_ЕГРЮЛ.pdf',
        date: '12.05.2020'
      },
      {
        id: 3,
        title: 'Свидетельство о государственной регистрации НКО',
        uri: '/docs/АНО_Платформа_ОП_(МИНЮСТ).pdf',
        date: '16.01.2019'
      },
      {
        id: 4,
        title: 'Устав организации',
        uri: '/docs/АНО_Платформа_ОП_(УСТАВ).pdf',
        date: '18.12.2018'
      }
    ]
  },
  {
    id: 2,
    title: 'Отчеты в Минюст России',
    items: [
      {
        id: 0,
        title: 'Отчет о деятельности НКО и о персональном составе — 2023',
        uri: '/docs/Отчет о деятельности НКО и о персональном составе  — 2023.pdf',
        date: '12.04.2024'
      },
      {
        id: 1,
        title: 'Отчет о целях расходования НКО денежных средств — 2023',
        uri: '/docs/Отчет о целях расходования НКО денежных средств  — 2023.pdf',
        date: '12.04.2024'
      },
      {
        id: 2,
        title: 'Отчет о целях расходования НКО денежных средств — 2022',
        uri: '/docs/Отчет о целях расходования НКО денежных средств  — 2022.pdf',
        date: '13.04.2023'
      },
      {
        id: 3,
        title: 'Отчет о деятельности НКО и о персональном составе — 2022',
        uri: '/docs/Отчет о деятельности НКО и о персональном составе  — 2022.pdf',
        date: '13.04.2023'
      },
      {
        id: 4,
        title: 'Отчет о деятельности НКО и о персональном составе — 2021',
        uri: '/docs/АНО_МИНЮСТ_2021_ФОРМА_ОН0001.pdf',
        date: '13.04.2022'
      },
      {
        id: 5,
        title: 'Отчет о целях расходования НКО денежных средств — 2021',
        uri: '/docs/АНО_МИНЮСТ_2021_ФОРМА_ОН0002.pdf',
        date: '13.04.2022'
      },
      {
        id: 6,
        title: 'Сообщение о продолжении деятельности — 2021',
        uri: '/docs/Сообщение_о_продолжении_деятельности_2021.pdf',
        date: '06.04.2021'
      },
      {
        id: 7,
        title: 'Сообщение о продолжении деятельности — 2020',
        uri: '/docs/Сообщение_о_продолжении_деятельности_2020.pdf',
        date: '06.04.2020'
      }
    ]
  }
]

export const CONTACTS = {
  OGRN: {
    title: 'ОГРН:',
    value: '1195958000424'
  },
  INN: {
    title: 'ИНН:',
    value: '5902051740'
  },
  KPP: {
    title: 'КПП:',
    value: '590201001'
  },
  email: {
    title: 'Почта:',
    value: 'info@iloveschool.help'
  },
  phone: {
    title: 'Телефон:',
    value: '8 800 555 2081'
  },
  address: {
    title: 'Адрес',
    value: '614066, Пермский край, г. Пермь, ул. Шоссе Космонавтов, д. 111и, к. 1, помещение 1'
  }
}
