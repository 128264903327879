import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { Button } from '../Button'
import { API_IMG } from '../../constants/Api.constants'

const ProjectBonusesFromPartners = () => {
  const { data: { partner_bonuses: partnerBonuses } } = useSelector(state => state.project)

  const captionStyle = name => classNames('bonuses__caption', {
    'bonuses__caption--margin-none': !name
  })

  return (
    <>
      {partnerBonuses?.map((item, index) => (
        <div className="info-block bonuses" key={index}>
          {item.name && <h3 className="bonuses__title bonuses__title--text-center">{item.name}</h3>}
          {item.description && (
            <div className={captionStyle(item.name)} dangerouslySetInnerHTML={{ __html: item.description }}></div>
          )}
          {item.image && (
            <img
              src={`${API_IMG}/${item.image}`}
              alt={`Изображение для ${item.name}`}
              className="bonuses__image" />
          )}
          {item.link && (
            <Button
              href={item.link}
              tabIndex="13"
              label="Получить"
              color="orange"
              className="bonuses__link"
            />
          )}
        </div>
      ))}
    </>
  )
}

export default ProjectBonusesFromPartners
