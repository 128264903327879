import {
  GET_NEWS_SUCCESS,
  GET_NEWS_REQUEST,
  GET_NEWS_FAIL
} from './News.constants'

const initialState = {
  news: null,
  isFetching: false
}

export default function newsReducer (state = initialState, { type, payload }) {
  switch (type) {
  case GET_NEWS_REQUEST:
    return {
      ...state,
      isFetching: true
    }
  case GET_NEWS_SUCCESS:
    return {
      ...state,
      news: payload.news,
      isFetching: false
    }
  case GET_NEWS_FAIL:
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }

  default: return state
  }
}
