import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import img from '../../ge/arrow-left-black.svg'

const ScrollButton = () => {
  const [ isHidden, setIsHidden ] = useState(true)

  const buttonClassName = classNames('scroll-button', { 'hidden-button': isHidden })
  const clickHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  useEffect(() => {
    const onScrollHandler = () => { setIsHidden(window.pageYOffset < document.documentElement.clientHeight) }
    window.addEventListener('scroll', onScrollHandler)
    return () => window.removeEventListener('scroll', onScrollHandler)
  }, [])

  return (
    <button type="button" className={buttonClassName} onClick={clickHandler}>
      <img src={img} alt="" className="scroll-button__image" />
    </button>
  )
}

export default ScrollButton
