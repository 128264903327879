import React from 'react'

import noUserPicture from '../../ge/no-userpic.svg'
import { API_IMG } from '../../constants/Api.constants'
import { formatPicturePath } from '../../helpers/formatters'

const PartnerPerson = ({ portrait, name, position, company }) => {
  const imageSource = (!portrait.avatar && !portrait.avatar_type &&
    noUserPicture) || (portrait.avatar && `${API_IMG}/${portrait.avatar.filename}`) ||
    formatPicturePath(portrait.avatar_type)

  return (
    <div className="partner-person">
      <img className="partner-person__portrait" alt={'Partner avatar'} src={imageSource} />
      <div className="partner-person__name">
        {name}
      </div>
      <div className="partner-person__position">
        {position}
      </div>
      <div className="partner-person__company">
        {company}
      </div>
    </div>
  )
}

export default PartnerPerson
