export const GET_SCHOOL_INFO_REQUEST = 'GET_SCHOOL_INFO_REQUEST'
export const GET_SCHOOL_INFO_SUCCESS = 'GET_SCHOOL_INFO_SUCCESS'
export const GET_SCHOOL_INFO_FAIL = 'GET_SCHOOL_INFO_FAIL'

export const GET_SCHOOL_PROJECT_REQUEST = 'GET_SCHOOL_PROJECT_REQUEST'
export const GET_SCHOOL_PROJECT_SUCCESS = 'GET_SCHOOL_PROJECT_SUCCESS'
export const GET_SCHOOL_PROJECT_FAIL = 'GET_SCHOOL_PROJECT_FAIL'

export const partnersSchool = [
  '/images/clogo05.jpg',
  '/images/clogo01.jpg',
  '/images/clogo03.jpg',
  '/images/clogo04.jpg',
  '/images/clogo06.png',
  '/images/clogo01.jpg'
]

export const schoolSliderContent = [
  {
    img: '/images/partnava01.jpg',
    name: 'Antonio Bridges',
    position: 'Заместитель директора по развитию компании',
    company: 'Компания Талан'
  },
  {
    img: '/images/partnava02.jpg',
    name: 'Rachel Fleming',
    position: 'Организатор мероприятий',
    company: 'Компания Мегаполис'
  },
  {
    img: '/images/partnava03.jpg',
    name: 'Lura Sandoval',
    position: 'Специалист по связям с общественностью',
    company: 'SKL Group'
  },
  {
    img: '/images/partnava04.jpg',
    name: 'Todd Bryan',
    position: 'Генеральный директор',
    company: 'Компания Total'
  },
  {
    img: '/images/partnava01.jpg',
    name: 'Lura Sandoval',
    position: 'Специалист по связям с общественностью',
    company: 'SKL Group'
  },
  {
    img: '/images/partnava02.jpg',
    name: 'Todd Bryan',
    position: 'Генеральный директор',
    company: 'Компания Total'
  }
]

export const schoolStatsItems = [
  {
    value: '100 000 000 ₽',
    label: 'Собрано'
  },
  {
    value: '10',
    label: 'Проектов'
  },
  {
    value: '35',
    label: 'Партнеров'
  }
]
