import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import classNames from 'classnames'

import ProjectInfoAvatar from './ProjectInfoAvatar'

const ProjectMembers = ({ projectMembers }) => (
  <>
    {projectMembers && projectMembers.map((projectMember, i) => (
      <Fragment key={i}>
        {projectMember && (
          <div className={classNames('info-block', { 'ambassador': i === 0 })}>
            {i === 0 && (
              <p className="ambassador__title">Амбассадор проекта</p>
            )}
            <div className="info-block__high-container">
              <div className="info-block__top">
                <div className="info-block__avatar">
                  <ProjectInfoAvatar projectMember={projectMember} />
                </div>
                <div className="info-block__quote">{projectMember.text || 'Цитата не указана'}</div>
              </div>
              <div className="info-block__bottom-wrapper">
                <div className="info-block__name">{projectMember.name || ''}</div>
                <div className="info-block__position">{projectMember.position || ''}</div>
              </div>
            </div>
            <div className="info-block__middle-container">
              <div className="info-block__middle-container-top">
                <div>
                  <div className="info-block__avatar">
                    <ProjectInfoAvatar projectMember={projectMember} />
                  </div>
                </div>
                <div className="info-block__header">
                  <div className="info-block__name">{projectMember.name || ''}</div>
                  <div className="info-block__position">{projectMember.position || ''}</div>
                </div>
              </div>

              <div className="info-block__quote">{projectMember.text || 'Цитата не указана'}</div>

            </div>

          </div>
        )}
      </Fragment>
    )
    )}
  </>
)

export default ProjectMembers

ProjectMembers.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    avatar: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.number,
        uploaded_at: PropTypes.string,
        filename: PropTypes.string
      })
    ]),
    position: PropTypes.string.isRequired,
    profile_link: PropTypes.string
  }))
}
