import React from 'react'
import classNames from 'classnames'

const DonateSupport = ({ saleValues, setActiveSaleIndex, activeSaleIndex }) => {
  const circleClassNames = (id) => classNames('payment-form__circle-percent', {
    'payment-form__circle-percent--selected': id === activeSaleIndex
  })

  return (
    <>
      <h2 className="payment-form__contribution">Поддержка платформы I Love School</h2>

      <div className="payment-form__percent-wrapper">
        {saleValues.map((sale, index) => (
          <div
            key={String(sale)}
            className={circleClassNames(index)}
            onClick={() => setActiveSaleIndex(index)}
          >
            <span>{sale}%</span>
          </div>
        ))}
      </div>
    </>
  )
}

export default DonateSupport
