import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import BaseLayout from '../../layouts/BaseLayout'
import { Button } from '../../components/Button'
import { API_IMG } from '../../constants/Api.constants'
import Loader from '../../components/Loader'
import noUserPicture from '../../ge/no-userpic.svg'

import { getNews } from './News.actions'
import { NEWS_COUNT } from './News.constants'

const NewsPage = () => {
  const history = useHistory()
  const [ newsCount, setNewsCount ] = useState(NEWS_COUNT)

  const dispatch = useDispatch()
  const { news: { news, isFetching } } = useSelector(state => state)

  useEffect(() => {
    dispatch(getNews(newsCount))
  }, [ newsCount, dispatch ])

  if (isFetching && !news) {
    return (
      <BaseLayout>
        <div className="container-app">
          <div className="container-fluid">
            <div className="news-article__loader">
              <Loader />
            </div>
          </div>
        </div>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout>
      <div className="container-app">
        <div className="container-fluid">
          <div className="news-section">
            <h1 className="news-section__title">Новости</h1>
            <div className="news-section__content">
              {news && news.data.items.map((news) => (
                <div
                  key={news.id}
                  className="news-card"
                  onClick={() => history.push(`/news/${news.id}`)}
                >
                  <div className="news-card__content">
                    <img
                      alt={'Cover for news article'}
                      className="news-card__img"
                      src={news.photo ? `${API_IMG}/${news.photo.filename}` : noUserPicture}
                    />
                    <p className="news-card__date">{moment(news.created_at).format('LL')}</p>
                    <h4 className="news-card__title">{news.title}</h4>

                    <div dangerouslySetInnerHTML={{ __html: news.summary }} />

                  </div>
                </div>
              ))}
            </div>
            <div className="row justify-content-center margin-top-15">
              <div className="col-xl-auto col">
                {news && newsCount < news.data.total && <Button
                  onClick={() => setNewsCount(newsCount + 3)}
                  disabled={isFetching}
                  label={isFetching ? 'Загрузка...' : 'Показать еще'}
                  large
                  nobg
                  width="100%"
                  className="btn__show-more"
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default NewsPage
