import { toast } from 'react-toastify'

import agent from '../../config/methods'
import loadReduxTypes from '../../helpers/loadReduxTypes'
import { USER_AUTH_SUCCESS } from '../../layouts/authorization/Authorization.constants'

const ACTIVATE_ACCOUNT = loadReduxTypes('ACTIVATE_ACCOUNT')

export default function activateAccount ({ token }) {
  return function action (dispatch) {
    dispatch({
      type: ACTIVATE_ACCOUNT.REQUEST
    })

    agent.POST('/signup-complete', { code: token })
      .then((response) => {
        dispatch({
          type: ACTIVATE_ACCOUNT.SUCCESS
        })

        const { data: { token, user } } = response.data
        dispatch({ type: USER_AUTH_SUCCESS, payload: { data: user } })
        agent.setToken(token)

        toast.success('Аккаунт успешно подтвержден!')
      })

      .catch((error) => {
        dispatch({
          type: ACTIVATE_ACCOUNT.FAILURE,
          payload: {
            error
          }
        })

        const errorData = error && error.response.data

        if (errorData) {
          const errorMessage = errorData.error

          if (errorMessage === 'User already activated') {
            toast.error('Аккаунт пользователя уже активирован!')
          } else if (errorMessage === 'Invalid token') {
            toast.error('Ссылка для активации не корректна')
          } else {
            toast.error('Произошла ошибка активации аккаунта')
          }
        }
      })
  }
}
