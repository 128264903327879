import { API_IMG } from '../../constants/Api.constants'
import { PROJECT_AGE_GROUPS } from '../CreateProject/CreateProject.constants'

/**
 * Generates image link by fields uuid and filename for css styles and links for meta-tags.
 * @param object
 * @param field
 * @param isMetaTag
 * @returns {string}
 */

export const getCoverImage = (object, field = 'photo', isMetaTag = false) => {
  if (object && object[field]) {
    const cover = object[field].filename ? (`${API_IMG}/${object[field].filename}`) : (`${API_IMG}/${object[field]}`)

    if (isMetaTag) {
      return cover
    }

    return `url("${cover}")`
  }
  return '#f9f9f9'
}

/**
 * Formats images array (logotypes) and adds news fields with src link.
 * @param array
 * @returns {array}
 */

export const formatArrayImages = (array) => {
  return array.map((item) => ({
    ...item,
    logo: getCoverImage(item, 'logo', true)
  }))
}

/**
 * Adds new field for data (Comments, Partners) which contains user field and generates file name
 * @param links
 * @param data
 * @returns {array}
 */

export const formatUserAvatars = (links, data) => {
  data.forEach((dataItem) => {
    const link = links.find((link) => { return link.id === dataItem.user.avatar })
    if (link) {
      dataItem.user.avatar = `${link.uuid}_${link.filename}`
    }
  })

  return data
}

/**
 * Generates string labels with age groups of project
 * @param array
 * @returns {string}
 */

export const formatAgeGroup = (array) => {
  const labels = PROJECT_AGE_GROUPS.reduce((labels, item) => {
    const isFind = array.find((id) => id === item.value)
    if (isFind) labels.push(` ${item.label}`)
    return labels
  }, [])

  return labels.toString()
}

/**
 * Generates a string declension by number of count
 * @param count
 * @param dictionary
 * @example
 *  > getPluralizeWord(1234, ["проект", "проекта", "проектов"])
 *  > 1234 проекта
 * @returns {array}
 */

export const getPluralizeWord = (count, dictionary) => {
  const remainder = Math.abs(count) % 10

  if ((count >= 10 && count <= 20) || (remainder === 0) || (remainder >= 5 && remainder <= 9)) {
    return dictionary[2]
  }

  if (remainder >= 2 && remainder <= 4) {
    return dictionary[1]
  }

  return dictionary[0]
}
