import * as Yup from 'yup'

export const AUTH_FORM_INITIAL_VALUES = {
  'email': '',
  'password': '',
  'auth_checkbox': ''
}

export const AUTH_FORM_FIELDS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  CHECKBOX: 'auth_checkbox'
}

export const USER_AUTH_REQUEST = 'USER_AUTH_REQUEST'
export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS'
export const USER_AUTH_FAIL = 'USER_AUTH_FAIL'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL'

export const SET_PROFILE_AVATAR = 'SET_PROFILE_AVATAR'

export const AUTH_VALIDATION = Yup.object().shape({
  email: Yup.string()
    .required('Данное поле должно быть заполнено.')
    .email('Введите корректный адрес электронной почты.'),
  password: Yup.string()
    .required('Данное поле должно быть заполнено.')
    .test('len', 'Пароль должен состоять из минимум 6 символов', password => password && password.length >= 6)
})
