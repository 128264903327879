import React from 'react'
import classNames from 'classnames'

export const Social = ({ className }) => {
  return (
    <div className={classNames(className, 'social')}>
      <a
        href="https://vk.com/iloveschoolhelp"
        target="_blank"
        rel="noopener noreferrer"
        className="social__item social__item--vk"
      />
      <a
        href="https://www.facebook.com/iloveschoolhelp/"
        target="_blank"
        rel="noopener noreferrer"
        className="social__item social__item--fb"
      />
      <a
        href="https://www.instagram.com/iloveschoolhelp/"
        target="_blank"
        rel="noopener noreferrer"
        className="social__item social__item--ig"
      />
    </div>
  )
}
