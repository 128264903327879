export const FORM_FIELD_ERROR = {
  REQUIRED_FIELD: 'Поле обязательно для заполнения',
  REQUIRED_ALL_FIELDS: 'Необходимо заполнить все поля',
  REQUIRED_FILES: 'Необходимо загрузить файлы',
  REQUIRED_LOGO: 'Необходимо загрузить логотип',
  REQUIRED_AT_LEAST_ONE_OPTION: 'Необходимо выбрать хотя бы один вариант',
  MUST_BE_POSITIVE: 'Значение должно быть положительным',
  MUST_BE_NEGATIVE: 'Значение должно быть отрицательным',
  MUST_BE_INTEGER: 'Значение должно быть целым',
  TYPE_NUMBER: 'Значение должно быть числом',
  REQUEST_CATEGORY: 'Выберите категорию или опишите недостающую',
  VIDEO_LINK_FORMAT: 'Проверьте правильность написания ссылки. Пример: https://www.youtube.com/watch?v=Bey4XXJAqS8',
  VIDEO_DESCRIPTION: 'Минимальная длина – 1 символ',
  MUST_BE_CHECKED: 'Требуется подтвердить',
  PHONE_INCORRECT: 'Неверный номер телефона',
  EMAIL_INCORRECT: 'Неверный адрес электронной почты',
  TOO_LARGE: 'Слишком много символов'
}
