import React, { useState, useEffect } from 'react'
import { useRouteMatch, useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

import agent from '../../config/methods'
import BaseLayout from '../../layouts/BaseLayout'
import MetaTags from '../../components/MetaTags'
import { META_TAGS_LIST } from '../../constants/Meta.constants'
import Loader from '../../components/Loader'

const AnswerPage = () => {
  const history = useHistory()
  const { hash } = useLocation()
  const match = useRouteMatch()
  const articleId = match.params.articleId

  const [ isLoading, setLoading ] = useState(false)
  const [ answer, setAnswer ] = useState(null)

  useEffect(() => {
    if (!articleId) return

    const getArticle = async () => {
      try {
        setLoading(true)
        const response = await agent.GET(`/faq/sections/${articleId}`)
        const data = await response.data
        setAnswer(data)
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            history.push('/not-found')
          }
        }
      } finally {
        setLoading(false)
      }
    }

    getArticle()
  }, [ articleId ])

  useEffect(() => {
    if (hash && answer) {
      setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''))
        if (element) {
          window.scrollTo({ top: element.offsetTop + 120, behavior: 'smooth' })
        }
      }, 0)
    }
  }, [ hash, answer ])

  if (!answer) return null

  return (
    <BaseLayout>
      <MetaTags
        title={META_TAGS_LIST.FAQ_TITLE}
        description={META_TAGS_LIST.FAG_DESCRIPTION}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-app">
          <div className="container-fluid">
            <main className="answer__container">
              <h1>{answer?.title}</h1>
              {answer?.articles && answer.articles.length && answer.articles.map((article) => (
                <div id={`paragraph${article?.id}`} key={article?.id}>
                  <h3>{article?.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: article?.content }} />
                </div>
              ))}
            </main>
          </div>
        </div>
      )}

    </BaseLayout>
  )
}

export default AnswerPage
