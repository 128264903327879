import loadReduxTypes from '../helpers/loadReduxTypes'

import {
  OPEN_AUTHORIZATION_FORM,
  CLOSE_AUTHORIZATION_FORM,
  OPEN_REGISTRATION_FORM,
  CLOSE_REGISTRATION_FORM,
  OPEN_CHANGE_PASSWORD_FORM,
  CLOSE_CHANGE_PASSWORD_FORM,
  OPEN_CONFIRM_PASSWORD_FORM,
  CLOSE_CONFIRM_PASSWORD_FORM,
  CLOSE_REGISTRATION_SUCCESS,
  OPEN_REGISTRATION_SUCCESS,
  RESET_ERROR_SUCCESS,
  OPEN_MOBILE_MENU,
  CLOSE_MOBILE_MENU
} from './BaseLayout.constants'
import {
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS
} from './registration/RegistrationForm.constants'

const GET_RESTORATION_PASSWORD = loadReduxTypes('GET_RESTORATION_PASSWORD')
const SAVE_NEW_PASSWORD = loadReduxTypes('SAVE_NEW_PASSWORD')

const initialState = {
  showAuthorizationForm: false,
  showRegistrationFrom: false,
  showChangePasswordFrom: false,
  showConfirmPasswordFrom: false,
  showRegistrationSuccess: false,
  isShowMobileMenu: false,
  registeredUser: null,
  isFetching: false,
  restorationEmail: '',
  error: null
}

export default function baseLayoutReducer (state = initialState, { type, payload }) {
  switch (type) {
  case OPEN_AUTHORIZATION_FORM:
    return {
      ...state,
      showAuthorizationForm: true
    }

  case CLOSE_AUTHORIZATION_FORM:
    return {
      ...state,
      showAuthorizationForm: false
    }

  case OPEN_REGISTRATION_FORM:
    return {
      ...state,
      showRegistrationFrom: true
    }

  case CLOSE_REGISTRATION_SUCCESS:
    return {
      ...state,
      showRegistrationSuccess: false
    }

  case OPEN_REGISTRATION_SUCCESS:
    return {
      ...state,
      showRegistrationSuccess: true
    }

  case CLOSE_REGISTRATION_FORM:
    return {
      ...state,
      showRegistrationFrom: false
    }

  case OPEN_CHANGE_PASSWORD_FORM:
    return {
      ...state,
      showChangePasswordFrom: true
    }

  case CLOSE_CHANGE_PASSWORD_FORM:
    return {
      ...state,
      restorationEmail: initialState.restorationEmail,
      showChangePasswordFrom: false
    }

  case OPEN_CONFIRM_PASSWORD_FORM:
    return {
      ...state,
      showConfirmPasswordFrom: true
    }

  case CLOSE_CONFIRM_PASSWORD_FORM:
    return {
      ...state,
      showConfirmPasswordFrom: false
    }

  case REGISTER_USER_REQUEST:
    return {
      ...state,
      isFetching: true
    }

  case REGISTER_USER_SUCCESS:
    return {
      ...state,
      isFetching: false,
      registeredUser: payload.registeredUser
    }

  case REGISTER_USER_FAILURE:
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }

  case GET_RESTORATION_PASSWORD.REQUEST:
    return {
      ...state,
      isFetching: true
    }

  case GET_RESTORATION_PASSWORD.SUCCESS:
    return {
      ...state,
      isFetching: false,
      restorationEmail: payload
    }

  case GET_RESTORATION_PASSWORD.FAILURE:
    return {
      ...state,
      isFetching: false,
      restorationEmail: initialState.restorationEmail,
      error: payload.error
    }

  case RESET_ERROR_SUCCESS:
    return {
      ...state,
      restorationEmail: initialState.restorationEmail,
      error: initialState.error
    }

  case SAVE_NEW_PASSWORD.REQUEST:
    return {
      ...state,
      isFetching: true
    }

  case SAVE_NEW_PASSWORD.SUCCESS:
    return {
      ...state,
      isFetching: false
    }

  case SAVE_NEW_PASSWORD.FAILURE:
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }
  case OPEN_MOBILE_MENU:
    return {
      ...state,
      isShowMobileMenu: true
    }

  case CLOSE_MOBILE_MENU:
    return {
      ...state,
      isShowMobileMenu: false
    }

  default: return state
  }
}
