
import { GET_PROJECTS_REQUEST, GET_PROJECTS_SUCCESS, GET_PROJECTS_FAIL, PROJECTS_CLEAR_STATE } from './SearchProject.constants'

const initialState = {
  projects: null,
  error: null,
  isFetching: null
}

export default function projectsReducer (state = initialState, { type, payload }) {
  switch (type) {
  case PROJECTS_CLEAR_STATE:
    return initialState

  case GET_PROJECTS_REQUEST:
    return {
      ...state,
      isFetching: true
    }
  case GET_PROJECTS_SUCCESS:
    return {
      ...state,
      projects: payload.projects,
      isFetching: false
    }
  case GET_PROJECTS_FAIL:
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }

  default: return state
  }
}
