import classNames from 'classnames'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import OpenMenuIcon from '../../ge/mobile-open-menu.svg'
import Login from '../../ge/login.svg'
import imgLogo from '../../ge/logo.svg'
import { Button } from '../Button'
import ProfileBlock from '../ProfileBlock'
import { mobileMenuHandler, openAuthorizationForm } from '../../layouts/BaseLayout.actions'

const Header = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const { isAuthorized, user, isAuthFetching } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const isPlatformPage = match.path === '/platform'
  const buttonColor = isPlatformPage ? 'white' : 'gray'

  const getClassName = (componentName) => classNames(
    `header-mobile__${componentName}`,
    { 'header-mobile--white': isPlatformPage }
  )

  const handleProfileClick = () => {
    if (isAuthorized) {
      history.push('/profile')
    } else {
      dispatch(openAuthorizationForm())
    }
  }

  return (
    <header className="header">
      <div className="container-app header__block">
        <Link to="/" className="header__logo-block">
          <img src={imgLogo} alt="I Love School" className="header__logo-img" />
        </Link>

        <div className="header__platform-block">
          <Button
            label="Поддержи проекты"
            color={buttonColor}
            link="/projects?type=all"
            noborder
            nocaps
            nobg
            className="header__platform-button"
          />
          <Button
            label="Создай проект"
            color={buttonColor}
            link="/project-info"
            noborder
            nocaps
            nobg
            className="header__platform-button"
          />
          <Button
            label="Стань партнером"
            color={buttonColor}
            link="/partner-info"
            noborder
            nocaps
            nobg
            className="header__platform-button"
          />
          <Button
            label="Частые вопросы"
            color={buttonColor}
            link="/faq"
            noborder
            nocaps
            nobg
            className="header__platform-button"
          />
          <Button
            label="Платформа ILS"
            color={buttonColor}
            link="/platform"
            noborder
            nocaps
            nobg
            className="header__platform-button"
          />
          <Button
            label="О нас"
            color={buttonColor}
            link="/about"
            noborder
            nocaps
            nobg
            className="header__platform-button"
          />
        </div>
        <div className="header-mobile__right-container">
          <ProfileBlock
            isAuthorized={isAuthorized}
            isWhiteIcon={isPlatformPage}
            user={user}
            isAuthFetching={isAuthFetching}
          />

          {!isAuthorized && !isAuthFetching &&
            <div className={getClassName('profile')} onClick={() => handleProfileClick()}>
              <img src={Login} alt="login" />
              <div className="mobile__profile-authorized" />
            </div>
          }

          <div
            className={getClassName('menu-controls')}
            onClick={() => dispatch(mobileMenuHandler(true))}>
            <img alt="Menu button" src={OpenMenuIcon} />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
