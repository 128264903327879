import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import MetaTags from 'react-meta-tags'
import classNames from 'classnames'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { API_IMG } from '../../constants/Api.constants'
import noUserPicture from '../../ge/no-userpic.svg'
import BaseLayout from '../../layouts/BaseLayout'
import { Button } from '../../components/Button'
import { getNewsArticle, clearNewsStorage } from '../NewsArticle/NewsArticle.actions'
import Loader from '../../components/Loader'
import SharingButtons from '../../components/SharingButtons'

const NewsArticlePage = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const { newsArticle: {
    newsArticle,
    isFetching,
    nextArticle,
    previousArticle
  } } = useSelector(state => state)

  useEffect(() => {
    const id = match.params.id

    dispatch(getNewsArticle(id))

    return () => {
      dispatch(clearNewsStorage())
    }
  }, [ match.params.id, dispatch ])

  const spanPrevNames = classNames({
    'news-article__button-text': true,
    'news-article__button-text--disabled': !previousArticle
  })

  const spanNextNames = classNames({
    'news-article__button-text': true,
    'news-article__button-text--disabled': !nextArticle
  })

  if (isFetching || !newsArticle) {
    return (
      <BaseLayout>
        <div className="container-app">
          <div className="container-fluid">
            <div className="news-article__loader">
              <Loader />
            </div>
          </div>
        </div>
      </BaseLayout>
    )
  }

  const title = newsArticle && newsArticle.data.title
  const image = newsArticle.data.photo ? `${API_IMG}/${newsArticle.data.photo.filename}` : noUserPicture
  const summary = newsArticle && newsArticle.data.summary

  return (
    <BaseLayout>
      <MetaTags
        titile={title}
        description={summary}
        image={image}
      />

      <div className="container-app">
        <div className="container-fluid">
          <div className="news-article">
            <div className="news-article__header">
              <p className="news-article__header-date">
                {moment(new Date(newsArticle && newsArticle.data.created_at)).format('LL')}
              </p>
              <h1 className="news-article__header-title">{title}</h1>
            </div>
            <div className="news-article__cover">
              <img alt="News cover" src={image} />
            </div>
            <div className="news-article__content">
              <div className="news-article__content-text"
                dangerouslySetInnerHTML={{ __html: newsArticle && newsArticle.data.text }}
              />

              <div className="news-article__social">
                <p className="news-article__social-title">Поделиться</p>
                <div className="news-article__social-content">
                  <SharingButtons title={title} />
                </div>
              </div>

              <div className="news-article__wrap">
                <Button
                  className="news-article__button"
                  tabIndex="13"
                  color="gray"
                  medium
                  nobg
                  nocaps
                  noborder
                  onClick={() => previousArticle && history.push(`/news/${previousArticle}`)}
                  disabled={!previousArticle}
                  label={<span className={spanPrevNames}>
                    <span className="news-article__button-arrow news-article__button-arrow--prev" />Предыдущая статья
                  </span>}
                />
                <Button
                  className="news-article__button"
                  tabIndex="14"
                  color="gray"
                  medium
                  nobg
                  nocaps
                  noborder
                  disabled={!nextArticle}
                  onClick={() => nextArticle && history.push(`/news/${nextArticle}`)}
                  label={<span className={spanNextNames}>
                       Следующая статья<span className="news-article__button-arrow news-article__button-arrow--next" />
                  </span>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

export default NewsArticlePage
