import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { HashLink } from 'react-router-hash-link'

import { Button } from '../Button'
import { mobileMenuHandler } from '../../layouts/BaseLayout.actions'

const MobileMenu = () => {
  const history = useHistory()
  const { baseLayoutReducer: { isShowMobileMenu } } = useSelector(state => state)

  const dispatch = useDispatch()

  const formClassName = classNames({
    'mobile-menu': true,
    'mobile-menu--show': isShowMobileMenu
  })

  const closeMenuHandler = () => {
    dispatch(mobileMenuHandler(false))
  }

  return (
    <div className={formClassName}>
      <div className="mobile-menu__content">
        <div className="mobile-menu__content-wrap text-center">
          <i className="icon icon--close mobile-menu__close" onClick={() => closeMenuHandler()} />

          <ul className="mobile-menu__menu-list">

            <div className="mobile-menu__menu-buttons">
              <Button
                label="Создать проект"
                color="blue"
                className="mobile-menu__menu-button"
                onClick={() => {
                  history.push('/projects/new')
                  closeMenuHandler()
                }}
              />
              <Button
                label="Поддержать проект"
                color="orange"
                className="mobile-menu__menu-button"
                onClick={() => {
                  history.push('/projects?type=all')
                  closeMenuHandler()
                }}
              />
            </div>

            <li className="mobile-menu__menu-item">
              <Link
                to="/project-info"
                className="mobile-menu__menu-link"
                onClick={() => closeMenuHandler()}>
                Создай проект
              </Link>
            </li>
            <li className="mobile-menu__menu-item">
              <Link
                to="/partner-info"
                className="mobile-menu__menu-link"
                onClick={() => closeMenuHandler()}>
                Стань партнером
              </Link>
            </li>
            <li className="mobile-menu__menu-item">
              <Link
                to="/platform"
                className="mobile-menu__menu-link"
                onClick={() => closeMenuHandler()}>
                Платформа ILS
              </Link>
            </li>
            <li className="mobile-menu__menu-item">
              <Link
                to="/about"
                className="mobile-menu__menu-link"
                onClick={() => closeMenuHandler()}>
                О нас
              </Link>
            </li>
            <li className="mobile-menu__menu-item">
              <HashLink
                to="/about#team"
                className="mobile-menu__menu-link"
                onClick={() => closeMenuHandler()}>Команда
              </HashLink>
            </li>
            <li className="mobile-menu__menu-item">
              <Link
                to="/news"
                className="mobile-menu__menu-link"
                onClick={() => closeMenuHandler()}>
                Новости
              </Link>
            </li>
            <li className="mobile-menu__menu-item">
              <Link
                to="/documents"
                className="mobile-menu__menu-link"
                onClick={() => closeMenuHandler()}>
                Документы
              </Link>
            </li>
            <li className="mobile-menu__menu-item">
              <Link
                to="/faq"
                className="mobile-menu__menu-link"
                onClick={() => closeMenuHandler()}>
                Частые вопросы
              </Link>
            </li>
            <li className="mobile-menu__menu-item">
              <Link
                to="/partners"
                className="mobile-menu__menu-link"
                onClick={() => closeMenuHandler()}>
                Партнеры
              </Link>
            </li>
            <li className="mobile-menu__menu-item">
              <Link
                to="/schools"
                className="mobile-menu__menu-link"
                onClick={() => closeMenuHandler()}>
                Инициаторы проектов
              </Link>
            </li>
          </ul>
          <div>
            <div className="mobile-menu__menu-contacts">
              <a href="mailto:info@iloveschool.help" className="mobile-menu__menu-contact">info@iloveschool.help</a>
              <a href="tel:8 800 555 2081" className="mobile-menu__menu-contact">8 800 555 2081</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
