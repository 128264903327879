import * as Yup from 'yup'
import validateCard from 'card-validator'

import { FORM_FIELD_ERROR } from '../../constants/Errors.constans'

export const PAYMENT_FORM_FIELDS = {
  AMOUNT: 'amount'
}

export const saleValues = [ 0, 8, 15 ]

export const getPaymentInitialValues = (amount) => ({ amount: amount || PAYMENT_FORM_PROJECT_INFO.amount })

export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const PAYMENT_FORM_PROJECT_INFO = {
  amount: 1000
}

export const PAYMENT_FORM_ERRORS = {
  AMOUNT: 'Минимальная сумма – 100 ₽, максимальная – 200 000 ₽',
  MAX: 'Процент для поддержки площадки не должен превышать 80%.'
}

export const validateAmount = (value) => value >= 100 && value <= 200000

export const VALIDATION_SCHEMA = Yup.object()
  .shape({
    amount: Yup
      .number()
      .test('check_min_max_amount', PAYMENT_FORM_ERRORS.AMOUNT, amount => {
        return validateAmount(amount)
      })
      .typeError(FORM_FIELD_ERROR.TYPE_NUMBER)
      .positive(FORM_FIELD_ERROR.MUST_BE_POSITIVE)
      .integer(FORM_FIELD_ERROR.MUST_BE_INTEGER)
      .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
  })

export const CALCULATE_FORM_FIELDS = {
  MAIN_AMOUNT: 'main_amount'
}

export const NEW_PAYMENT_FORM_FIELDS = {
  AMOUNT: 'amount',
  CARD_NUMBER: 'cardNumber',
  EXPIRATION_DATE_MONTH: 'expDateMonth',
  EXPIRATION_DATE_YEAR: 'expDateYear',
  CVV: 'cvv',
  NAME: 'name',
  OFFER: 'offer',
  PERSONAL_DATA: 'personal-data',
  SUBSCRIPTION: 'subscription'
}

export const getInitialValues = (amount = '') => ({ main_amount: amount })

export const CALCULATE_VALIDATION_SCHEMA = Yup.object().shape({
  main_amount: Yup
    .number()
    .test('check_min_max_amount', PAYMENT_FORM_ERRORS.AMOUNT, amount => {
      return validateAmount(amount)
    })
    .typeError(FORM_FIELD_ERROR.TYPE_NUMBER)
    .positive(FORM_FIELD_ERROR.MUST_BE_POSITIVE)
    .integer(FORM_FIELD_ERROR.MUST_BE_INTEGER)
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
})

export const PAYMENT_FORM_SCHEMA = Yup.object().shape({
  amount: Yup
    .number()
    .test('check_min_max_amount', PAYMENT_FORM_ERRORS.AMOUNT, amount => {
      return validateAmount(amount)
    })
    .typeError(FORM_FIELD_ERROR.TYPE_NUMBER)
    .positive(FORM_FIELD_ERROR.MUST_BE_POSITIVE)
    .integer(FORM_FIELD_ERROR.MUST_BE_INTEGER)
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
  userEmail: Yup
    .string()
    .max(254, 'Слишком много символов')
    .matches(emailRegExp, { message: 'Введите корректный адрес электронной почты' })
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
  [NEW_PAYMENT_FORM_FIELDS.CARD_NUMBER]: Yup
    .string()
    .test('test-number', 'Некорректный номер карты', value => validateCard.number(value).isValid)
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
  [NEW_PAYMENT_FORM_FIELDS.EXPIRATION_DATE_MONTH]: Yup
    .string()
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
    .test('test-month', 'Некорректное значение', value => validateCard.expirationMonth(value).isValid),
  [NEW_PAYMENT_FORM_FIELDS.EXPIRATION_DATE_YEAR]: Yup
    .string()
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
    .test('test-year', 'Некорректное значение', value => validateCard.expirationYear(value).isValid),
  [NEW_PAYMENT_FORM_FIELDS.CVV]: Yup
    .string()
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
    .test('test-cvv', 'Некорректное значение', value => validateCard.cvv(value).isValid),
  [NEW_PAYMENT_FORM_FIELDS.OFFER]: Yup
    .bool()
    .required(FORM_FIELD_ERROR.MUST_BE_CHECKED)
    .oneOf([ true ], FORM_FIELD_ERROR.MUST_BE_CHECKED),
  [NEW_PAYMENT_FORM_FIELDS.PERSONAL_DATA]: Yup
    .bool()
    .required(FORM_FIELD_ERROR.MUST_BE_CHECKED)
    .oneOf([ true ], FORM_FIELD_ERROR.MUST_BE_CHECKED)
})

export const SECURE_FORM_SCHEMA = Yup.object().shape({
  code: Yup.number()
    .typeError(FORM_FIELD_ERROR.TYPE_NUMBER)
    .positive(FORM_FIELD_ERROR.MUST_BE_POSITIVE)
    .integer(FORM_FIELD_ERROR.MUST_BE_INTEGER)
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
})

export const COMPANY_PAYMENT_FORM_SCHEME = Yup.object().shape({
  amount: Yup
    .number()
    .test('check_min_max_amount', PAYMENT_FORM_ERRORS.AMOUNT, amount => {
      return validateAmount(amount)
    })
    .typeError(FORM_FIELD_ERROR.TYPE_NUMBER)
    .positive(FORM_FIELD_ERROR.MUST_BE_POSITIVE)
    .integer(FORM_FIELD_ERROR.MUST_BE_INTEGER)
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD),
  company: Yup
    .string()
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
    .max(254, 'Слишком много символов'),
  email: Yup
    .string()
    .max(254, 'Слишком много символов')
    .matches(emailRegExp, {
      message: 'Введите корректный адрес электронной почты'
    })
    .required(FORM_FIELD_ERROR.REQUIRED_FIELD)
})
