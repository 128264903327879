export const SCHOOLS_SEARCH_FORM_INITIAL = {
  query: ''
}

export const SCHOOLS_SEARCH_FORM_NAMES = {
  QUERY: 'query'
}

export const SCHOOLS_PER_PAGE = 16

export const REVIEWS_COUNT = 7
export const SCHOOL_PROJECTS_COUNT = 3
