import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { syncHistoryWithStore } from 'react-router-redux'

import configureStore from './core/configureStore'
import 'normalize.css/normalize.css'
import 'bootstrap-4-grid/css/grid.min.css'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import App from './core/App'
import agent from './config/methods'
import memoryOrBrowserHistory from './core/history'

const store = configureStore()

const history = syncHistoryWithStore(memoryOrBrowserHistory, store)

agent.loadToken()

ReactDOM.hydrate(
  <Provider store={store}>
    <ToastContainer />
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)
