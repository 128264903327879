import agent from '../../../config/methods'

export default function requestSuggestions (type, query, cb, parts) {
  agent.dadataPOST(type, query, parts)
    .then((response) => {
      cb(response.data.suggestions)
    })
    .catch((error) => {
      console.log('При получении подсказок произошла ошибка: ', error)
    })
}
