import agent from '../../config/methods'
import loadReduxTypes from '../../helpers/loadReduxTypes'
import { formatArrayImages } from '../utils/getObjectFields'

const GET_SCHOOLS = loadReduxTypes('GET_SCHOOLS')
const SCHOOLS_CLEAR_STATE = 'SCHOOLS_CLEAR_STATE'

function getSchoolsAction (perPage, searchQuery, isClearState) {
  return async function action (dispatch) {
    isClearState && dispatch(clearState())

    dispatch({ type: GET_SCHOOLS.REQUEST })

    const q = {
      order: 'desc',
      'sort_by': 'created_at',
      q: 'status__eq__published',
      'per_page': perPage
    }

    if (searchQuery && searchQuery.trim().length) {
      q.search_query = searchQuery.trim()
    }

    try {
      const response = await agent.GET('/school', q)
      dispatch({
        type: GET_SCHOOLS.SUCCESS,
        payload: {
          schools: formatArrayImages(response.data.data.items),
          total: response.data.data.total
        }
      })
    } catch (error) {
      dispatch({
        type: GET_SCHOOLS.FAILURE,
        payload: {
          error
        }
      })
    }
  }
}

function clearState () {
  return function action (dispatch) {
    dispatch({ type: SCHOOLS_CLEAR_STATE })
  }
}

export { getSchoolsAction, clearState }
