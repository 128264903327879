import * as Yup from 'yup'

export const OPEN_REGISTRATION_FORM = 'OPEN_REGISTRATION_FORM'
export const CLOSE_REGISTRATION_FORM = 'CLOSE_REGISTRATION_FORM'

export const OPEN_AUTHORIZATION_FORM = 'OPEN_AUTHORIZATION_FORM'
export const CLOSE_AUTHORIZATION_FORM = 'CLOSE_AUTHORIZATION_FORM'

export const OPEN_CHANGE_PASSWORD_FORM = 'OPEN_CHANGE_PASSWORD_FORM'
export const CLOSE_CHANGE_PASSWORD_FORM = 'CLOSE_CHANGE_PASSWORD_FORM'

export const OPEN_CONFIRM_PASSWORD_FORM = 'OPEN_CONFIRM_PASSWORD_FORM'
export const CLOSE_CONFIRM_PASSWORD_FORM = 'CLOSE_CONFIRM_PASSWORD_FORM'

export const OPEN_REGISTRATION_SUCCESS = 'OPEN_REGISTRATION_SUCCESS'
export const CLOSE_REGISTRATION_SUCCESS = 'CLOSE_REGISTRATION_SUCCESS'

export const OPEN_MOBILE_MENU = 'OPEN_MOBILE_MENU'
export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU'

export const RESET_ERROR_SUCCESS = 'RESET_ERROR_SUCCESS'

export const femaleIcons = [
  { id: '1', img: '/images/female-icons/female-icon-1.svg', name: '/female-icons/female-icon-1' },
  { id: '2', img: '/images/female-icons/female-icon-2.svg', name: '/female-icons/female-icon-2' },
  { id: '3', img: '/images/female-icons/female-icon-3.svg', name: '/female-icons/female-icon-3' },
  { id: '4', img: '/images/female-icons/female-icon-4.svg', name: '/female-icons/female-icon-4' },
  { id: '5', img: '/images/female-icons/female-icon-5.svg', name: '/female-icons/female-icon-5' },
  { id: '6', img: '/images/female-icons/female-icon-6.svg', name: '/female-icons/female-icon-6' }
]

export const maleIcons = [
  { id: '1', img: '/images/male-icons/male-icon-1.svg', name: '/male-icons/male-icon-1' },
  { id: '2', img: '/images/male-icons/male-icon-2.svg', name: '/male-icons/male-icon-2' },
  { id: '3', img: '/images/male-icons/male-icon-3.svg', name: '/male-icons/male-icon-3' },
  { id: '4', img: '/images/male-icons/male-icon-4.svg', name: '/male-icons/male-icon-4' },
  { id: '5', img: '/images/male-icons/male-icon-5.svg', name: '/male-icons/male-icon-5' },
  { id: '6', img: '/images/male-icons/male-icon-6.svg', name: '/male-icons/male-icon-6' }
]

export const CHANGE_PASSWORD_FORM_VALIDATION = Yup.object()
  .shape({
    email: Yup.string()
      .required('Данное поле должно быть заполнено.')
      .matches(/^\S+@\S+\.\S+$/, 'Введите корректный адрес электронной почты.')
  })
export const CHANGE_PASSWORD_FORM_INITIAL_VALUES = {
  email: ''
}

export const CHANGE_PASSWORD_FORM_FIELDS = {
  EMAIL: 'email'
}

export const CONFIRM_PASSWORD_FORM_VALIDATION = Yup.object()
  .shape({
    password: Yup.string()
      .required('Данное поле должно быть заполнено.')
      .oneOf([ Yup.ref('confirm_password'), null ], 'Пароли должны совпадать.')
      .test('len', 'Пароль должен состоять из минимум 8 символов', password => password && password.length >= 8),
    confirm_password: Yup.string()
      .required('Данное поле должно быть заполнено.')
      .test('len', 'Пароль должен состоять из минимум 8 символов.', password => password && password.length >= 8)
  })

export const CONFIRM_PASSWORD_FORM_INITIAL_VALUES = {
  password: '',
  confirm_password: ''
}

export const CONFIRM_PASSWORD_FORM_FIELDS = {
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm_password'
}
