import { toast } from 'react-toastify'

import agent from '../../config/methods'
import loadReduxTypes from '../../helpers/loadReduxTypes'

import {
  GET_SCHOOL_INFO_FAIL,
  GET_SCHOOL_INFO_REQUEST,
  GET_SCHOOL_INFO_SUCCESS,

  GET_SCHOOL_PROJECT_FAIL,
  GET_SCHOOL_PROJECT_REQUEST,
  GET_SCHOOL_PROJECT_SUCCESS

} from './School.constants'

const SCHOOL_REVIEW = loadReduxTypes('SCHOOL_REVIEW')
const POST_SCHOOL_REVIEW = loadReduxTypes('POST_SCHOOL_REVIEW')
const GET_PROJECTS_GOAL = loadReduxTypes('GET_PROJECTS_GOAL')
const SCHOOL_PAGE_CLEAR_STATE = 'SCHOOL_PAGE_CLEAR_STATE'

function getSchoolInfo (id) {
  return async function action (dispatch) {
    dispatch({ type: GET_SCHOOL_INFO_REQUEST })

    try {
      const response = await agent.GET(`/school/${id}`)
      dispatch({
        type: GET_SCHOOL_INFO_SUCCESS,
        payload: {
          school: response.data
        }
      })
    } catch (error) {
      dispatch({
        type: GET_SCHOOL_INFO_FAIL,
        payload: {
          error
        }
      })
    }
  }
}

function getSchoolProjects (id, perPage, isGetGoal = false) {
  return async function action (dispatch) {
    isGetGoal ? dispatch({ type: GET_PROJECTS_GOAL.REQUEST }) : dispatch({ type: GET_SCHOOL_PROJECT_REQUEST })

    try {
      const response = await agent.GET(`/school_projects/${id}`, {
        'q': [ `school__eq__${id}`, 'status__eq__published' ],
        'per_page': perPage,
        'sort_by': 'created_at'
      })
      if (isGetGoal) {
        dispatch({
          type: GET_PROJECTS_GOAL.SUCCESS,
          payload: {
            projectsGoal: response.data.data.projects
              .map(i => i.collected)
              .reduce((partialSum, a) => partialSum + a, 0)
          }
        })
      }

      dispatch({
        type: GET_SCHOOL_PROJECT_SUCCESS,
        payload: {
          schoolProjects: response.data
        }
      })
    } catch (error) {
      if (isGetGoal) {
        dispatch({
          type: GET_PROJECTS_GOAL.FAILURE,
          payload: {
            error
          }
        })
      }

      dispatch({
        type: GET_SCHOOL_PROJECT_FAIL,
        payload: {
          error
        }
      })
    }
  }
}

function getSchoolReviewAction (id, perPage) {
  return async function action (dispatch) {
    dispatch({ type: SCHOOL_REVIEW.REQUEST })

    try {
      const response = await agent.GET('/review', {
        'q': [ `school__eq__${id}` ],
        'per_page': perPage,
        'sort_by': 'created_at',
        'order': 'desc'
      })
      dispatch({
        type: SCHOOL_REVIEW.SUCCESS,
        payload: {
          reviews: response.data,
          total: response.data.total
        }
      })
    } catch (error) {
      dispatch({
        type: SCHOOL_REVIEW.FAILURE,
        payload: {
          error
        }
      })
    }
  }
}

function postReviewAction (params, successCallback) {
  return function action (dispatch) {
    dispatch({ type: POST_SCHOOL_REVIEW.REQUEST })

    agent.POST('/review', params)
      .then(() => {
        dispatch({
          type: POST_SCHOOL_REVIEW.SUCCESS
        })

        dispatch(getSchoolReviewAction(params.school, '7'))

        successCallback()

        toast.success('Отзыв успешно отправлен')
      })

      .catch((error) => {
        dispatch({
          type: POST_SCHOOL_REVIEW.FAILURE,
          payload: {
            error
          }
        })

        toast.success('Ошибка при создании отзыва')
      })
  }
}

function clearSchoolStateAction () {
  return function action (dispatch) {
    dispatch({ type: SCHOOL_PAGE_CLEAR_STATE })
  }
}

export {
  getSchoolInfo,
  getSchoolProjects,
  getSchoolReviewAction,
  postReviewAction,
  clearSchoolStateAction
}
