import React from 'react'
import classNames from 'classnames'

export const GenderIcon = ({ item, onSelect, selectedIcon, modifier, onChangeType }) => {
  const onSelectIcon = id => {
    onSelect(id)
  }

  const iconClassNames = classNames({
    'gender-icon': true,
    'gender-icon--selected': selectedIcon.length && selectedIcon === item.id,
    'gender-icon--blur': selectedIcon.length && selectedIcon !== item.id,
    'gender-icon--large': modifier === 'large'
  })

  const onHandleClick = (e) => {
    if (!modifier) {
      onSelectIcon(selectedIcon === e.target.id ? '' : e.target.id)
      onChangeType(selectedIcon === e.target.id ? null : item.name)
    }
  }

  return (
    <div
      id={item.id}
      className={iconClassNames}
      style={{
        backgroundImage: `url(${item.img})`
      }}
      onClick={onHandleClick}
    />
  )
}
