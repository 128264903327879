import React from 'react'
import PropTypes from 'prop-types'

import { Slider } from '../Slider'
import PartnerPerson from '../../components/PartnerPerson'
import { formatName } from '../../helpers/formatters'

const PartnersContainer = ({ partners }) => {
  if (!partners || partners.length === 0) {
    return (
      <React.Fragment>
        <h3 className="margin-bottom-45">Проект поддерживают</h3>
        <p>Стань первым, кто поддержит проект.</p>
      </React.Fragment>
    )
  }

  const partnersCount = partners.length

  const getNameString = (partner) => (
    formatName(partner.first_name, partner.last_name)
  )

  return (
    <Slider
      sliderId="partners"
      header={<h3 className="margin-bottom-45">Проект поддерживают</h3>}
      buttonsBlue
      count={partnersCount}
      className="margin-bottom-75 project__partners-slider"
    >
      {partners.map((partner, index) => (
        <PartnerPerson
          key={index}
          portrait={partner}
          name={getNameString(partner)}
          position={partner.position}
          company={partner.company}
        />
      ))
      }
    </Slider>
  )
}

PartnersContainer.propTypes = {
  partners: PropTypes.array
}

export default PartnersContainer
