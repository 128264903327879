import { toast } from 'react-toastify'

import agent from '../../config/methods'
import { CLOSE_AUTHORIZATION_FORM } from '../BaseLayout.constants'

import {
  USER_AUTH_REQUEST,
  USER_AUTH_SUCCESS,
  USER_AUTH_FAIL,
  LOGOUT_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  SET_PROFILE_AVATAR
} from './Authorization.constants'

function userAuthAction ({ email, password }) {
  return function action (dispatch) {
    dispatch({ type: USER_AUTH_REQUEST })

    agent.POST('/login', { email, password })
      .then((response) => {
        const { data: { token, user } } = response.data
        agent.setToken(token)

        if (window && window.carrotquest) {
          window.carrotquest.auth(user.id, user.carrot_quest_hash)
        }

        dispatch({ type: USER_AUTH_SUCCESS, payload: { data: user } })
        dispatch({ type: CLOSE_AUTHORIZATION_FORM })
      })

      .catch((error) => {
        dispatch({
          type: USER_AUTH_FAIL,
          payload: {
            error
          }
        })

        toast('Проверьте правильность введенных данных')
      })
  }
}

function logoutAction () {
  return function action (dispatch) {
    dispatch({ type: LOGOUT_REQUEST })
    try {
      dispatch({ type: LOGOUT_SUCCESS })
      agent.removeToken()
    } catch (e) {
      dispatch({ type: LOGOUT_FAIL })
    }
  }
}

function getUserAction () {
  return function action (dispatch) {
    dispatch({ type: GET_USER_REQUEST })

    agent.GET('/current_user')
      .then((response) => {
        const { data } = response.data

        dispatch({ type: GET_USER_SUCCESS, payload: { data: data.user } })

        if (data.user.avatar) {
          dispatch({
            type: SET_PROFILE_AVATAR,
            payload: {
              avatar: {
                filename: data.user.avatar
              }
            }
          })
        }
      })

      .catch((error) => {
        dispatch({
          type: GET_USER_FAIL,
          payload: {
            error
          }
        })

        logoutAction()
      })
  }
}

function userVkAuthAction (code) {
  return function action (dispatch) {
    dispatch({ type: USER_AUTH_REQUEST })

    agent.GET(`/auth/vk/?code=${code}`)
      .then((response) => {
        const { data: { token, user } } = response.data
        agent.setToken(token)
        dispatch({ type: USER_AUTH_SUCCESS, payload: { data: user } })
        dispatch({ type: CLOSE_AUTHORIZATION_FORM })
      })

      .catch((error) => {
        dispatch({
          type: USER_AUTH_FAIL,
          payload: {
            error
          }
        })

        toast('Проверьте правильность введенных данных')
      })
  }
}

function userFbAuthAction (code) {
  return function action (dispatch) {
    dispatch({ type: USER_AUTH_REQUEST })

    agent.GET('/auth/fb', { code: code })
      .then((response) => {
        const { data: { token, user } } = response.data
        agent.setToken(token)
        dispatch({ type: USER_AUTH_SUCCESS, payload: { data: user } })
        dispatch({ type: CLOSE_AUTHORIZATION_FORM })
      })

      .catch((error) => {
        dispatch({
          type: USER_AUTH_FAIL,
          payload: {
            error
          }
        })

        toast('Проверьте правильность введенных данных')
      })
  }
}

export { userAuthAction, userVkAuthAction, logoutAction, getUserAction, userFbAuthAction }
