import React from 'react'
import { MetaTags as MetaTagsComponent } from 'react-meta-tags'
import PropTypes from 'prop-types'

import { BASE_URL } from '../../constants/Api.constants'

const MetaTags = ({ type, title, description, keyWords, ogTitle, ogDescription, ogImage }) => {
  return (
    <MetaTagsComponent>
      {type && <meta property="og:type" content={type} />}
      {title && <title>{title}</title>}
      {title && <meta name="title" content={title} />}
      {description && <meta name="description" content={description} />}
      {keyWords && <meta name="keywords" content={keyWords} />}
      {(ogTitle || title) && <meta property="og:title" content={ogTitle || title} />}
      {(ogDescription || description) && <meta property="og:description" content={ogDescription || description} />}
      <meta property="og:image" content={ogImage || BASE_URL.replace(':8443', '') + '/media/logo.png'} />
    </MetaTagsComponent>
  )
}

MetaTags.propType = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string
}

export default MetaTags
