import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { API_IMG } from '../../constants/Api.constants'
import noUserPicture from '../../ge/no-userpic.svg'

export const ImageCard = ({ link, imageFilename, imageAlt, title, description, date, className }) => {
  return (
    <Link to={link} className={classNames(className, 'image-card')}>
      <div className="image-card__cover">
        <div className="image-card__head-gradient" />

        <img
          alt={imageAlt}
          className="image-card__image"
          src={imageFilename ? `${API_IMG}/${imageFilename}` : noUserPicture}
        />
      </div>

      <div className="image-card__body">
        {!!date && <p className="image-card__date">{date}</p>}
        {!!title && <p className="image-card__title">{title}</p>}

        <div className="image-card__description" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </Link>
  )
}
