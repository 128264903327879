import { createBrowserHistory, createMemoryHistory } from 'history'

import { isServer } from '../constants/Server.constants'

const history = isServer
  ? createMemoryHistory({
    initialEntries: [ '/' ]
  })
  : createBrowserHistory()

export default history
