import React from 'react'

import BaseLayout from '../../layouts/BaseLayout'

import ImageLoader from './Loader.svg'

const Loader = (isSmall = false) => {
  if (isSmall) {
    return (
      <div className="loader-container loader-container--fixed-width">
        <div className='loader'>
          <img src={ImageLoader} alt="Loader for loading user's data." />
        </div>
      </div>
    )
  }

  /* WIP: TODO Сделать графический лоудер вместо текстового. */
  return (
    <BaseLayout>
      <div className='row'>
        <div className='col-auto'>
          <span>Загрузка</span>
        </div>
      </div>
    </BaseLayout>
  )
}

export default Loader
