import React, { useState } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'

import { API_IMG } from '../../constants/Api.constants'
import { formatName, formatPicturePath } from '../../helpers/formatters'
import { logoutAction } from '../../layouts/authorization/Authorization.actions'
import { Button } from '../Button'
import { useResizeHook } from '../../hooks/useResize.hook'
import Loader from '../../components/Loader'
import { openAuthorizationForm } from '../../layouts/BaseLayout.actions'

const ProfileBlock = ({
  isAuthorized,
  isWhiteIcon,
  user,
  isAuthFetching
}) => {
  const history = useHistory()
  const [ isDropdownVisible, setIsDropdownVisible ] = useState(false)
  const [ isProfileIconMobile, setIsProfileIconMobile ] = useState(false)
  const dispatch = useDispatch()

  useResizeHook(() => {
    if (document.documentElement.clientWidth <= 768) {
      setIsProfileIconMobile(true)
    } else {
      setIsProfileIconMobile(false)
    }
  })

  const dropDownClassNames = classNames({
    'profile-block__profile-dropdown': true,
    'profile-block__profile-dropdown--visible': isDropdownVisible
  })

  const iconClassNames = classNames({
    'profile-block__dropdown-icon': true,
    'profile-block__dropdown-icon--raised': isDropdownVisible
  })

  const headerLockIcons = classNames({
    'header__profile-lock': true,
    'icon': true,
    'icon--lock': !isProfileIconMobile,
    'icon--lock-mobile': isProfileIconMobile,
    'icon--va-bottom': true
  })

  if (isAuthorized && user) {
    const {
      first_name: firstName,
      last_name: lastName,
      avatar_type: avatarType,
      avatar
    } = user

    const picture = (avatar && `${API_IMG}/${avatar.filename}`) || formatPicturePath(avatarType)
    const fullName = formatName(lastName, firstName)

    const onLogoutClick = () => {
      dispatch(logoutAction())
      if (!isAuthorized) {
        history.push('/')
      }
    }

    return (
      <React.Fragment>
        <div className="profile-block" onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
          <img
            alt={'Profile object'}
            className="profile-block__user-avatar"
            src={picture}
          />
          <div className={iconClassNames} />
          <div className={dropDownClassNames}>
            <div />
            <div className="profile-block__body">
              <div className="profile-block__username">{fullName}</div>
              <div className="profile-block__link">
                <Link to="/profile">Редактировать профиль</Link>
              </div>
              <div className="profile-block__link--colored">
                <span onClick={() => onLogoutClick()}>Выйти</span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  if (isAuthFetching) {
    return (
      <div className="profile-block__loader">
        <Loader isSmall />
      </div>
    )
  }

  return (
    <div className="header__profile-block">
      <Button
        label={<React.Fragment>
          <i className={headerLockIcons} />Вход
        </React.Fragment>}
        color={isWhiteIcon ? 'white' : 'gray'}
        noborder
        nobg
        className="header__profile-entry"
        onClick={() => dispatch(openAuthorizationForm())}
      />
    </div>
  )
}

export default ProfileBlock
