import React from 'react'

export const fCash = (value) => (
  <React.Fragment>
    { value.toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, '$1\u00a0') }&nbsp;₽
  </React.Fragment>
)

export function formatMoney (value) {
  return `${Math.round(value)} ₽`
}

export function formatDate (date) {
  const monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
  ]

  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  return `${day} ${monthNames[monthIndex]} ${year}`
}

export const formatName = (firstName, lastName) => {
  return `${firstName || ''} ${lastName || ''}`.trim()
}

export const formatPicturePath = (path) => {
  return path ? `/images/${path}.svg` : '/images/female-icons/no-userpic.svg'
}
