// export  SCHOOLS_SEARCH_FORM_INITIAL,
//   SCHOOLS_SEARCH_FORM_NAMES,
//   SCHOOLS_PER_PAGE

export const PARTNERS_SEARCH_INITIAL = {
  query: ''
}
export const PARTNERS_SEARCH_FORM = {
  QUERY: 'query'
}

export const PARTNERS_PER_PAGE = 16
