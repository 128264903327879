import { toast } from 'react-toastify'

import loadReduxTypes from '../../helpers/loadReduxTypes'
import agent from '../../config/methods'
import {
  formatFilesData,
  convertAvatarParamsToString,
  identifyUniqueItems
} from '../utils/formatFilesData'
import { formatArrayImages, formatUserAvatars } from '../utils/getObjectFields'

import { DEFAULT_PER_PAGE, OPEN_PAYMENT_FORM, CLOSE_PAYMENT_FORM } from './constants'

const SAVE_AMOUNT = loadReduxTypes('SAVE_AMOUNT')
const GET_PROJECT = loadReduxTypes('GET_PROJECT')
const PROJECT_FILES = loadReduxTypes('PROJECT_FILES')
const PROJECT_PARTNERS = loadReduxTypes('PROJECT_PARTNERS')
const PROJECT_COMMENTS = loadReduxTypes('PROJECT_COMMENTS')
const POST_PROJECT_COMMENT = loadReduxTypes('POST_PROJECT_COMMENT')
const CLEAR_PROJECT_DATA = loadReduxTypes('CLEAR_PROJECT_DATA')
const GET_PROJECT_VENDORS = loadReduxTypes('GET_PROJECT_VENDORS')
const GET_PARTNERS_AVATAR_LINKS = loadReduxTypes('GET_PARTNERS_AVATAR_LINKS')

function saveAmountAction (amount) {
  return function action (dispatch) {
    try {
      dispatch({ type: SAVE_AMOUNT.REQUEST, payload: { amount } })
    } catch (e) {
      console.error('error with save amount', e)
    }
  }
}

function onPaymentFormAction (state) {
  return function action (dispatch) {
    state ? dispatch({ type: OPEN_PAYMENT_FORM }) : dispatch({ type: CLOSE_PAYMENT_FORM })
  }
}

function getProjectAction (id) {
  return async function action (dispatch) {
    dispatch({ type: GET_PROJECT.REQUEST })

    try {
      const response = await agent.GET(`/project/${id}`)
      const project = response.data.data
      dispatch({
        type: GET_PROJECT.SUCCESS,
        payload: {
          project
        }
      })
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error('Проект по указанному адресу не найден.')
      }

      dispatch({
        type: GET_PROJECT.FAILURE,
        payload: {
          error
        }
      })

      if (error.response.status > 300) {
        throw new Error('not-found')
      }
    }
  }
}

function getProjectFiles (id) {
  return function action (dispatch) {
    const params = { 'q': [ `project__eq__${id}` ] }
    dispatch({ type: PROJECT_FILES.REQUEST })

    agent.GET('/projectfile', params)
      .then((response) => {
        const projectFiles = formatFilesData(response.data.data.items)
        dispatch({
          type: PROJECT_FILES.SUCCESS,
          payload: {
            projectFiles
          }
        })
      })

      .catch((error) => {
        dispatch({
          type: PROJECT_FILES.FAILURE,
          payload: {
            error
          }
        })
      })
  }
}

function getProjectPartnersAction (id) {
  return function action (dispatch) {
    const params = { 'q': [ `project__eq__${id}` ] }

    dispatch({
      type: PROJECT_PARTNERS.REQUEST
    })

    agent.GET('/donation', params)
      .then((donations) => {
        const avatarNumbers = convertAvatarParamsToString(donations)

        if (avatarNumbers.length !== 0) {
          dispatch({ type: GET_PARTNERS_AVATAR_LINKS.REQUEST })

          agent.GET('/file', { 'q': [ `id__in__${avatarNumbers}` ] })
            .then((links) => {
              const uniqueDonations = identifyUniqueItems(donations)

              dispatch({ type: GET_PARTNERS_AVATAR_LINKS.SUCCESS })
              dispatch({
                type: PROJECT_PARTNERS.SUCCESS,
                payload: {
                  partners: {
                    data: {
                      items: formatUserAvatars(links.data.data.items, Object.values(uniqueDonations))
                    }
                  }
                }
              })
            })

            .catch((error) => {
              dispatch({
                type: PROJECT_PARTNERS.FAILURE,
                payload: {
                  error
                }
              })
            })
        } else {
          dispatch({
            type: PROJECT_PARTNERS.SUCCESS,
            payload: {
              partners: donations.data
            }
          })
        }
      })

      .catch((error) => {
        dispatch({
          type: PROJECT_PARTNERS.FAILURE,
          payload: {
            error
          }
        })
      })
  }
}

function getProjectCommentsAction (id, perPage = DEFAULT_PER_PAGE) {
  return async function action (dispatch) {
    const params = { 'q': [ `project__eq__${id}` ], 'per_page': perPage, 'sort_by': 'created_at', 'order': 'desc' }
    dispatch({ type: PROJECT_COMMENTS.REQUEST })

    try {
      const response = await agent.GET('/comment', params)
      dispatch({
        type: PROJECT_COMMENTS.SUCCESS,
        payload: {
          comments: response.data
        }
      })
    } catch (error) {
      dispatch({
        type: PROJECT_COMMENTS.FAILURE,
        payload: {
          error
        }
      })
    }
  }
}

function postProjectCommentAction (data, successCallBack) {
  return function action (dispatch) {
    dispatch({ type: POST_PROJECT_COMMENT.REQUEST })

    agent.POST('/comment', data)
      .then((response) => {
        dispatch({
          type: POST_PROJECT_COMMENT.SUCCESS,
          payload: {
            comments: response.data
          }
        })
        successCallBack()
        dispatch(getProjectCommentsAction(data.project))
        toast.success('Комментарий успешно оставлен')
      })

      .catch((error) => {
        dispatch({
          type: POST_PROJECT_COMMENT.FAILURE,
          payload: {
            error
          }
        })
        toast.error('Возникла ошибка при отправке')
      })
  }
}

function clearProjectData () {
  return function action (dispatch) {
    dispatch({
      type: CLEAR_PROJECT_DATA.SUCCESS
    })
  }
}

function getProjectVendorsAction (projectId) {
  // WIP: This method needs for testing (showing uploaded data).
  return function action (dispatch) {
    dispatch({ type: GET_PROJECT_VENDORS.REQUEST })

    agent.GET('/vendor', { 'q': [ `project__eq__${projectId}` ] })
      .then((response) => {
        dispatch({
          type: GET_PROJECT_VENDORS.SUCCESS,
          payload: formatArrayImages(response.data.data.items)
        })
      })

      .catch((error) => {
        dispatch({
          type: GET_PROJECT_VENDORS.FAILURE,
          payload: {
            error
          }
        })
      })
  }
}

export {
  saveAmountAction,
  getProjectAction,
  getProjectFiles,
  getProjectPartnersAction,
  clearProjectData,
  getProjectCommentsAction,
  postProjectCommentAction,
  getProjectVendorsAction,
  onPaymentFormAction
}
