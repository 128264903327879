import React from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'

import { Button } from '../../components/Button'
import { CheckBoxField, EmailField } from '../../components/Input'
import { setSubscription } from '../../pages/Index/Index.actions'

import { SUBSCRIBE_FORM } from './constants'
import { subscribeFormValidation } from './utils/validation'

const SubscribeForm = ({ setSubscription }) => (
  <Formik
    initialValues={{
      email: '',
      terms: false
    }}
    enableReinitialize
    onSubmit={(values, actions) => {
      setSubscription(values.email)
      actions.resetForm()
    }}
    validationSchema={subscribeFormValidation()}
    render={(f) => (
      <form onSubmit={f.handleSubmit} className="subscribe-form">
        <div className="subscribe-form__input">
          <EmailField
            name={SUBSCRIBE_FORM.EMAIL}
            value={f.values.email}
            errors={f.errors.email && f.touched.email ? f.errors.email : null}
            onChange={f.handleChange}
            onBlur={f.handleBlur}
            required
          />
          <div className="subscribe-form__button">
            <Button
              label="Подписаться"
              type="submit"
              width="100%"
              color={!f.isValid ? 'gray' : 'orange'}
              disabled={!f.isValid}
            />
          </div>
        </div>
        <CheckBoxField
          id="auth_checkbox"
          name={SUBSCRIBE_FORM.TERMS}
          label="Я согласен с обработкой персональных данных"
          onChange={f.handleChange}
          remember
          cnLabel="subscribe-form__check-label"
          cnInput="subscribe-form__check-input"
        />
      </form>
    )}
  />
)

const mapDispatchToProps = dispatch => ({
  setSubscription: (email) => dispatch(setSubscription(email))
})

export default connect(null, mapDispatchToProps)(SubscribeForm)
