
import loadReduxTypes from '../../helpers/loadReduxTypes'
import agent from '../../config/methods'

const GET_EXPERTS = loadReduxTypes('GET_EXPERTS')
const EXPERTS_CLEAR = loadReduxTypes('EXPERTS_CLEAR')

function getExpertsAction (count) {
  return async function action (dispatch) {
    dispatch({ type: GET_EXPERTS.REQUEST })

    try {
      const response = await agent.GET('/experts', { 'per_page': count })
      dispatch({
        type: GET_EXPERTS.SUCCESS,
        payload: {
          experts: response.data.data.items,
          total: response.data.data.total
        }
      })
    } catch (error) {
      dispatch({
        type: GET_EXPERTS.FAILURE,
        payload: error
      })
    }
  }
}

function clearState () {
  return function action (dispatch) {
    dispatch({ type: EXPERTS_CLEAR.SUCCESS })
  }
}

export {
  getExpertsAction,
  clearState
}
