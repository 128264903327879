import { PUBLIC_ID, API_ORIGIN } from '../../../constants/Api.constants'
import { sendDonate } from '../Payment.service'

export const sendDonation = (projectId, title, supportRate, user, showSuccessMessage, projectAmount) => {
  const guestUser = {
    id: ''
  }

  const userDetails = user || guestUser
  const invoiceId = Math.round((Math.random() * 36 ** 5))

  const widget = new window.cp.CloudPayments()
  widget.charge({
    publicId: PUBLIC_ID,
    description: title,
    amount: supportRate,
    currency: 'RUB',
    invoiceId,
    accountId: userDetails.email || '',
    skin: 'mini',
    data: {
      user_id: userDetails.id || null,
      project_id: parseInt(projectId, 10),
      amount: parseInt(projectAmount, 10)
    }
  },
  function (a, b) {
    showSuccessMessage()
  },
  function (reason, options) {
  })
}

export const openSecure = (data) => {
  const secureForm = document.createElement('form')
  const inputPaReq = document.createElement('input')
  const inputMD = document.createElement('input')
  const inputTermUrl = document.createElement('input')

  secureForm.setAttribute('action', data.acs_url)
  secureForm.setAttribute('method', 'POST')

  inputPaReq.setAttribute('name', 'PaReq')
  inputMD.setAttribute('name', 'MD')
  inputTermUrl.setAttribute('name', 'TermUrl')

  inputPaReq.setAttribute('type', 'hidden')
  inputMD.setAttribute('type', 'hidden')
  inputTermUrl.setAttribute('type', 'hidden')

  inputPaReq.setAttribute('value', data.pa_req)
  inputMD.setAttribute('value', data.transaction_id)
  inputTermUrl.setAttribute('value', `${API_ORIGIN}/cloudpayments/confirm_3dsecure/`)

  secureForm.append(inputPaReq)
  secureForm.append(inputMD)
  secureForm.append(inputTermUrl)

  document.body.append(secureForm)
  secureForm.submit()
  document.body.removeChild(secureForm)
}

export const createCryptogram = (
  values,
  email,
  projectID,
  amount,
  amountProject,
  amountService,
  openPaymentCheck,
  setErrors,
  isSubscribePayment,
  successCardPayment
) => {
  const form = document.createElement('form')
  values.forEach(item => {
    const input = document.createElement('input')
    input.setAttribute('data-cp', item.attr)
    if (item.attr === 'name') {
      input.value = 'Cardholder name'
    } else {
      input.value = item.value
    }
    form.append(input)
  })

  const checkout = new window.cp.Checkout(
    PUBLIC_ID,
    form
  )
  const cryptogram = checkout.createCryptogramPacket()

  const data = {
    cryptogram: cryptogram.packet,
    amount: amountProject,
    service_fee: amountService,
    name: 'Cardholder name',
    payment_type: isSubscribePayment ? 'monthly' : 'one-time',
    email: !email ? undefined : email
  }

  sendDonate(projectID, data, openSecure, openPaymentCheck, setErrors, successCardPayment)
}
