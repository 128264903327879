import loadReduxTypes from '../../helpers/loadReduxTypes'
import agent from '../../config/methods'
// WIP import { identifyUniqueItems } from '../utils/formatFilesData'

const PARTNERS = loadReduxTypes('PARTNERS')
const PARTNERS_CLEAR = 'PARTNERS_CLEAR'

function getPartners (perPage) {
  return async function action (dispatch) {
    dispatch({ type: PARTNERS.REQUEST })

    const data = {
      per_page: perPage,
      'sort_by': 'created_at',
      'order': 'desc'
    }

    try {
      const response = await agent.GET('/donators', { ...data })
      dispatch({
        type: PARTNERS.SUCCESS,
        payload: {
          partners: response.data
        }
      })
    } catch (error) {
      dispatch({
        type: PARTNERS.FAILURE,
        payload: {
          error
        }
      })
    }
  }
}

function clearPartnersState () {
  return function action (dispatch) {
    dispatch({ type: PARTNERS_CLEAR })
  }
}

export {
  getPartners,
  clearPartnersState
}
