export const PROFILE_FORM_INITIAL_VALUES = {
  avatar: [],
  email: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  company: '',
  position: ''
}

export const PROFILE_FORM_FIELDS = {
  AVATAR: 'avatar',
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  MIDDLE_NAME: 'middle_name',
  LAST_NAME: 'last_name',
  COMPANY: 'company',
  POSITION: 'position'
}

export const PROFILE_SAVE_REQUEST = 'PROFILE_SAVE_REQUEST'
export const PROFILE_SAVE_SUCCESS = 'PROFILE_SAVE_SUCCESS'
export const PROFILE_SAVE_FAILURE = 'PROFILE_SAVE_FAILURE'

export const getInitialValues = user => {
  return {
    avatar: user && user.avatar ? user.avatar : PROFILE_FORM_INITIAL_VALUES.avatar,
    email: user && user.email ? user.email : PROFILE_FORM_INITIAL_VALUES.email,
    first_name: user && user.first_name ? user.first_name : PROFILE_FORM_INITIAL_VALUES.first_name,
    middle_name: user && user.middle_name ? user.middle_name : PROFILE_FORM_INITIAL_VALUES.middle_name,
    last_name: user && user.last_name ? user.last_name : PROFILE_FORM_INITIAL_VALUES.last_name,
    company: user && user.company ? user.company : PROFILE_FORM_INITIAL_VALUES.company,
    position: user && user.position ? user.position : PROFILE_FORM_INITIAL_VALUES.position
  }
}
