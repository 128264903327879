import React, { useRef } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import Loader from '../Loader'
import LeftArrow from '../../ge/arrow-left-black.svg'
import RightArrow from '../../ge/arrow-right-black.svg'

/* Mobile version. Only for pages Index & School pages. */
/* Mobile & Desktop version for ProjectPage. */

const CardSlider = ({
  slidesToShow,
  isShowLoader,
  slidesToScroll,
  children,
  count,
  responsive = [],
  headerNode = null
}) => {
  const sliderRef = useRef(null)

  const onSlickClick = (action) => {
    const { slickNext, slickPrev } = sliderRef.current
    action === 'next' ? slickNext() : slickPrev()
  }

  return (
    <React.Fragment>
      <div className="slider__top-section">
        {headerNode}
        <div className="slider__arrows">
          <img src={LeftArrow} alt="left arrow" onClick={() => onSlickClick('prev')} />
          <img src={RightArrow} alt="left arrow" onClick={() => onSlickClick('next')} />
        </div>
      </div>

      <div className="slider__active-project">
        <div className="slides-item__active-project">
          {isShowLoader && <Loader isSmall />}
          {!isShowLoader && <Slider
            dots
            infinite={false}
            speed={500}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            count={count}
            responsive={responsive}
            ref={sliderRef}>
            {children}
          </Slider>}
        </div>
      </div>
    </React.Fragment>
  )
}

export default CardSlider

CardSlider.propTypes = {
  children: PropTypes.node,
  count: PropTypes.number,
  headerNode: PropTypes.node,
  isShowLoader: PropTypes.bool,
  responsive: PropTypes.array,
  slidesToScroll: PropTypes.number,
  slidesToShow: PropTypes.number
}
