import * as Yup from 'yup'

export const getRegistrationValidation = () => {
  return (
    Yup.object().shape({
      first_name: Yup.string()
        .required('Данное поле должно быть заполнено.')
        .matches(/^[a-zA-Zа-яёА-ЯЁ'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, 'Имя должно состоять из русских или английских символов.'),
      last_name: Yup.string()
        .required('Данное поле должно быть заполнено.')
        .matches(/^[a-zA-Zа-яёА-ЯЁ'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, 'Фамилия должна состоять из русских или английских символов.'),
      email: Yup.string()
        .required('Данное поле должно быть заполнено.')
        .matches(/^\S+@\S+\.\S+$/, 'Введите корректный адрес электронной почты.'),
      password: Yup.string()
        .required('Данное поле должно быть заполнено.')
        .oneOf([ Yup.ref('confirm_password'), null ], 'Пароли должны совпадать.')
        .test('len', 'Пароль должен состоять из минимум 8 символов', password => password && password.length >= 8),
      confirm_password: Yup.string()
        .required('Данное поле должно быть заполнено.')
        .test('len', 'Пароль должен состоять из минимум 8 символов.', password => password && password.length >= 8),
      offer_checkbox: Yup.bool().required('Требуется подтвердить').oneOf([ true ], 'Field must be checked'),
      personal_checkbox: Yup.bool().required('Требуется подтвердить').oneOf([ true ], 'Field must be checked')
    })
  )
}

export const checkEmptyFields = (firstName, lastName, gender, avatarType, fields = {}) => {
  if (firstName || (firstName && firstName.trim().length !== 0)) {
    fields.first_name = firstName
  }

  if (lastName || (lastName && lastName.trim().length !== 0)) {
    fields.last_name = lastName
  }

  if (gender) {
    fields.gender = gender === 'female' ? 'f' : 'm'
  }

  if (avatarType) {
    fields.avatar_type = avatarType
  }

  return fields
}
