import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'

import { FACEBOOK_PIXEL_ID } from '../constants/Api.constants'
import { getUserAction } from '../layouts/authorization/Authorization.actions'
import Loader from '../components/Loader'
import PrivateRoute from '../containers/PrivateRoute'
import NotFoundPage from '../pages/NotFound/NotFound.page'
import ScrollToTop from '../components/ScrollToTop'

import routesList from './routesList'

export const App = () => {
  const dispatch = useDispatch()
  const { auth: { isAuthFetching, isAuthorized } } = useSelector(state => state)

  useEffect(() => {
    ReactPixel.init(FACEBOOK_PIXEL_ID)
    ReactPixel.track('PageView')

    if (isAuthorized) {
      dispatch(getUserAction())
    }
  }, [ dispatch, isAuthorized ])

  if (isAuthFetching) {
    return (
      <div className="main-loader">
        <Loader />
      </div>
    )
  }

  return (
    <ScrollToTop>
      <Switch>
        {routesList.map((route, index) => (
          route.private ? <PrivateRoute key={index} path={route.path} component={route.component} exact={route.exact} /> : <Route key={index} path={route.path} component={route.component} exact={route.exact} />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </ScrollToTop>
  )
}

export default App
