import axios from 'axios'

import agent from './methods'

const HTTP = axios.create()

HTTP.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const status = error.response ? error.response.status : 408
  if (status >= 500) {
    console.error(error)
  }
  if (status === 401) {
    agent.removeToken()
    console.error(error)
  }
  return Promise.reject(error)
})

export default HTTP
