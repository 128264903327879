import loadReduxTypes from '../../helpers/loadReduxTypes'
import { SET_PROFILE_AVATAR } from '../../layouts/authorization/Authorization.constants'

import {
  PROFILE_SAVE_FAILURE,
  PROFILE_SAVE_REQUEST,
  PROFILE_SAVE_SUCCESS
} from './Profile.constants'

const UPLOAD_AVATAR = loadReduxTypes('UPLOAD_AVATAR')
const REMOVE_AVATAR = loadReduxTypes('REMOVE_AVATAR')

const initialState = {
  avatar: null,
  error: null,
  isFetching: false,
  isRemoveAvatar: false,
  isGetAvatar: false
}

export default function profileReducer (state = initialState, { type, payload }) {
  switch (type) {
  case PROFILE_SAVE_REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case PROFILE_SAVE_SUCCESS: {
    return {
      ...state,
      isFetching: false
    }
  }

  case PROFILE_SAVE_FAILURE: {
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }
  }

  case UPLOAD_AVATAR.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case UPLOAD_AVATAR.SUCCESS: {
    return {
      ...state,
      avatar: payload.avatar,
      isFetching: false
    }
  }

  case SET_PROFILE_AVATAR: {
    return {
      ...state,
      avatar: payload.avatar.filename,
      isFetching: false
    }
  }

  case UPLOAD_AVATAR.FAILURE: {
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }
  }

  case REMOVE_AVATAR.REQUEST: {
    return {
      ...state,
      avatar: initialState.avatar,
      isRemoveAvatar: true
    }
  }

  default: return state
  }
}
