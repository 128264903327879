import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import { isServer } from '../constants/Server.constants'

import rootReducer from './rootReducer'

export default function configureStore () {
  const initialState = isServer ? {} : window.__PRELOADED_STATE__

  if (!isServer) {
    delete window.__PRELOADED_STATE__
  }

  const middlewares = [ thunk ]

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger())
  }

  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middlewares)
  )
}
