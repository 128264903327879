import loadReduxTypes from '../../helpers/loadReduxTypes'
import agent from '../../config/methods'

export const PLATFORM_PAGE_MEMBERS = loadReduxTypes('PLATFORM_PAGE_MEMBERS')
export const PLATFORM_PAGE_NEWS = loadReduxTypes('PLATFORM_PAGE_NEWS')
export const PLATFORM_PAGE_VOLUNTEERS = loadReduxTypes('PLATFORM_PAGE_VOLUNTEERS')

const NEWS_COUNT = 4

export function getPlatformMembers () {
  return function action (dispatch) {
    dispatch({ type: PLATFORM_PAGE_MEMBERS.REQUEST })

    agent.GET('/team-members')
      .then((response) => {
        dispatch({
          type: PLATFORM_PAGE_MEMBERS.SUCCESS,
          payload: response.data
        })
      })

      .catch((error) => {
        dispatch({
          type: PLATFORM_PAGE_MEMBERS.FAILURE,
          payload: {
            error
          }
        })
      })
  }
}

export function getPlatformNews () {
  return async function action (dispatch) {
    dispatch({ type: PLATFORM_PAGE_NEWS.REQUEST })

    try {
      const response = await agent.GET('/news', {
        'per_page': NEWS_COUNT,
        'sort_by': 'created_at',
        'order': 'desc'
      })
      dispatch({
        type: PLATFORM_PAGE_NEWS.SUCCESS,
        payload: {
          news: response.data
        }
      })
    } catch (error) {
      dispatch({
        type: PLATFORM_PAGE_NEWS.FAILURE,
        payload: {
          error
        }
      })
    }
  }
}

export function getPlatformVolunteers () {
  return function action (dispatch) {
    dispatch({ type: PLATFORM_PAGE_VOLUNTEERS.REQUEST })

    agent.GET('/volunteers')
      .then((response) => {
        dispatch({
          type: PLATFORM_PAGE_VOLUNTEERS.SUCCESS,
          payload: response.data
        })
      })

      .catch((error) => {
        dispatch({
          type: PLATFORM_PAGE_VOLUNTEERS.FAILURE,
          payload: {
            error
          }
        })
      })
  }
}
