import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import BaseLayout from '../../layouts/BaseLayout'
import { Button } from '../../components/Button'
import SearchInput from '../../components/SearchInput'
import ProjectCard from '../../components/ProjectCard'
import Loader from '../../components/Loader'
import MetaTags from '../../components/MetaTags'
import { useDebounce } from '../../hooks/useDebounce.hook'
import { META_TAGS_LIST } from '../../constants/Meta.constants'
import PaymentFormContainer from '../../components/PaymentFormContainer/PaymentFormContainer'
import SortIcon from '../../ge/sort-icon-1.svg'

import { SEARCH_PROJECT_FORM, SEARCH_PROJECT_INITIAL, PROJECTS_PER_PAGE, SORT_OPTIONS } from './SearchProject.constants'
import { getProjectList, clearState } from './SearchProject.actions'

const fetchProjects = (query, projectsCount, dispatch, sortParams) => {
  dispatch(getProjectList(query, projectsCount, sortParams))
}

const SearchProjectsPage = () => {
  const history = useHistory()
  const [ projectsCount, setProjectCount ] = useState(PROJECTS_PER_PAGE)

  const [ query, setQuery ] = useState('')

  const [ sort, setSort ] = useState(true)
  const [ selectedProject, setSelectedProject ] = useState()
  const [ isSuccessPaymentModal, setIsSuccessPaymentModal ] = useState()

  const sortString = sort ? 'От поздних к ранним' : 'От ранних к поздним'

  const dispatch = useDispatch()

  const {
    projects: { projects, isFetching },
    project: { isPaymentFormVisible }
  } = useSelector(state => state)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search).get('q')
    if (query) {
      setQuery(query)
    }

    return () => {
      dispatch(clearState())
      setQuery('')
    }
  }, [ dispatch ])

  useDebounce(fetchProjects, 600, [ query.trim(), projectsCount, dispatch, sort === true ? SORT_OPTIONS[0].queryParams : SORT_OPTIONS[1].queryParams ])

  const totalData = (projects && projects.data.total) || 0

  const onClearClick = () => {
    history.push('projects?type=all')
    setQuery('')
    setProjectCount(PROJECTS_PER_PAGE)
  }

  return (
    <BaseLayout>
      <MetaTags
        title={META_TAGS_LIST.SEARCH_TITLE}
        description={META_TAGS_LIST.SEARCH_DESCRIPTION}
      />
      <div className="container-app">
        <div className="container-fluid">
          <h1>Поиск проектов</h1>
          <Formik
            initialValues={SEARCH_PROJECT_INITIAL}
            render={f => (
              <form onSubmit={f.handleSubmit} className="search-project-form">
                <SearchInput
                  name={SEARCH_PROJECT_FORM.QUERY}
                  value={query}
                  onChange={e => {
                    f.handleChange(e)
                    f.setFieldValue(SEARCH_PROJECT_FORM.QUERY, e.target.value)
                    setQuery(e.target.value)
                    setProjectCount(PROJECTS_PER_PAGE)
                  }}
                  onBlur={f.handleBlur}
                  isInputLarge
                  isShowCancelButton={query.length !== 0}
                  onClear={onClearClick}
                />
                <div
                  className="search-project-form__right-section"
                  onClick={() => setSort(!sort)}>
                  <span className="search-project-form__sort-info">{sortString}</span>
                  <img
                    alt={sortString}
                    src={SortIcon}
                  />
                </div>
              </form>
            )}
          />

          {(!isFetching && projects && projects.data.items.length === 0) &&
            <div className="search-project-form__projects-container row">
              <div className="search-project-form__search-status">
                <p>Извините, но по вашему запросу мы ничего не нашли.</p>
              </div>
            </div>
          }

          <div className="search-project-form__projects-container row">
            {projects && projects.data.items.map(project => (
              <div className="col-6 col-xl-3 px-1" key={`col_${project.id}`}>
                <ProjectCard
                  openPaymentForm={() => setSelectedProject(project)}
                  key={`project_${project.id}`}
                  title={project.title}
                  link={`/projects/${project.id}`}
                  stage={project.stage}
                  collected={project.collected}
                  goal={project.goal}
                  className="search-project-form__project-card"
                  logo={project.logo}
                  description={project.description}
                  balance={project.balance}
                />
              </div>
            ))}
          </div>

          {(isFetching &&
            <div className="search-project-form__loader-wrapper">
              <Loader />
            </div>) ||
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-auto col">
                {projectsCount < totalData &&
                  <Button
                    large
                    nobg
                    label="Показать еще"
                    onClick={() => {
                      setProjectCount(projectsCount + PROJECTS_PER_PAGE)
                    }}
                    width="100%"
                    className="btn__show-more"
                  />}
              </div>
            </div>
          }
        </div>
      </div>
      <PaymentFormContainer
        isPaymentFormVisible={isPaymentFormVisible}
        isSuccessPaymentModal={isSuccessPaymentModal}
        setIsSuccessPaymentModal={setIsSuccessPaymentModal}
        selectedProject={selectedProject}
      />
    </BaseLayout>
  )
}

export default SearchProjectsPage
