import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BaseLayout from '../../layouts/BaseLayout'
import { PartnersLogoGrid } from '../../components/Partners'
import MetaTags from '../../components/MetaTags'
import { META_TAGS_LIST } from '../../constants/Meta.constants'

import { WORK_INFO } from './About.constants'
import { getAboutPageVendorsAction } from './About.actions'

const AboutPage = () => {
  const dispatch = useDispatch()

  const { aboutPage: {
    aboutPageVendors,
    isFetching
  } } = useSelector(state => state)

  useEffect(() => {
    dispatch(getAboutPageVendorsAction())
  }, [ dispatch ])

  const vendors = (!isFetching && aboutPageVendors) ? aboutPageVendors.map(i => ({ logo: i.logo, link: i.link })) : []

  return (
    <React.Fragment>
      <BaseLayout>
        <MetaTags
          title={META_TAGS_LIST.ABOUT_TITLE}
          description={META_TAGS_LIST.ABOUT_DESCRIPTION}
        />
        <div className="container-app--about">
          <div className="container-fluid">
            <div className="welcome-section">
              <div className="welcome-section__content">
                <h2 className="welcome-section__title">«I Love School» - это платформа для реализации образовательных проектов на всей территории России</h2>
              </div>
            </div>

            <div className="platform-section  about-section">
              <div className="platform-section__container about-section__container">
                <div className="about-card about-card--blue">
                  <p className="about-card__text">На платформе «ILoveSchool» учителя и ученики могут заявить широкой аудитории о своих проектах, а партнеры - выбрать проекты и следить за ходом их выполнения
                  </p>
                </div>
                <div className="platform-section__content about-section__content">
                  <div className="platform-section__column-left">
                    <p className="platform-section__title">Мы убеждены</p>
                  </div>
                  <div className="platform-section__column-right">
                    <p className="platform-section__text">что образование - это стержень любой личности, и от того,
                насколько качественным оно будет, зависит очень многое. Успешно учиться, с интересом и пользой
                проводить время, находить и развивать свои увлечения, вступать в самостоятельную жизнь – важные
                компоненты всего образовательного процесса. </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="cover-section cover-section__image-head" />

            <div className="platform-section  about-section">
              <div className="platform-section__container about-section__container">
                <div className="about-card about-card--green align-items-center">
                  <p className="about-card__text">«ILoveSchool» – удобный и простой инструмент, который уже сегодня делает
              образование наших детей в тысячах школ по всей стране эффективным и современным</p>
                </div>
                <div className="platform-section__content about-section__content">
                  <div className="platform-section__column-left">
                    <p className="platform-section__title">«ILoveSchool»</p>
                  </div>
                  <div className="platform-section__column-right">
                    <p className="platform-section__text">широкий круг преподавателей и учеников получают доступ к
                современным практикам, инструментарию и опыту, которые необходимы для успешного и эффективного обучения.
                Учебные пособия, оборудование, техническое оснащение и многое другое – вот те проекты, которые совместно
                с партнерами реализуются нами в настоящее время.</p>
                    <p className="platform-section__text">Помимо поиска и привлечения партнеров огромное значение для нас имеет прозрачность реализации проектов. Мы гарантируем отсутствие нецелевого использования средств - для этого команда «ILoveSchool» берет на себя контроль всех этапов проектной деятельности.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="cover-section cover-section__image-body" />

            <div className="platform-section  about-section">
              <div className="platform-section__container about-section__container">
                <div className="about-card about-card--orange align-items-center">
                  <p className="about-card__text">Это удобное и простое решение для реализации инициатив образовательных учреждений в области развития инфраструктуры системы образования
                  </p>
                </div>
              </div>
            </div>

            <div className="work-section">
              <div className="work-section__container about-section__container">
                <h3 className="work-section__title about-section__title">Как это работает</h3>
                <div className="work-section__content row flex-wrap">
                  { WORK_INFO.map(item => (
                    <div
                      key={item.id}
                      className="work-section__item"
                    >
                      <p className="work-section__item-number color-orange-1">{item.id}</p>
                      <p className="work-section__item-title">{item.title}</p>
                      <p className="work-section__item-text">{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="partners-section">
              <div className="partners-section about-section__container">
                <h3 className="partners-section__title about-section__title">Партнеры</h3>
                <div className="partners">
                  <PartnersLogoGrid
                    partners={vendors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseLayout>
    </React.Fragment>
  )
}

export default AboutPage
