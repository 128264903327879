import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const useStateClearance = (clearState) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearState())
    return () => {
      dispatch(clearState())
    }
  }, [ clearState, dispatch ])
}
