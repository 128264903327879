import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import BaseLayout from '../../layouts/BaseLayout'
import { Button } from '../../components/Button'
import SearchInput from '../../components/SearchInput'
import SubscribeForm from '../../forms/SubscribeForm'
import { useResizeHook } from '../../hooks/useResize.hook'
import ActionSection from '../../components/ActionSection'
import MetaTags from '../../components/MetaTags'
import ProjectsContainer from '../../containers/ProjectsContainer'
import { STAGE_TYPES } from '../Project/constants'
import { META_TAGS_LIST } from '../../constants/Meta.constants'
import { userFbAuthAction, userVkAuthAction } from '../../layouts/authorization/Authorization.actions'

import {
  PLACEHOLDER_SEARCH_DESKTOP,
  PLACEHOLDER_SEARCH_PHONE,
  PLACEHOLDER_SUBSCRIPTION_FORM_DESKTOP,
  PLACEHOLDER_SUBSCRIPTION_FORM_PHONE

} from './Index.constants'
import { getActiveProjects, getCompletedProjects, getRealisationProjects } from './Index.actions'

const IndexPage = () => {
  const history = useHistory()
  const location = useLocation()
  const [ searchPlaceholder, setSearchPlaceholder ] = useState(PLACEHOLDER_SEARCH_DESKTOP)
  const [ subscriptionString, setSubscriptionPlaceholder ] = useState(PLACEHOLDER_SUBSCRIPTION_FORM_DESKTOP)

  const dispatch = useDispatch()

  const {
    projects,
    isFetching,
    completedProjects,
    realisationProjects
  } = useSelector(state => state.indexProjects)

  const isVKAuth = new RegExp('vk').test(location.pathname)
  const isFBAuth = new RegExp('fb').test(location.pathname)

  const fundraisingProjectsData = projects && projects.data.items
  const fundraisingProjectsCount = projects && projects.data.total

  const completedProjectsData = completedProjects && completedProjects.data.items
  const completedProjectsCount = completedProjects && completedProjects.data.total

  const realisationProjectsData = realisationProjects && realisationProjects.data.items
  const realisationProjectsCount = realisationProjects && realisationProjects.data.total

  useEffect(() => {
    dispatch(getActiveProjects(4, true))
    dispatch(getCompletedProjects(4))
    dispatch(getRealisationProjects(4))
  }, [ dispatch ])

  useResizeHook(() => {
    if (window.innerWidth <= 768) {
      updatePlaceholders(PLACEHOLDER_SEARCH_PHONE, PLACEHOLDER_SUBSCRIPTION_FORM_PHONE)
    } else {
      updatePlaceholders(PLACEHOLDER_SEARCH_DESKTOP, PLACEHOLDER_SUBSCRIPTION_FORM_DESKTOP)
    }
  })

  const updatePlaceholders = (searchPlaceholder, subscriptionPlaceholder) => {
    setSearchPlaceholder(searchPlaceholder)
    setSubscriptionPlaceholder(subscriptionPlaceholder)
  }

  const onSubmitSearch = (values) => {
    const query = (values.query && values.query.trim().length !== 0 &&
      `projects?q=${values.query}`) ||
      'projects?type=all'
    history.push(query)
  }

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code')
    const error = new URLSearchParams(location.search).has('error_code')

    if (isVKAuth && code) {
      dispatch(userVkAuthAction(code))
      history.push('/')
    }
    if (isFBAuth && code) {
      dispatch(userFbAuthAction(code))
      history.push('/')
    }
    if (error) {
      history.push('/')
    }
  }, [ dispatch, history, isFBAuth, isVKAuth, location.search ])

  return (
    <BaseLayout>
      <MetaTags
        title={META_TAGS_LIST.MAIN_PAGE_TITLE}
        description={META_TAGS_LIST.MAIN_PAGE_DESCRIPTION}
      />
      <div className="container-app">
        <div className="container-fluid">
          <div className="search-project__section">
            <div className="search-project__left-search-section">
              <h1>Воплощаем идеи. Вместе делаем образование разнообразным и интересным.</h1>
              <p className="search-project__description">У нас есть сотни практических идей от учителей и школьников, которые нацелены на результат. Выберите проект — реализуем его вместе.
              </p>
              <div className="search-project__input-section">
                <Formik
                  initialValues={{
                    query: ''
                  }}
                  onSubmit={(values) => onSubmitSearch(values)}
                  render={({ handleSubmit, handleChange }) => (
                    <form onSubmit={handleSubmit}>
                      <SearchInput
                        name="query"
                        onChange={handleChange}
                        isIndexPage
                        placeholder={searchPlaceholder}
                      />
                    </form>
                  )}
                />
              </div>
            </div>
            <div className="search-project__right-section" />
          </div>

          <div className="mobile-buttons-block">
            <Button
              label="Создать проект"
              color="blue"
              width="136px"
              className="mobile-buttons-button"
              onClick={() => history.push('/projects/new')}
            />
            <Button
              label="Поддержать проект"
              color="orange"
              width="152px"
              className="mobile-buttons-button"
              onClick={() => history.push('/projects?type=all')}
            />
          </div>

          <ProjectsContainer
            stageType={STAGE_TYPES.FUNDRAISING}
            projects={fundraisingProjectsData}
            total={fundraisingProjectsCount}
            isFetching={isFetching}
          />

          <ActionSection action="create" />

          <ProjectsContainer
            stageType={STAGE_TYPES.INTEGRATION}
            projects={realisationProjectsData}
            total={realisationProjectsCount}
            isFetching={isFetching}
          />
          <ProjectsContainer
            stageType={STAGE_TYPES.FINISHED}
            projects={completedProjectsData}
            total={completedProjectsCount}
            isFetching={isFetching}
          />
        </div>
      </div>

      <div className="container-app">
        <div className="container-fluid">
          <div className="form-subscribe">
            <h2>{subscriptionString}</h2>
            <SubscribeForm />
          </div>
        </div>
      </div>
      <div className="bottom-section" />
    </BaseLayout>
  )
}

export default IndexPage
