import React from 'react'

import SharingButtons from '../SharingButtons'

import ProjectMembers from './ProjectMembers'
import ProjectFields from './ProjectFields'
import ProjectBonuses from './ProjectBonuses'
import ProjectBonusesFromPartners from './ProjectBonusesFromPartners'

const ProjectInfo = ({ data }) => {
  const projectMembers = data && [ data.ambassador, data.quote ]

  return (
    <React.Fragment>
      <div className="d-md-none d-lg-block col-lg-4 project-info-payment">
        <ProjectMembers projectMembers={projectMembers} />
        <ProjectBonusesFromPartners />
        <ProjectBonuses />
        <ProjectFields fields={data} />
        <SharingButtons title={data.title} />
        <div className="sharing-container__text">Поделиться проектом</div>
      </div>
    </React.Fragment>
  )
}

export default ProjectInfo
