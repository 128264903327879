import loadReduxTypes from '../../helpers/loadReduxTypes'
import { getProjectStageType } from '../utils/projectStages'

import { CLOSE_PAYMENT_FORM, OPEN_PAYMENT_FORM } from './constants'

const SAVE_AMOUNT = loadReduxTypes('SAVE_AMOUNT')
const GET_PROJECT = loadReduxTypes('GET_PROJECT')
const PROJECT_FILES = loadReduxTypes('PROJECT_FILES')
const PROJECT_PARTNERS = loadReduxTypes('PROJECT_PARTNERS')
const PROJECT_COMMENTS = loadReduxTypes('PROJECT_COMMENTS')
const CLEAR_PROJECT_DATA = loadReduxTypes('CLEAR_PROJECT_DATA')
const POST_PROJECT_COMMENT = loadReduxTypes('POST_PROJECT_COMMENT')
const PROJECT_QUOTE_IMAGE = loadReduxTypes('PROJECT_QUOTE_IMAGE')

const initialState = {
  amount: 0,
  data: null,
  error: null,
  isFetching: false,
  isCommentsFetching: false,
  projectQuote: null,
  files: [],
  projectVideos: null,
  partners: null,
  comments: null,
  isCommentPosting: null,
  quoteAvatar: null,
  isPaymentFormVisible: false,
  stageType: null
}

export default function projectReducer (state = initialState, { type, payload }) {
  switch (type) {
  case OPEN_PAYMENT_FORM: {
    return {
      ...state,
      isPaymentFormVisible: true
    }
  }

  case CLOSE_PAYMENT_FORM: {
    return {
      ...state,
      isPaymentFormVisible: false
    }
  }

  case SAVE_AMOUNT.REQUEST: {
    return {
      ...state,
      amount: payload.amount
    }
  }

  case GET_PROJECT.REQUEST: {
    return {
      ...state,
      stageType: null,
      isFetching: true
    }
  }

  case GET_PROJECT.SUCCESS: {
    const { stage } = payload.project
    const stageType = getProjectStageType(stage)

    return {
      ...state,
      data: payload.project,
      stageType,
      projectVideos: payload.projectVideos,
      isFetching: false
    }
  }

  case GET_PROJECT.FAILURE: {
    return {
      ...state,
      error: payload.error,
      stageType: null,
      isFetching: false
    }
  }

  case PROJECT_FILES.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case PROJECT_FILES.SUCCESS: {
    return {
      ...state,
      isFetching: false,
      files: payload.files
    }
  }

  case PROJECT_FILES.FAILURE: {
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }
  }

  case PROJECT_PARTNERS.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case PROJECT_PARTNERS.SUCCESS: {
    return {
      ...state,
      isFetching: false,
      partners: payload.partners
    }
  }

  case PROJECT_PARTNERS.FAILURE: {
    return {
      ...state,
      error: payload.error,
      isFetching: false
    }
  }

  case PROJECT_COMMENTS.REQUEST: {
    return {
      ...state,
      isCommentsFetching: true
    }
  }

  case PROJECT_COMMENTS.SUCCESS: {
    return {
      ...state,
      isCommentsFetching: false,
      comments: payload.comments
    }
  }

  case PROJECT_COMMENTS.FAILURE: {
    return {
      ...state,
      error: payload.error,
      isCommentsFetching: false
    }
  }

  case POST_PROJECT_COMMENT.REQUEST: {
    return {
      ...state,
      isCommentPosting: true
    }
  }

  case POST_PROJECT_COMMENT.SUCCESS: {
    return {
      ...state,
      isCommentPosting: false
    }
  }

  case POST_PROJECT_COMMENT.FAILURE: {
    return {
      ...state,
      isCommentPosting: false
    }
  }

  case CLEAR_PROJECT_DATA.SUCCESS: {
    return initialState
  }

  case PROJECT_QUOTE_IMAGE.REQUEST: {
    return {
      ...state,
      isFetching: true
    }
  }

  case PROJECT_QUOTE_IMAGE.SUCCESS: {
    return {
      ...state,
      isFetching: false,
      quoteAvatar: payload.quoteAvatar
    }
  }

  case PROJECT_QUOTE_IMAGE.FAILURE: {
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }
  }

  default: return state
  }
}
