import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { openAuthorizationForm } from '../../layouts/BaseLayout.actions'

const ProjectBonuses = () => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth.isAuthorized)
  const { data } = useSelector(state => state.project)

  return (
    <>
      {data.bonus_system && (
        <div className="info-block bonuses">
          <h3 className="bonuses__title">
          Бонусы партнерам проекта
          </h3>
          {data.bonus_system && data.bonus_system.bonuses.map((item, index) => (
            <div key={index} className="bonuses__item">
              <div className="bonuses__sum" dangerouslySetInnerHTML={{ __html: item.amount }}></div>
              <div className="bonuses__description" dangerouslySetInnerHTML={{ __html: item.description }}>
              </div>
            </div>
          ))}
          {!auth && <div className="bonuses__registation">
          получение бонуса доступно только для <button type="button" className="bonuses__registation-link" onClick={() => dispatch(openAuthorizationForm())} >зарегистрированных пользователей</button>
          </div>}
        </div>
      )}
    </>
  )
}

export default ProjectBonuses
