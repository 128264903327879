import agent from '../../config/methods'

import {
  GET_NEWS_FAIL,
  GET_NEWS_REQUEST,
  GET_NEWS_SUCCESS
} from './News.constants'

function getNews (newsCount) {
  return async function action (dispatch) {
    dispatch({ type: GET_NEWS_REQUEST })

    try {
      const response = await agent.GET('/news', {
        'per_page': newsCount,
        'sort_by': 'created_at',
        'order': 'desc'
      })
      dispatch({
        type: GET_NEWS_SUCCESS,
        payload: {
          news: response.data
        }
      })
    } catch (error) {
      dispatch({
        type: GET_NEWS_FAIL,
        payload: {
          error
        }
      })
    }
  }
}

export { getNews }
