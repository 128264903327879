import { useEffect } from 'react'

export const useResizeHook = (callback) => {
  useEffect(() => {
    window.addEventListener('resize', callback)
    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [ callback ])
}
