import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { VKShareButton, OKShareButton } from 'react-share'

import imgVK from '../../ge/social-vk-hover.svg'
import imgOK from '../../ge/social-ok-hover.svg'
import { HOSTNAME_URL } from '../../constants/Api.constants'

const SharingButtons = ({ title, match: { url }, newUrl }) => {
  const urlCalculated = newUrl ? `${HOSTNAME_URL}${newUrl}` : `${HOSTNAME_URL}${url}`
  return (
    <React.Fragment>
      <div>
        <div className="sharing-container">
          <VKShareButton title={title} url={urlCalculated}>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={imgVK} alt="VKShare" className="news-article__social-content-img" />
            </a>
          </VKShareButton>

          <OKShareButton title={title} url={urlCalculated}>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={imgOK} alt="OKShare" className="news-article__social-content-img" />
            </a>
          </OKShareButton>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SharingButtons)

SharingButtons.propTypes = {
  title: PropTypes.string.isRequired
}
