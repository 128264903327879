import React, { useEffect } from 'react'

const ModalContainer = ({ children }) => {
  useEffect(() => {
    // Prevent body scrolling on IOS
    const body = document.querySelector('body')
    const html = document.querySelector('html').style
    const wrap = document.querySelector('.content').style
    let scrollTop = 0

    body.classList.add('overlay-open')

    if (window.pageYOffset) {
      scrollTop = window.pageYOffset
      wrap.top = -(scrollTop)
    }
    body.style.height = '100%'
    body.style.overflow = 'hidden'
    html.height = '100%'
    html.overflow = 'hidden'

    return () => {
      body.style.height = ''
      body.style.overflow = ''
      html.height = ''
      html.overflow = ''
      wrap.top = ''

      window.scrollTo(0, scrollTop)
      window.setTimeout(function () {
        scrollTop = null
      }, 0)
      body.classList.remove('overlay-open')
    }
  }, [])

  return (
    <div className="payment-modal">
      {children}
    </div>
  )
}

export default ModalContainer
