import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import MissionImage from '../../ge/platform-ils/mission-section.svg'
import PlatformILsBottomImage from '../../ge/platform-ils/platform-ils-bottom__img.svg'
import BaseLayout from '../../layouts/BaseLayout'
import MetaTags from '../../components/MetaTags'
import { META_TAGS_LIST } from '../../constants/Meta.constants'
import ArrowIcon from '../../ge/arrow-right-blue.svg'
import { ImageCard } from '../../components/ImageCard'
import { Slider } from '../../components/Slider'
import TeamCard from '../../components/TeamCard'
import { API_IMG } from '../../constants/Api.constants'
import { DocumentCompact } from '../../components/DocumentCompact'
import { Social } from '../../components/Social'
import { HashNavigation } from '../../components/HashNavigation'
import CollageImage1 from '../../ge/platform-ils/collage-img1.png'
import CollageImage2 from '../../ge/platform-ils/collage-img2.png'
import CollageImage3 from '../../ge/platform-ils/collage-img3.png'
import CollageImage4 from '../../ge/platform-ils/collage-img4.png'
import CollageImage5 from '../../ge/platform-ils/collage-img5.png'
import CollageImage6 from '../../ge/platform-ils/collage-img6.png'

import {
  CONTACTS,
  NAVIGATION_BASE,
  NAVIGATION_ITEMS,
  PROJECTS,
  REPORTS_AND_DOCUMENTS,
  WORK_TARGETS_LIST
} from './PlatformILS.constants'
import { getPlatformMembers, getPlatformNews, getPlatformVolunteers } from './PlatformILS.actions'

const SLIDES_TO_SHOW = 3

const PlatformILSPage = () => {
  const dispatch = useDispatch()

  const { platformPage: {
    platformNews,
    platformMembers,
    platformVolunteers
  } } = useSelector(state => state)

  useEffect(() => {
    dispatch(getPlatformNews())
    dispatch(getPlatformMembers())
    dispatch(getPlatformVolunteers())
  }, [ dispatch ])

  const news = platformNews?.news.data.items.slice(0, 4)
  const members = platformMembers?.map(item => ({
    id: item.order_value,
    name: item.name,
    position: item.short_quote,
    text: item.quote,
    img: API_IMG + item.photo
  })).sort((a, b) => a.id - b.id) || []
  const volunteers = platformVolunteers?.map(volunteer => ({
    id: volunteer.order_value,
    name: volunteer.name,
    position: volunteer.position,
    img: API_IMG + volunteer.photo
  })) || []

  const sliderResponsiveSettings = [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        dots: true
      }
    }
  ]

  return (
    <React.Fragment>
      <BaseLayout>
        <MetaTags
          title={META_TAGS_LIST.PLATFORM_TITLE}
          description={META_TAGS_LIST.PLATFORM_DESCRIPTION}
        />

        <div className="container-app--platform">
          <div className="container-fluid">
            <div className="platform-welcome-section">
              <div className="platform-welcome-section__content">
                <div>
                  <h2 className="platform-welcome-section__title">Автономная некоммерческая организация «Платформа образовательных проектов»</h2>
                  <p className="platform-welcome-section__description">C 2019 года развивает интернет-платформу для социальных проектов, направленных на объединение школ, горожан и бизнеса, а также людей, кто хочет быть причастным к развитию образования в нашей стране</p>
                </div>
                <div className="platform-welcome-section__collage">
                  <img src={CollageImage1} className="platform-welcome-section__collage-image platform-welcome-section__collage-image_1" alt="" />
                  <img src={CollageImage2} className="platform-welcome-section__collage-image platform-welcome-section__collage-image_2" alt="" />
                  <img src={CollageImage3} className="platform-welcome-section__collage-image platform-welcome-section__collage-image_3" alt="" />
                  <img src={CollageImage4} className="platform-welcome-section__collage-image platform-welcome-section__collage-image_4" alt="" />
                  <img src={CollageImage5} className="platform-welcome-section__collage-image platform-welcome-section__collage-image_5" alt="" />
                  <img src={CollageImage6} className="platform-welcome-section__collage-image platform-welcome-section__collage-image_6" alt="" />
                </div>
              </div>
            </div>

            <HashNavigation items={NAVIGATION_ITEMS} base={NAVIGATION_BASE} className="platform-navigation" />

            <div className="platform-section platform-mission-section" id="mission">
              <h3 className="platform-title">Миссия</h3>

              <div className="platform-description-card platform-description-card--green">
                <img src={MissionImage} alt="People with devices" className="platform-description-card__image" />
                <p className="platform-description-card__text">Создаем удобные и простые инструменты для реализации проектов,
                  которые делают образование наших детей в тысячах школ по всей стране эффективным и современным</p>
              </div>
            </div>

            <div className="platform-section work-target-section" id="work-targets">
              <h3 className="platform-title">Основные направления работы</h3>

              <div className="work-target-section__targets-list-wrapper">
                <ul className="work-target-section__targets-list">
                  {WORK_TARGETS_LIST.map((item, index) => <li key={index} className="work-target-section__targets-list-item">{item}</li>)}
                </ul>
              </div>
            </div>

            <div className="platform-section news-section" id="news">
              <div className="news-section__header">
                <h3 className="platform-title">Новости</h3>
                <Link className="news-section__all-news-button" to="/news">
                  Смотреть все
                  <img src={ArrowIcon} alt="right arrow" className="news-section__arrow-icon" />
                </Link>
              </div>

              <div className="news-section__news-container">
                {news?.map((item) => (
                  <ImageCard
                    imageFilename={item.photo?.filename || null}
                    imageAlt="Cover for news article"
                    date={moment(item.created_at).format('LL')}
                    title={item.title}
                    description={item.summary}
                    link={'/news/' + item.id}
                    className="news-section__news-item"
                  />
                ))}
              </div>
            </div>

            <div className="platform-section platform-team-section" id="team">
              <div className="platform-team-section__container">
                <h3 className="platform-title">Наша команда</h3>

                <div className="platform-team-section__team-slider">
                  {!!members.length && (
                    <Slider
                      sliderId="team"
                      slidesToShow={members.length > 2 ? SLIDES_TO_SHOW : members.length}
                      buttonsLightBlue
                      className="platform-team-slider"
                      classNameButton="platform-team-slider__buttons"
                      responsive={sliderResponsiveSettings}
                    >
                      {members.map(item => (
                        <TeamCard key={item.id} member={item} />
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </div>

            <div className="platform-section platform-team-section" id="volunteers">
              <div className="platform-team-section__container">
                <h3 className="platform-title">Нам помогают</h3>

                <div className="platform-team-section__team-slider">
                  {!!volunteers.length && (
                    <Slider
                      sliderId="volunteers"
                      slidesToShow={volunteers.length > 2 ? SLIDES_TO_SHOW : volunteers.length}
                      buttonsLightBlue
                      className="platform-team-slider"
                      classNameButton="platform-team-slider__buttons"
                      responsive={sliderResponsiveSettings}
                    >
                      {volunteers.map(item => (
                        <TeamCard key={item.id} member={item} />
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </div>

            <div className="platform-section structure-section" id="structure">
              <h3 className="platform-title">Структура управления НКО</h3>

              <div className="structure-section__card">
                <h4 className="structure-section__subtitle">Органы управления:</h4>

                <div className="structure-section__text-block">
                  <p className="structure-section__heading">Правление (срок полномочий – 3 года)</p>
                  <p className="structure-section__text">Председатель правления: Проценко Анна Вячеславовна (избирается большинством голосов членов правления)</p>
                </div>

                <div className="structure-section__text-block">
                  <p className="structure-section__heading">Члены правления:</p>
                  <ul className="structure-section__text structure-section__members-list">
                    <li>Попова Елена Владимировна</li>
                    <li>Павлов Алексей Константинович</li>
                  </ul>
                </div>

                <div className="structure-section__text-block">
                  <p className="structure-section__heading">Директор (срок полномочий – 3 года)</p>
                  <p className="structure-section__text">Павлов Алексей Константинович</p>
                </div>
              </div>
            </div>

            <div className="platform-section projects-section" id="projects">
              <h3 className="platform-title">Проекты АНО</h3>

              <div className="projects-section__projects-container">
                {PROJECTS.map((project) =>
                  (
                    <a key={project.id} href={Array.isArray(project.link) ? void 0 : project.link} target="_blank" rel="noopener noreferrer" className="projects-section__project-card">
                      <img src={project.image} alt={'Cover for project'} className="projects-section__project-image" />

                      <div className="projects-section__project-body">
                        <p className="projects-section__project-title">{project.title}</p>

                        {Array.isArray(project.link)
                          ? project.link.map((item) => (
                            <p className="projects-section__project-link" key={item.id}>
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="projects-section__project-nested-link"
                              >{item.description}</a>
                              <img src={ArrowIcon} alt="right arrow" className="projects-section__arrow-icon" />
                            </p>
                          ))
                          : (
                            <p className="projects-section__project-link">
                              {project.description}
                              <img src={ArrowIcon} alt="right arrow" className="projects-section__arrow-icon" />
                            </p>
                          )}
                      </div>
                    </a>
                  )
                )}
              </div>
            </div>

            <div className="platform-section reports-section" id="reports-and-documents">
              <h3 className="platform-title">Отчеты и документы</h3>

              {REPORTS_AND_DOCUMENTS.map((section) => (
                <div key={section.id} className="reports-section__block">
                  <h4 className="reports-section__block-title">{section.title}</h4>

                  <div className="reports-section__block-documents">
                    {section.items.map((document) => (
                      <DocumentCompact
                        key={document.id}
                        title={document.title}
                        subtitle={document.date
                          ? moment(document.date, 'DD.MM.YYYY').format('D MMMM YYYY').concat(' г.')
                          : null}
                        uri={document.uri}
                        className="reports-section__document"
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div className="platform-section contacts-section" id="contacts">
              <h3 className="platform-title">Контакты</h3>
              <p className="contacts-section__description">Автономная некоммерческая организация “Платформа образовательных проектов”</p>

              <div className="contacts-section__contacts-container">
                <div className="contacts-section__contacts-col contacts-section__contacts-col-1">
                  <div className="contacts-section__contacts-row">
                    <p className="contacts-section__contact-title">{CONTACTS.email.title}</p>
                    <a className="contacts-section__contact-value" href={'mailto:'.concat(CONTACTS.email.value)}>{CONTACTS.email.value}</a>
                  </div>
                  <div className="contacts-section__contacts-row">
                    <p className="contacts-section__contact-title">{CONTACTS.phone.title}</p>
                    <a className="contacts-section__contact-value" href={'tel:'.concat(CONTACTS.phone.value)}>{CONTACTS.phone.value}</a>
                  </div>
                  <div className="contacts-section__contacts-row">
                    <p className="contacts-section__contact-title">{CONTACTS.address.title}</p>
                    <p className="contacts-section__contact-value">{CONTACTS.address.value}</p>
                  </div>
                </div>

                <div className="contacts-section__contacts-col contacts-section__contacts-col-2">
                  <div className="contacts-section__contacts-row">
                    <p className="contacts-section__contact-title">{CONTACTS.OGRN.title}</p>
                    <p className="contacts-section__contact-value">{CONTACTS.OGRN.value}</p>
                  </div>
                  <div className="contacts-section__contacts-row">
                    <p className="contacts-section__contact-title">{CONTACTS.INN.title}</p>
                    <p className="contacts-section__contact-value">{CONTACTS.INN.value}</p>
                  </div>
                  <div className="contacts-section__contacts-row">
                    <p className="contacts-section__contact-title">{CONTACTS.KPP.title}</p>
                    <p className="contacts-section__contact-value">{CONTACTS.KPP.value}</p>
                  </div>

                  <Social className="contacts-section__social" />
                </div>
              </div>
            </div>

            <img src={PlatformILsBottomImage} alt="Relaxing people with books" className="platform-bottom-image" />
          </div>
        </div>
      </BaseLayout>
    </React.Fragment>
  )
}

export default PlatformILSPage
