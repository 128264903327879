import PropTypes from 'prop-types'
import classNames from 'classnames'
import React, { useRef } from 'react'

import clearIcon from '../../ge/clear-icon.svg'

const SearchInput = ({
  name,
  query,
  isIndexPage = false,
  error = false,
  isInputLarge = false,
  onChange,
  disabled = false,
  placeholder,
  cnInput,
  cnIcon,
  isShowCancelButton = false,
  onClear = null,
  ...inputProps
}) => {
  const inputRef = useRef(null)

  const inputClassNames = classNames({
    [cnInput]: cnInput,
    'search-input__field': !cnInput,
    'search-input__field--rounded': isIndexPage,
    'search-input__field--background-blue': isIndexPage,
    'search-input__field--large': isInputLarge,
    'search-input__field--no-framed': isIndexPage
  })

  const searchIcon = classNames({
    [cnIcon]: cnIcon,
    'search-input__icon': !cnIcon,
    'search-input__icon--rounded': isIndexPage,
    'search-input__icon--large': isInputLarge
  })

  const cancelIcon = classNames({
    'search-input__cancel-icon': true,
    'search-input__cancel-icon--centered': isInputLarge
  })

  const handleFocus = (event) => {
    event.preventDefault()
    inputRef.current.focus()
  }

  return (
    <div className="search-project-form__search-input search-input">
      {error && (<div className="search-input__error">{error}</div>)}
      <input
        onChange={onChange}
        className={inputClassNames}
        name={name}
        type="search"
        placeholder={placeholder || 'Введите название инициатора проекта.'}
        maxLength={124}
        ref={inputRef}
        {...inputProps}
      />

      {isShowCancelButton && <div className={cancelIcon}>
        <img alt="cancel-button" src={clearIcon} onClick={() => onClear()} />
      </div>
      }

      <button
        onClick={(e) => !isIndexPage && handleFocus(e)}
        className={searchIcon}
      />
    </div>
  )
}

SearchInput.propTypes = {
  query: PropTypes.string,
  onChange: PropTypes.func,
  isIndexPage: PropTypes.bool
}

export default SearchInput
