import { useEffect } from 'react'

export const useDebounce = (fn, ms = 0, args = []) => {
  useEffect(() => {
    const handle = setTimeout(fn.bind(null, ...args), ms)
    return () => {
      clearTimeout(handle)
    }
  // eslint-disable-next-line
  }, [ ...args, fn, ms ])
}
