import loadReduxTypes from '../../helpers/loadReduxTypes'

import { SCHOOLS_PROJECTS_CLEAR_STATE } from './SchoolsProjects.constants'

const GET_SCHOOL_PROJECTS = loadReduxTypes('GET_SCHOOL_PROJECTS')
const GET_PROJECT_VENDORS = loadReduxTypes('GET_PROJECT_VENDORS')
const GET_SCHOOL = loadReduxTypes('GET_SCHOOL')

const initialState = {
  data: null,
  total: 0,
  error: null,
  vendors: null
}

export default function schoolProjectsReducer (state = initialState, { type, payload }) {
  switch (type) {
  case GET_SCHOOL_PROJECTS.REQUEST:
    return {
      ...state,
      isFetching: true
    }

  case GET_SCHOOL_PROJECTS.SUCCESS:
    return {
      ...state,
      isFetching: false,
      data: payload.data,
      total: payload.total
    }

  case GET_SCHOOL_PROJECTS.FAILURE:
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }

  case GET_PROJECT_VENDORS.REQUEST:
    return {
      ...state,
      isFetching: true
    }

  case GET_PROJECT_VENDORS.SUCCESS:
    return {
      ...state,
      isFetching: false,
      vendors: payload
    }

  case GET_PROJECT_VENDORS.FAILURE:
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }

  case GET_SCHOOL.REQUEST:
    return {
      ...state,
      isFetching: true
    }

  case GET_SCHOOL.SUCCESS:
    return {
      ...state,
      isFetching: false,
      schoolName: payload.schoolName
    }

  case GET_SCHOOL.FAILURE:
    return {
      ...state,
      isFetching: false,
      error: payload.error
    }

  case SCHOOLS_PROJECTS_CLEAR_STATE:
    return initialState

  default: return state
  }
}
