import React from 'react'
import classNames from 'classnames'

import DocumentImage from '../../ge/document.svg'

export const DocumentCompact = ({ title, subtitle, uri, className, download = undefined, target = '_blank' }) => {
  return (
    <a href={uri} download={download} target={target} className={classNames(className, 'document-compact')}>
      <img src={DocumentImage} alt="Document" className="document-compact__icon" />

      <div>
        <p className="document-compact__title">{title}</p>

        {!!subtitle && <p className="document-compact__subtitle">{subtitle}</p>}
      </div>
    </a>
  )
}
