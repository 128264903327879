import { toast } from 'react-toastify'
import ReactPixel from 'react-facebook-pixel'

import agent from '../../config/methods'

import {
  GET_ACTIVE_PROJECTS_FAIL,
  GET_ACTIVE_PROJECTS_REQUEST,
  GET_ACTIVE_PROJECTS_SUCCESS,
  GET_COMPLETED_PROJECTS_FAIL,
  GET_COMPLETED_PROJECTS_REQUEST,
  GET_COMPLETED_PROJECTS_SUCCESS,
  SUBSCRIBE_FORM_REQUEST,
  SUBSCRIBE_FORM_SUCCESS,
  SUBSCRIBE_FORM_FAIL,
  TOAST_OPTIONS, REALISATION_PROJECTS
} from './Index.constants'
const getParams = (perPage, stage, isRandom) => {
  if (isRandom) {
    return {
      q: [
        'status__like__published',
        'stage__eq__not_specified',
        'stage__eq__financing',
        'stage__eq__purchasing',
        'stage__eq__installing',
        'stage__eq__training'
      ],
      order: 'random',
      per_page: perPage
    }
  }

  const realisationStages = [ 'stage__eq__training', 'stage__eq__purchasing', 'stage__eq__installing' ]
  const queryStage = stage !== 'realisation' ? [ `stage__eq__${stage}` ] : realisationStages

  return {
    'q': [ queryStage, 'status__like__published' ],
    'per_page': perPage,
    'sort_by': 'created_at',
    'order': 'desc'
  }
}

function getActiveProjects (perPage, isRandom) {
  return async function action (dispatch) {
    const params = getParams(perPage, 'financing', isRandom)

    dispatch({ type: GET_ACTIVE_PROJECTS_REQUEST })
    try {
      const response = await agent.GET('/project', params)
      dispatch({
        type: GET_ACTIVE_PROJECTS_SUCCESS,
        payload: {
          projects: response.data
        }
      })
    } catch (error) {
      dispatch({
        type: GET_ACTIVE_PROJECTS_FAIL,
        payload: {
          error
        }
      })
    }
  }
}

function getCompletedProjects (perPage) {
  return async function action (dispatch) {
    const params = getParams(perPage, 'done')

    dispatch({ type: GET_COMPLETED_PROJECTS_REQUEST })

    try {
      const response = await agent.GET('/project', params)
      dispatch({
        type: GET_COMPLETED_PROJECTS_SUCCESS,
        payload: {
          completedProjects: response.data
        }
      })
    } catch (error) {
      dispatch({
        type: GET_COMPLETED_PROJECTS_FAIL,
        payload: {
          error
        }
      })
    }
  }
}

function getRealisationProjects (perPage) {
  return async function action (dispatch) {
    const params = getParams(perPage, 'realisation')

    dispatch({ type: REALISATION_PROJECTS.REQUEST })

    try {
      const response = await agent.GET('/project', params)
      dispatch({
        type: REALISATION_PROJECTS.SUCCESS,
        payload: {
          realisationProjects: response.data
        }
      })
    } catch (error) {
      dispatch({
        type: REALISATION_PROJECTS.FAILURE,
        payload: {
          error
        }
      })
    }
  }
}

function setSubscription (email) {
  return function action (dispatch) {
    dispatch({ type: SUBSCRIBE_FORM_REQUEST })
    ReactPixel.track('Lead')

    agent.POST('/subscribe', { email: email })
      .then((response) => {
        dispatch({
          type: SUBSCRIBE_FORM_SUCCESS,
          payload: {
            completedProjects: response.data
          }
        })

        toast.info('Вы успешно подписались на рассылку!', TOAST_OPTIONS)
      })

      .catch((error) => {
        if (error.response.status === 500 || error.response.status === 400) {
          toast.info('Вы уже подписаны на рассылку новостей', TOAST_OPTIONS)
          return
        }
        dispatch({
          type: SUBSCRIBE_FORM_FAIL,
          payload: {
            error
          }
        })

        if (error.response.data.error === 'Subscription already exists') {
          toast.error('Подписка на указанный адрес уже активирована!', TOAST_OPTIONS)
        } else {
          toast.error('Что-то пошло не так! Повторите попытку позже', TOAST_OPTIONS)
        }
      })
  }
}

export { getActiveProjects, getCompletedProjects, setSubscription, getRealisationProjects }
