import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useRouteMatch } from 'react-router'

import { Button } from '../../components/Button'
import { PartnersLogoGrid } from '../../components/Partners'
import PartnerPerson from '../../components/PartnerPerson'
import { Slider } from '../../components/Slider'
import BaseLayout from '../../layouts/BaseLayout'
import ErrorMessage from '../../components/ErrorMessage'
import { API_IMG } from '../../constants/Api.constants'
import ProjectCard from '../../components/ProjectCard'
import Review from '../../components/Review'
import Loader from '../../components/Loader'
import { fCash, formatName } from '../../helpers/formatters'
import ReviewPopup from '../../components/ReviewPopup'
import { formatArrayImages, getPluralizeWord } from '../utils/getObjectFields'
import CardSlider from '../../components/CardSlider'
import { getOriginalHeight } from '../utils/componentsOperations'
import {
  SCHOOL_PROJECTS_COUNT,
  REVIEWS_COUNT
} from '../Schools/Schools.constants'
import { useStateClearance } from '../../hooks/useStateClearance.hook'
import PaymentFormContainer from '../../components/PaymentFormContainer/PaymentFormContainer'

import {
  clearSchoolStateAction,
  getSchoolInfo,
  getSchoolProjects,
  getSchoolReviewAction,
  postReviewAction
} from './School.actions'

const SchoolPage = () => {
  const match = useRouteMatch()
  const id = match.params.id

  const [ projectCount, setProjectCount ] = useState(SCHOOL_PROJECTS_COUNT)
  const [ reviewCount, setReviewCount ] = useState(REVIEWS_COUNT)
  const [ selectedProject, setSelectedProject ] = useState()
  const [ isSuccessPaymentModal, setIsSuccessPaymentModal ] = useState()

  const { isPaymentFormVisible } = useSelector((state) => state.project)

  const [ isShowDescription, setIsShowDescription ] = useState(false)
  const [ isPopupVisible, setIsPopupVisible ] = useState(false)

  const dispatch = useDispatch()

  const {
    schoolProjects,
    isFetching,
    isReviewFetching,
    error,
    data,
    isProjectFetching,
    schoolReviews,
    projectsGoal
  } = useSelector((state) => state.school)

  const { user } = useSelector((state) => state.auth)

  const popupRef = useRef(null)

  useStateClearance(clearSchoolStateAction)

  useEffect(() => {
    dispatch(getSchoolInfo(id))
    dispatch(getSchoolProjects(id, '3', true))
  }, [ id, dispatch ])

  useEffect(() => {
    if (projectCount > SCHOOL_PROJECTS_COUNT) {
      dispatch(getSchoolProjects(id, projectCount))
    }

    if (reviewCount > REVIEWS_COUNT) {
      dispatch(getSchoolReviewAction(id, reviewCount))
    }
  }, [ projectCount, reviewCount, dispatch, id ])

  useEffect(() => {
    dispatch(getSchoolReviewAction(id, reviewCount))
  }, [ dispatch, reviewCount, id ])

  useEffect(() => {
    if (popupRef) {
      isPopupVisible
        ? disableBodyScroll(popupRef.current)
        : clearAllBodyScrollLocks()
    }
  }, [ isPopupVisible ])

  if (error) {
    return <ErrorMessage />
  }

  const checkAuth = () => {
    if (user) {
      setIsPopupVisible(!isPopupVisible)
    } else {
      toast.error('Отзывы могут оставлять только авторизованные пользователи')
    }
  }

  /* Spoiler feature */
  const descriptionText = isShowDescription
    ? 'Скрыть подробное описание'
    : 'Показать подробное описание'
  const isLargeText = data && data.info && data.info.length >= 450
  const isInfoExist = data && data.info ? data.info.trim().length !== 0 : false

  /* Statistic Block */
  const totalGoal = projectsGoal || 0
  const totalProjects = schoolProjects ? schoolProjects.total : 0

  // wip data.donation_users.filter(i => i.user).map(i => i.user)
  const partnersData = data && data.donation_users ? data.donation_users : []
  const totalPartnersCount = data && data.total_partners_count ? data.total_partners_count : 0
  const totalPartnersString = `${getPluralizeWord(totalPartnersCount, [
    'партнер',
    'партнера',
    'партнеров'
  ])}`
  const projectSlidesCount =
    schoolProjects && schoolProjects.total === 1 ? 1 : 2

  const isFetchingData =
    !data ||
    !schoolProjects ||
    (isReviewFetching && reviewCount === 3) ||
    (isProjectFetching && projectCount === 3)

  if (isFetchingData) {
    return (
      <BaseLayout>
        <div className="container-app">
          <div className="school-details-review__loader">
            <Loader />
          </div>
        </div>
      </BaseLayout>
    )
  }

  const projectsWord = `${getPluralizeWord(totalProjects, [
    'проект',
    'проекта',
    'проектов'
  ])}`

  const schoolVendors =
    !isFetching && data.vendors ? formatArrayImages(data.vendors) : []
  // WIP, TODO: Create ref for getting height.

  return (
    <React.Fragment>
      {isPopupVisible && (
        <ReviewPopup
          schoolId={id}
          onSendReview={postReviewAction}
          onClose={setIsPopupVisible}
          innerRef={popupRef}
        />
      )}
      <PaymentFormContainer
        isPaymentFormVisible={isPaymentFormVisible}
        isSuccessPaymentModal={isSuccessPaymentModal}
        setIsSuccessPaymentModal={setIsSuccessPaymentModal}
        selectedProject={selectedProject}
      />
      <BaseLayout>
        <div className="container-app">
          <div className="container-fluid">
            <div className="school-details">
              <div className="school-details__main-wrapper">
                <h2>{(data && data.title) || ''}</h2>
                {isInfoExist && (
                  <div>
                    <div
                      className="school-details__info"
                      dangerouslySetInnerHTML={{ __html: data.info }}
                      style={{
                        height:
                          (isShowDescription &&
                            getOriginalHeight('school-details__info')) ||
                          0
                      }}
                    />
                    {isLargeText && (
                      <div className="school-details__spoiler">
                        <span
                          onClick={() =>
                            setIsShowDescription(!isShowDescription)}
                        >
                          {descriptionText}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                <div className="school-stats">
                  <div className="school-stats__items-container">
                    <div className="school-stats__value">
                      {fCash(totalGoal)}
                    </div>
                    <div className="school-stats__label">Собрано</div>
                  </div>

                  <div className="school-stats__items-container">
                    <div className="school-stats__value">{totalProjects}</div>
                    <div className="school-stats__label">{projectsWord}</div>
                  </div>

                  <div className="school-stats__items-container">
                    <div className="school-stats__value">
                      {totalPartnersCount}
                    </div>
                    <div className="school-stats__label">
                      {totalPartnersString}
                    </div>
                  </div>
                </div>
              </div>
              <div className="school-details__image-wrapper">
                {data && (
                  <img
                    alt="School logo"
                    className="school-details__logo"
                    src={data.logo
                      ? `${API_IMG}/${data.logo.filename}`
                      : '/images/female-icons/no-userpic.svg'}
                  />
                )}
              </div>
            </div>
            <div className="school-details__header">
              <h3>Проекты</h3>
              {schoolProjects.total === 0 && (
                <p>В настоящий момент проекты у инициатора отсутствуют.</p>
              )}
            </div>
            {schoolProjects.total !== 0 && (
              <div className="row school-details__project-cards">
                {schoolProjects &&
                  schoolProjects.projects.map((project) => (
                    <div
                      className="col school-details__project-cards-item"
                      key={`item_${project.id}`}
                    >
                      <ProjectCard
                        openPaymentForm={() => setSelectedProject(project)}
                        key={`project_${project.id}`}
                        title={project.title}
                        link={`/projects/${project.id}`}
                        stage={project.stage}
                        collected={project.collected}
                        goal={project.goal}
                        logo={project.logo}
                        description={project.description}
                        balance={project.balance}
                      />
                    </div>
                  ))}
              </div>
            )}

            {schoolProjects.total !== 0 && (
              <div className="school-details__project-cards-slides">
                <CardSlider
                  slidesToShow={projectSlidesCount}
                  isShowLoader={isFetching}
                  slidesToScroll={2}
                >
                  {schoolProjects &&
                    schoolProjects.projects.length !== 0 &&
                    schoolProjects.projects.map((project) => (
                      <div className="active-projects__slide" key={`project_${project.id}`}>
                        <ProjectCard
                          openPaymentForm={() => setSelectedProject(project)}
                          key={`project_${project.id}`}
                          title={project.title}
                          link={`/projects/${project.id}`}
                          stage={project.stage}
                          collected={project.collected}
                          goal={project.goal}
                          logo={project.logo}
                          description={project.description}
                          balance={project.balance}
                        />
                      </div>
                    ))}
                </CardSlider>
              </div>
            )}
            <div className="school-details__more-button">
              <div className="col-auto">
                {schoolProjects && projectCount < schoolProjects.total && (
                  <Button
                    label={isReviewFetching ? 'Загрузка...' : 'Показать еще'}
                    nobg
                    large
                    onClick={() => setProjectCount(projectCount + 3)}
                  />
                )}
              </div>
            </div>

            {partnersData.length === 0 && (
              <div className="margin-bottom-45 school-details__partners-wrapper">
                <h3 className="school-details__header">Партнеры</h3>
                <span>Станьте первым партнером для инициатора проекта.</span>
              </div>
            )}

            <div className="school-details__partners-slider-wrapper">
              {partnersData.length !== 0 && (
                <Slider
                  sliderId="partners"
                  header={<h3 className="school-details__header">Партнеры</h3>}
                  buttonsBlue
                  count={partnersData.length}
                  className="margin-bottom-75 school-details__partners-slider"
                >
                  {partnersData &&
                    partnersData.map((person, index) => (
                      <PartnerPerson
                        key={index}
                        portrait={person}
                        name={formatName(person.first_name, person.last_name)}
                        position={person.position}
                        company={person.company}
                      />
                    ))}
                </Slider>
              )}
            </div>

            <div className="school-details__project-cards-slides-partners">
              <h3 className="school-details__header">Партнеры</h3>

              {partnersData.length === 0 && (
                <span>Станьте первым партнером для инициатора проекта.</span>
              )}

              <CardSlider
                slidesToShow={partnersData.length === 1 ? 1 : 2}
                isShowLoader={isFetching}
                slidesToScroll={2}
              >
                {partnersData &&
                  partnersData.map((person, index) => (
                    <PartnerPerson
                      key={index}
                      portrait={person}
                      name={formatName(person.first_name, person.last_name)}
                      position={person.position}
                      company={person.company}
                    />
                  ))}
              </CardSlider>
            </div>

            <div className="partners-section">
              <div className="partners-section">
                <div className="partners">
                  <PartnersLogoGrid
                    partners={schoolVendors}
                    isCustomStyles
                    className="partners-content"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="school-details-review">
          <div className="school-details-review__wrap">
            <div className="school-details-review__controls">
              <div className="school-details-review__left-section">
                <h3>Отзывы</h3>
                <span className="school-details-review__count">
                  {schoolReviews && schoolReviews.data.total}
                </span>
              </div>

              <div className="school-details-review__right-section">
                <Button
                  label="Написать отзыв"
                  color="blue"
                  onClick={() => checkAuth()}
                  width="256px"
                />
              </div>
            </div>

            <div className="school-details-review__content">
              {schoolReviews &&
                schoolReviews.data.items.length === 0 &&
                'Отзывы отсутствуют.'}
              {schoolReviews &&
                schoolReviews.data.items.map((item, index) => {
                  const fullName =
                    formatName(item.user.first_name, item.user.last_name) ||
                    item.user.login
                  return (
                    <Review
                      key={index}
                      name={fullName}
                      text={item.text}
                      user={item.user}
                      date={item.created_at}
                    />
                  )
                })}

              {isReviewFetching && <Loader />}

              <div className="row justify-content-center margin-top-15 align-self-center">
                {schoolReviews && reviewCount < schoolReviews.data.total && (
                  <Button
                    label={isProjectFetching ? 'Загрузка' : 'Показать еще'}
                    nobg
                    large
                    width="100%"
                    onClick={() => setReviewCount((count) => count + 7)}
                    className="school-details-review__button-show-more"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </BaseLayout>
    </React.Fragment>
  )
}

export default SchoolPage
